const messages = {
  profile: 'Profile',
  changePassword: 'Change password',
  logout: 'Logout',
  firstName: 'First name',
  lastName: 'Last name',
  username: 'Username',
  email: 'Email',
  phone: 'Phone',
  email2: 'Other email',
  phone2: 'Other phone',
  lastLogin: 'Last login',
  lastLogout: 'Last logout',
  isActive: 'Active',
  isVerifyEmail: 'Verify email',
  status: 'Status',
  group: 'Group',
  groups: 'Groups',
  gender: 'Gender',
  birthday: 'Date of birth',
  address: 'Address',
  nationality: 'Nationality',
  ethicity: 'Ethicity',
  religion: 'Religion',
  note: 'Note',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm password',
  using: 'Using',
  temp_locking: 'Temp locking',
  locking: 'Locking',
  blacklist: 'Blacklist',
  cancel: 'Cancel',
  default_group: 'Default group',
  private_group: 'Private group',
  custom_group: 'Custom group',
  male: 'Male',
  female: 'Female',
  secret: 'Secret',
  none: 'None',
  lot: 'Lot',
  serial: 'Serial',
  resources: 'Resources',
  amount: 'Amount',
  comment: 'Comment',
  dark: 'Dark',
  light: 'Light',
  keyword: 'Keyword',
  domain: 'Domain',
  login: 'LOGIN',
  detail: 'Details',
  dashboard: 'Dashboard',
  second: 'second',
  day: 'day',
  confirm: 'Confirm',
  copied: 'Successfully Copied',
  import_button: 'Import',
  dateOfData: 'Date of Data',
  isOverride: 'Override',
  continue: 'Continue',
  resend_email: 'Resend Email',
  reset_password: 'Reset password',
  reset_your_password: 'Reset your password',
  check_your_email: 'Check Your Email',
  type_email_for_reset_password: 'Type your email to reset your password.',
  type_password_to_reset: 'Enter a new password below to change your password.',
  password_change: 'Password Changed!',
  password_change_msg: 'Your password has been changed successfully.',
  back_login_page: 'Back to login page',
  forgot_password: 'Forgot password?',
  wifiPassword: 'Wifi password',
  menu: {
    'Home Page': 'Home Page',
    'Intergrations Management': 'Intergrations Management',
    Modules: 'Modules',
    'Api Keys': 'Api Keys',
    'Screen Groups': 'Screen Groups',
    Screens: 'Screens',
    Functions: 'Functions',
    Menus: 'Menus',
    'API Groups': 'API Groups',
    API: 'API',
    Webhook: 'Webhook',
    'Users Management': 'Users Management',
    'Account Groups': 'Account Groups',
    Accounts: 'Accounts',
    Teachers: 'Teachers',
    Students: 'Students',
    'Subjects Management': 'Subjects Management',
    Subjects: 'Subjects',
    'Subject Classes': 'Subject Classes',
    'Master Management': 'Master Management',
    Units: 'Units',
    Departments: 'Departments',
    Courses: 'Courses',
    'Activity Classes': 'Activity Classes',
    Scholastics: 'Scholastics',
    Semesters: 'Semesters',
    'System Management': 'System Management',
    Settings: 'Settings',
    'Log Exceptions': 'Log Exceptions',
    'Log Mails': 'Log Mails',
  },
  api: {
    'Get activity classes': 'Get activity classes',
    'Get detail activity class': 'Get detail activity class',
    'Get clubs': 'Get clubs',
    'Get detail club': 'Get detail club',
    'Get setting': 'Get setting',
    'Get app state': 'Get app state',
    'Get communes': 'Get communes',
    'Get courses': 'Get courses',
    'Get detail course': 'Get detail course',
    'Get districts': 'Get districts',
    'Add mail content to send by batch': 'Add mail content to send by batch',
    'Test send email': 'Test send email',
    'Get error messages': 'Get error messages',
    'Get detail error messages': 'Get detail error messages',
    'Get faculties': 'Get faculties',
    'Get detail faculty': 'Get detail faculty',
    'Forgot password': 'Forgot password',
    'Check token forgot password': 'Check token forgot password',
    'Reset password': 'Reset password',
    Login: 'Login',
    'Refresh token': 'Refresh token',
    Logout: 'Logout',
    'Get majors': 'Get majors',
    'Get detail major': 'Get detail major',
    'Get token': 'Get token',
    'Get type of student': 'Get type of student',
    'Get foreign languages': 'Get foreign languages',
    'Get profile': 'Get profile',
    'Get base profile': 'Get base profile',
    'Save profile': 'Save profile',
    'Change password': 'Change password',
    'Get provinces': 'Get provinces',
    'Get roles': 'Get roles',
    'Get detail role': 'Get detail role',
    'Get scholatics': 'Get scholatics',
    'Get detail scholatic': 'Get detail scholatic',
    'Get semesters': 'Get semesters',
    'Get detail semester': 'Get detail semester',
    'Get students': 'Get students',
    'Get students (base information)': 'Get students (base information)',
    'Get detail student': 'Get detail student',
    'Get detail student (base information)': 'Get detail student (base information)',
    'Get teachers of student': 'Get teachers of student',
    'Get teachers': 'Get teachers',
    'Get teachers (base information)': 'Get teachers (base information)',
    'Get detail teacher': 'Get detail teacher',
    'Get detail teacher (base information)': 'Get detail teacher (base information)',
    'Check major of student and teacher': 'Check major of student and teacher',
    'Get init data for user': 'Get init data for user',
    'Get users': 'Get users',
    'Delete user': 'Delete user',
    'Delete users': 'Delete users',
    'Get YUS classes': 'Get YUS classes',
    'Get detail YUS class': 'Get detail YUS class',
    'Get permission': 'Get permission',
    'Get init data for teacher': 'Get init data for teacher',
  },
  functions: {
    'Get activity classes': 'Get activity classes',
    'Get detail activity class': 'Get detail activity class',
    'Get clubs': 'Get clubs',
    'Get detail club': 'Get detail club',
    'Get setting': 'Get setting',
    'Get app state': 'Get app state',
    'Get communes': 'Get communes',
    'Get courses': 'Get courses',
    'Get detail course': 'Get detail course',
    'Get districts': 'Get districts',
    'Add mail content to send by batch': 'Add mail content to send by batch',
    'Test send email': 'Test send email',
    'Get error messages': 'Get error messages',
    'Get detail error messages': 'Get detail error messages',
    'Get faculties': 'Get faculties',
    'Get detail faculty': 'Get detail faculty',
    'Forgot password': 'Forgot password',
    'Check token forgot password': 'Check token forgot password',
    'Reset password': 'Reset password',
    Login: 'Login',
    'Refresh token': 'Refresh token',
    Logout: 'Logout',
    'Get majors': 'Get majors',
    'Get detail major': 'Get detail major',
    'Get token': 'Get token',
    'Get type of student': 'Get type of student',
    'Get foreign languages': 'Get foreign languages',
    'Get profile': 'Get profile',
    'Get base profile': 'Get base profile',
    'Save profile': 'Save profile',
    'Change password': 'Change password',
    'Get provinces': 'Get provinces',
    'Get roles': 'Get roles',
    'Get detail role': 'Get detail role',
    'Get scholatics': 'Get scholatics',
    'Get detail scholatic': 'Get detail scholatic',
    'Get semesters': 'Get semesters',
    'Get detail semester': 'Get detail semester',
    'Get students': 'Get students',
    'Get students (base information)': 'Get students (base information)',
    'Get detail student': 'Get detail student',
    'Get detail student (base information)': 'Get detail student (base information)',
    'Get teachers of student': 'Get teachers of student',
    'Get teachers': 'Get teachers',
    'Get teachers (base information)': 'Get teachers (base information)',
    'Get detail teacher': 'Get detail teacher',
    'Get detail teacher (base information)': 'Get detail teacher (base information)',
    'Check major of student and teacher': 'Check major of student and teacher',
    'Get init data for user': 'Get init data for user',
    'Get users': 'Get users',
    'Delete user': 'Delete user',
    'Delete users': 'Delete users',
    'Get YUS classes': 'Get YUS classes',
    'Get detail YUS class': 'Get detail YUS class',
    'Get permission': 'Get permission',
    'Get init data for teacher': 'Get init data for teacher',
    'Search roles': 'Search roles',
    'Show role detail': 'Show role detail',
    'Add role': 'Add role',
    'Edit role': 'Edit role',
    'Delete role': 'Delete role',
    'Grant permission': 'Grant permission',
    Settings: 'Settings',
    General: 'General',
    Email: 'Email',
    'Foreign language': 'Foreign language',
    'Type of student': 'Type of student',
    'Type of teacher': 'Type of teacher',
    'Position in class': 'Position in class',
    'Position in faculty': 'Position in faculty',
    'Position in major': 'Position in major',
    'Position in YUS': 'Position in YUS',
    'Position in CPV': 'Position in CPV',
    'Position in department': 'Position in department',
    Logs: 'Logs',
    'Call API': 'Call API',
    Notification: 'Notification',
    Exception: 'Exception',
    'Export module data': 'Export module data',
    'Import module data': 'Import module data',
    'Blob storage setting': 'Blob storage setting',

    'Search modules': 'Search modules',
    'Show module detail': 'Show module detail',
    'Add module': 'Add module',
    'Edit module': 'Edit module',
    'Delete module': 'Delete module',
    'Search API keys': 'Search API keys',
    'Show API key': 'Show API key',
    'Add API key': 'Add API key',
    'Edit API key': 'Edit API key',
    'Delete API key': 'Delete API key',
    'Search screen groups': 'Search screen groups',
    'Show screen group detail': 'Show screen group detail',
    'Add screen group': 'Add screen group',
    'Edit screen group': 'Edit screen group',
    'Delete screen group': 'Delete screen group',
    'Search screens': 'Search screens',
    'Show screen detail': 'Show screen detail',
    'Add screen': 'Add screen',
    'Edit screen': 'Edit screen',
    'Delete screen': 'Delete screen',
    'Search functions': 'Search functions',
    'Show function detail': 'Show function detail',
    'Add function': 'Add function',
    'Edit function': 'Edit function',
    'Delete function': 'Delete function',
    'Search menu items': 'Search menu items',
    'Show menu item detail': 'Show menu item detail',
    'Add menu item': 'Add menu item',
    'Edit menu item': 'Edit menu item',
    'Delete menu item': 'Delete menu item',
    'Preview menu': 'Preview menu',
    'Arange menu': 'Arange menu',
    'Search API groups': 'Search API groups',
    'Show API group detail': 'Show API group detail',
    'Add API group': 'Add API group',
    'Edit API group': 'Edit API group',
    'Delete API group': 'Delete API group',
    'Search API': 'Search API',
    'Show API detail': 'Show API detail',
    'Add API': 'Add API',
    'Edit API': 'Edit API',
    'Delete API': 'Delete API',
    'Search webhook': 'Search webhook',
    'Show webhook detail': 'Show webhook detail',
    'Add webhook': 'Add webhook',
    'Edit webhook': 'Edit webhook',
    'Delete webhook': 'Delete webhook',

    'Search users': 'Search users',
    'Show user detail': 'Show user detail',
    'Add user': 'Add user',
    'Edit user': 'Edit user',
    'Import users': 'Import users',
    'Export users': 'Export users',
    'Lock account': 'Lock account',
    'Unlock account': 'Unlock account',
    'Add account to black list': 'Add account to black list',
    'Remove account from black list': 'Remove account from black list',
    'Search students': 'Search students',
    'Show student detail': 'Show student detail',
    'Add student': 'Add student',
    'Edit student': 'Edit student',
    'Delete student': 'Delete student',
    'Import student': 'Import student',
    'Export student': 'Export student',
    'Search teachers': 'Search teachers',
    'Show teacher detail': 'Show teacher detail',
    'Add teacher': 'Add teacher',
    'Edit teacher': 'Edit teacher',
    'Delete teacher': 'Delete teacher',
    'Import teacher': 'Import teacher',
    'Export teacher': 'Export teacher',
    'Edit role student': 'Edit role student',
    'Edit role teacher': 'Edit role teacher',
    'Search subjects': 'Search subjects',
    'Show subject detail': 'Show subject detail',
    'Add subject': 'Add subject',
    'Edit subject': 'Edit subject',
    'Delete subject': 'Delete subject',
    'Search subject classes': 'Search subject classes',
    'Show subject class detail': 'Show subject class detail',
    'Add subject class': 'Add subject class',
    'Edit subject class': 'Edit subject class',
    'Delete subject class': 'Delete subject class',
    'View calendar': 'View calendar',
    'Search faculties': 'Search faculties',
    'Show faculty detail': 'Show faculty detail',
    'Add faculty': 'Add faculty',
    'Edit faculty': 'Edit faculty',
    'Delete faculty': 'Delete faculty',
    'Search departments': 'Search departments',
    'Show department detail': 'Show department detail',
    'Add department': 'Add department',
    'Edit department': 'Edit department',
    'Delete department': 'Delete department',
    'Search courses': 'Search courses',
    'Show course detail': 'Show course detail',
    'Add course': 'Add course',
    'Edit course': 'Edit course',
    'Delete course': 'Delete course',
    'Search activity classes': 'Search activity classes',
    'Show activity class detail': 'Show activity class detail',
    'Add activity class': 'Add activity class',
    'Edit activity class': 'Edit activity class',
    'Delete activity class': 'Delete activity class',
    'Search scholastics': 'Search scholastics',
    'Show scholastic detail': 'Show scholastic detail',
    'Add scholastic': 'Add scholastic',
    'Edit scholastic': 'Edit scholastic',
    'Delete scholastic': 'Delete scholastic',
    'Search semesters': 'Search semesters',
    'Show semester detail': 'Show semester detail',
    'Add semester': 'Add semester',
    'Edit semester': 'Edit semester',
    'Delete semester': 'Delete semester',
  },
  screens: {
    'Roles management': 'Roles management',
    Settings: 'Settings',
    Logs: 'Logs',
    'Modules management': 'Modules management',
    'API key management': 'API key management',
    'Screen groups management': 'Screen groups management',
    'Screens management': 'Screens management',
    'Functions management': 'Functions management',
    'Menu management': 'Menu management',
    'Api groups management': 'Api groups management',
    'Api management': 'Api management',
    'Webhook management': 'Webhook management',
    'User management': 'User management',
    'Teacher management': 'Teacher management',
    'Student management': 'Student management',
    'Subjects management': 'Subjects management',
    'Subject classes management': 'Subject classes management',
    Dashboard: 'Dashboard',
    'Faculties management': 'Faculties management',
    'Departments management': 'Departments management',
    'Courses management': 'Courses management',
    'Activity classes management': 'Activity classes management',
    'Scholastics management': 'Scholastics management',
    'Semesters management': 'Semesters management',
  },
};

export default messages;
