import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
};

export const roles = createSlice({
  name: 'users/roles',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
    },
    getInitDataFailure: (state) => {
      state.modules = [];
    },
  },
});

const actionsTmp: any = { ...roles.actions };
actionsTmp.getInitData = createAction('users/roles/getInitData');

export const actions = { ...actionsTmp };

export const rolesSelector = (state: any) => state;

export default roles.reducer;
