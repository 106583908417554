/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  LocalesMenuButton,
  ToggleThemeButton,
  useLocaleState,
  AppBar,
} from 'react-admin';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import UserMenu from './userMenu';
import { actions as appActions } from '../redux/app';
import usePrevious from '../hooks/usePrevious';
import { darkTheme, lightTheme } from './themes';

export default React.memo(() => {
  const dispatch = useDispatch();
  const [locale, setLocale] = useLocaleState();
  const prevLocale = usePrevious(locale);
  React.useEffect(() => {
    if (localStorage.getItem('locale') !== locale) {
      localStorage.setItem('locale', locale);
      dispatch(appActions.changeLanguage(locale));
      dispatch(appActions.getAppState({}));
    }
  }, [locale]);
  return (
    <AppBar
      userMenu={<UserMenu />}
    >
      <Typography flex="1" variant="h6" id="react-admin-title" />
      <LocalesMenuButton
        languages={[
          { locale: 'en', name: 'English' },
          { locale: 'vi', name: 'Tiếng việt' },
        ]}
      />
      <ToggleThemeButton
        lightTheme={lightTheme}
        darkTheme={darkTheme}
      />
    </AppBar>
  );
});
