import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  forceLoading: false,
  noLoading: false,
  progressLoading: 0,
  isProgressLoading: false,
  countLoading: 0,
  version: 0,
  lang: localStorage.getItem('locale') ?? 'en',
  appState: localStorage.getItem('appState') ? JSON.parse(localStorage.getItem('appState') ?? '{}') : {},
  appSetting: localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings') ?? '{}') : {},
};

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    forceLoading: (state, action) => {
      state.forceLoading = action.payload;
    },
    noLoading: (state, action) => {
      state.noLoading = action.payload;
    },
    isProgressLoading: (state, action) => {
      state.isProgressLoading = action.payload;
    },
    setProgressLoading: (state, action) => {
      state.progressLoading = action.payload;
    },
    increaseCountLoading: (state) => {
      state.countLoading += 1;
    },
    decreaseCountLoading: (state) => {
      state.countLoading -= 1;
    },
    changeLanguage: (state, action) => {
      state.lang = action.payload ?? 'vi';
    },
    changeVersionAppSetting: (state) => {
      state.version += 1;
    },
    getAppStateSuccess: (state, action) => {
      localStorage.setItem('appState', JSON.stringify(action.payload));
      state.appState = { ...action.payload };
    },
    getAppStateFailure: (state) => {
      localStorage.setItem('appState', JSON.stringify({}));
      state.appState = {};
    },
    getAppSettingSuccess: (state, action) => {
      localStorage.setItem('settings', JSON.stringify(action.payload));
      // state.version += 1;
      state.appSetting = { ...action.payload };
    },
    getAppSettingFailure: (state) => {
      localStorage.setItem('settings', JSON.stringify({}));
      // state.version += 1;
      state.appSetting = {};
    },
  },
});

const actionsTmp: any = { ...app.actions };
actionsTmp.getAppState = createAction('app/getAppState');
actionsTmp.getAppSetting = createAction('app/getAppSetting');
actionsTmp.logout = createAction('app/logout');
actionsTmp.forgotPassword = createAction('forgotPassword');
actionsTmp.resetPassword = createAction('resetPassword');

export const actions = { ...actionsTmp };

export const appSelector = (state: any) => state;

export default app.reducer;
