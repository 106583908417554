import repositories from '..';

const resource = '/system/settings';

export default {
  getSetting: (type: any) => repositories.get(`${resource}?type=${type}`),
  putMailSystem: (body: any) => repositories.put(`${resource}/mail-system`, body),
  putInt: (body: any) => repositories.put(`${resource}/int`, body),
  putString: (body: any) => repositories.put(`${resource}/string`, body),
  putOther: (body: any) => repositories.put(`${resource}/other`, body),
  putBlobStorage: (body: any) => repositories.put(`${resource}/blob-storage`, body),
};
