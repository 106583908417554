/* eslint-disable max-len */
import { Identifier } from 'react-admin';
import repositories from '..';

const resource = '/subject/subject-classes';

export default {
  getInitData: (data: any) => {
    let seperator = '?';
    let query = '';
    if (data.scholasticId !== '' && data.scholasticId !== undefined) {
      query += `${seperator}scholasticId=${data.scholasticId}`;
      seperator = '&';
    }
    if (data.facultyId !== '' && data.facultyId !== undefined) {
      query += `${seperator}facultyId=${data.facultyId}`;
    }
    return repositories.get(`${resource}/data/init${query}`);
  },
  importStudents: (id: number, data: FormData) => repositories.post(`${resource}/${id}/import`, data),
  addStudentsToSubjectClass: (id: number, data: any) => repositories.post(`${resource}/${id}/add-students`, data),
  removeStudentsFromSubjectClass:
    (id: number, studentIds: Identifier[]) => repositories.delete(`${resource}/${id}/delete-students/${studentIds.join(',')}`),
};
