import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ReactJson from 'react-json-view';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

function ParsedJsonField(props: any) {
  const record = useRecordContext(props);
  let parsedJson = null;
  try {
    parsedJson = JSON.parse(record.data);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  return (
    <ReactJson
      name={null}
      displayDataTypes={false}
      src={parsedJson}
    />
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canSearch = canAccess(permissions, FUNC.LOG_EXCEPTION);
  return (
    <Show
      actions={<ShowActions isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.requestId')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="requestId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.project')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="project"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.createdAt')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="createdAt"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.class')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="class"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.method')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="method"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.createdIp')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="createdIp"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.username')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="username"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.message')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="message"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.source')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="source"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.innerException')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="innerException"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.data')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <ParsedJsonField source="data" />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs.fields.stackTrace')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="stackTrace"
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
