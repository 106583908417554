import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Datagrid,
  List,
  ListActions,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import {
  Grid,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import PostPagination from '../../../components/commons/postPagination';
import keywordFilter from '../../../components/commons/keywordFilter';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const { id } = useParams();

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_FACULTY);
  const canSearch = canAccess(permissions, FUNC.SEARCH_FACULTIES);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} mutationMode="pessimistic" />}
    >
      <SimpleShowLayout>
        <Grid
          container
          xs={12}
          md={12}
          mt={3}
          mx={1}
        >
          <Typography variant="h6">
            {translate('resources.master/faculties.fields.facultyInfo')}
          </Typography>
        </Grid>

        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/faculties.fields.facultyCode')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="facultyCode"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/faculties.fields.typeName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="typeName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/faculties.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/faculties.fields.description')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="description"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} mt={3} mx={1}>
          <Typography variant="h6">
            {translate('resources.master/faculties.fields.majorLabel')}
          </Typography>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5} sx={{ marginLeft: 1, marginRight: 1 }}>
          <List
            title=""
            filters={keywordFilter}
            resource="master/major"
            actions={<ListActions hasCreate={false} />}
            pagination={<PostPagination />}
            empty={false}
            filter={{ facultyId: id }}
            perPage={100}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" label={translate('resources.master/faculties.fields.name')} />
              <TextField source="code" label={translate('resources.master/faculties.fields.code')} />
              <TextField
                source="description"
                fullWidth
                label={translate('resources.master/faculties.fields.description')}
              />
            </Datagrid>
          </List>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
