import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TopToolbar,
  ListButton,
  SelectInput,
  BooleanInput,
  TextInput,
  required,
  maxLength,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import { actions } from '../../../redux/integration/screens';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import { validateId } from '../../../utils/constant';

export default React.memo(() => {
  const {
    groups, modules, functions,
  } = useSelector((state: any) => state.screens);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPost = canAccess(permissions, FUNC.ADD_SCREEN);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCREENS);
  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  // use to auto uncheck other checkbox while one is checked
  const uncheck = (data: any) => {
    const change = data.target.name;
    const elesContain = Array.from(document.getElementsByClassName('isView'));
    let input = null;
    elesContain.forEach((element) => {
      input = element.getElementsByTagName('INPUT')[0] as HTMLInputElement;
      if (input.name !== change && input.checked) {
        input.click();
      }
    });
  };
  // update data before submit
  const transform = (data:any) => {
    let haveView = false;
    if (data.functions.length > 0) {
      Array.from(data.functions).forEach((func: any) => {
        if (func.isReturnView) haveView = true;
      });
      if (!haveView) {
        data.functions[0].isReturnView = true;
      }
    }
    return data;
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      transform={transform}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canPost} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="id"
              validate={[
                required(),
                maxLength(10),
                validateId,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              defaultValue={modules[0]?.id ?? ''}
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              onChange={(choice) => getData(choice.target.value)}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={3} md={5}>
            <BooleanInput
              source="isDefault"
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(50)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="groupId"
              choices={groups ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={[
                required()]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="sort"
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <ArrayInput
              source="functions"
              sx={{
                marginTop: '0',
              }}
            >
              <SimpleFormIterator className="rowfunction">
                <BooleanInput
                  source="isReturnView"
                  onClick={(change) => uncheck(change)}
                  defaultValue={false}
                  className="isView"
                />
                <SelectInput
                  focused={false}
                  source="id"
                  choices={functions ?? []}
                  emptyValue={null}
                  optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
                  validate={[required()]}
                  sx={{ width: '80%' }}
                  variant="outlined"
                  helperText={false}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
