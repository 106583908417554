import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  TopToolbar,
  ListButton,
  required,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canAdd = canAccess(permissions, FUNC.ADD_ROLE);
  const canSearch = canAccess(permissions, FUNC.SEARCH_ROLES);

  const { modules } = useSelector((state: any) => state.roles);

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="dut-form"
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="showId"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="name"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              validate={required()}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <BooleanInput
              source="isDefaultForStudent"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <BooleanInput
              source="isDefaultForTeacher"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={16} md={10}>
            <TextInput
              multiline
              source="description"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 200 }}
              rows={3}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
