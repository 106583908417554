import * as React from 'react';
import { BulkDeleteWithConfirmButton, BulkExportButton } from 'react-admin';

export default React.memo((props: any) => {
  const { isDelete, isExport } = props;
  const canDelete = isDelete === undefined || isDelete === null ? true : isDelete;
  return (
    <>
      { isExport && <BulkExportButton /> }
      { canDelete && <BulkDeleteWithConfirmButton mutationMode="pessimistic" /> }
    </>
  );
});
