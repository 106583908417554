import React from 'react';
import { DateInput } from 'react-admin';
import CustomSearchInput from '../../commons/customSearchInput';

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <DateInput
    source="from"
  />,
  <DateInput
    source="to"
  />,
];
