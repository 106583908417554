import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
  apiKeys: [],
  triggers: [],
  status: [],
};

export const webhooks = createSlice({
  name: 'webhooks',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
      state.apiKeys = action.payload.apiKeys;
      state.triggers = action.payload.triggers;
      state.status = action.payload.status;
    },
    getInitDataFailure: (state) => {
      state.modules = [];
      state.apiKeys = [];
      state.triggers = [];
      state.status = [];
    },
  },
});

const actionsTmp: any = { ...webhooks.actions };
actionsTmp.getInitData = createAction('webhooks/getInitData');
actionsTmp.resendWebHook = createAction('webhooks/resendWebHook');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default webhooks.reducer;
