import React from 'react';
import { SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';

function SelectScholastic({ source }: any) {
  const { scholastics } = useSelector((state: any) => state.semesters);
  return (
    <SelectInput
      source={source}
      choices={scholastics ?? []}
      variant="outlined"
    />
  );
}

export default [
  <SelectScholastic
    source="scholasticId"
  />,
];
