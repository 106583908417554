/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, Typography, Tabs, Tab,
} from '@mui/material';
import { useTranslate } from 'react-admin';
import PositionContainer from '../../../containers/system/settings/editPositionsContainer';
import MailContainer from '../../../containers/system/settings/editMailPosition';
import LanguageContainer from '../../../containers/system/settings/editLanguagePosition';
import StudentContainer from '../../../containers/system/settings/editTypeStudent';
import CadresContainer from '../../../containers/system/settings/editTypeCadres';
import OtherContainer from '../../../containers/system/settings/editOtherSetting';
import BlogStorageContainer from '../../../containers/system/settings/editBlogStorage';
import { actions } from '../../../redux/system/settings';
import TabPanelProps from '../../../interfaces/tabPanelProps';

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function () {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const translate = useTranslate();
  useEffect(() => {
    setLoading(true);
    dispatch(actions.getSetting({
      value,
      callback: () => {
        setLoading(false);
      },
    }));
  }, [value]);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab style={{ display: 'none' }} />
          <Tab label={translate('resources.system/settings.position')} {...a11yProps(1)} />
          <Tab label={translate('resources.system/settings.mail')} {...a11yProps(2)} />
          <Tab label={translate('resources.system/settings.language')} {...a11yProps(3)} />
          <Tab label={translate('resources.system/settings.typeStudent')} {...a11yProps(4)} />
          <Tab label={translate('resources.system/settings.typeCadres')} {...a11yProps(5)} />
          <Tab label={translate('resources.system/settings.blobStorage')} {...a11yProps(6)} />
          <Tab label={translate('resources.system/settings.other')} {...a11yProps(7)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={1}>
        <PositionContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MailContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <LanguageContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <StudentContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <CadresContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <BlogStorageContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        {loading ? null : <OtherContainer />}
      </CustomTabPanel>
    </Box>
  );
}
