import createFaculty from './createFaculty';
import editFaculty from './editFaculty';
import facultyList from './facultyList';
import showFaculty from './showFaculty';

export default {
  list: facultyList,
  create: createFaculty,
  edit: editFaculty,
  show: showFaculty,
};
