import React from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/master/semesters/createSemesterContainer';
import { actions } from '../../../redux/master/semesters';

export default function () {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.getInitData({}));
  }, []);
  return (
    <Container />
  );
}
