const messages = {
  resources: {
    'integration/api-keys': {
      name: 'Khóa truy cập |||| Khóa truy cập',
      fields: {
        id: 'Mã',
        moduleId: 'Mô đun',
        aud: 'Người dùng',
        name: 'Tên',
        key: 'Giá trị',
        loginKey: 'Mã đăng nhập',
        secret: 'Chuỗi bí mật',
        description: 'Mô tả',
        redirectUrl: 'Địa chỉ chuyển hướng',
        appRedirectUrl: 'Địa chỉ chuyển hướng ứng dụng',
        iss: 'Issuer',
        crossOrigin: 'Cross Origin',
        from: 'Hiệu lực từ',
        to: 'Hết hạn lúc',
        env: 'Môi trường',
        isActive: 'Đang hoạt động',
      },
    },
  },
  dut: {
    development: 'Phát triển',
    staging: 'Kiểm thử',
    production: 'Thực tế',
  },
};

export default messages;
