const messages = {
  resources: {
    'master/courses': {
      name: 'Khoá |||| Khoá',
      fields: {
        id: 'Id',
        name: 'Tên khoá',
        description: 'Mô tả',
        activityClasses: 'Danh sách lớp sinh hoạt',
        activityClassName: 'Tên lớp sinh hoạt',
      },
    },
  },
};

export default messages;
