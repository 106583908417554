import * as React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  TextField,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { get } from 'lodash';

export default React.memo((props:any) => {
  const record = useRecordContext();
  const [isHide, setIsHide] = React.useState(true);
  const { source, className } = props;
  const value = get(record, source);
  const translate = useTranslate();
  const notify = useNotify();

  const handleEvent = () => {
    setIsHide((val) => (!val));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    notify(translate('dut.copied'), { type: 'success' });
  };

  if (!record) return null;
  if (isHide) {
    return (
      <div className={className}>
        <span>******</span>
        <VisibilityIcon
          className="pointerClass"
          sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 1 }}
          fontSize="small"
          onClick={handleEvent}
        />
        <ContentCopyIcon className="pointerClass" fontSize="small" onClick={handleCopy} sx={{ marginBottom: 1 }} />
      </div>
    );
  }
  return (
    <div className={className}>
      <TextField
        fullWidth
        source={source}
      />
      <VisibilityOffIcon
        className="pointerClass"
        sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 1 }}
        fontSize="small"
        onClick={handleEvent}
      />
      <ContentCopyIcon className="pointerClass" fontSize="small" onClick={handleCopy} sx={{ marginBottom: 1 }} />
    </div>
  );
});
