import React from 'react';
import { SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectCourse({ source }: any) {
  const { courses } = useSelector((state: any) => state.activityClasses);
  const courseArr = [
    {
      id: -1,
      name: 'Chưa phân khoá',
    },
    ...courses,
  ];
  return (
    <SelectInput
      source={source}
      choices={courseArr ?? []}
      variant="outlined"
    />
  );
}

function SelectFaculties({ source }: any) {
  const { faculties } = useSelector((state: any) => state.activityClasses);
  const facultiesArr = [
    {
      id: -1,
      name: 'Chưa phân khoa',
    },
    ...faculties,
  ];
  return (
    <SelectInput
      source={source}
      choices={facultiesArr ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectCourse
    source="courseId"
  />,
  <SelectFaculties
    source="facultyId"
  />,
];
