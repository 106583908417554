import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ComputerIcon from '@mui/icons-material/Computer';
import KeyIcon from '@mui/icons-material/Key';
import GridViewIcon from '@mui/icons-material/GridView';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ApiIcon from '@mui/icons-material/Api';
import WebhookIcon from '@mui/icons-material/Webhook';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import DomainIcon from '@mui/icons-material/Domain';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsIcon from '@mui/icons-material/Settings';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import MailIcon from '@mui/icons-material/Mail';

interface IObjectKeys {
  [key: string]: any;
}

const icons: IObjectKeys = {
  DashboardIcon: <DashboardIcon />,
  IntegrationInstructionsIcon: <IntegrationInstructionsIcon />,
  ComputerIcon: <ComputerIcon />,
  KeyIcon: <KeyIcon />,
  GridViewIcon: <GridViewIcon />,
  ScreenshotMonitorIcon: <ScreenshotMonitorIcon />,
  WysiwygIcon: <WysiwygIcon />,
  MenuSharpIcon: <MenuSharpIcon />,
  GroupWorkIcon: <GroupWorkIcon />,
  ApiIcon: <ApiIcon />,
  WebhookIcon: <WebhookIcon />,
  ManageAccountsIcon: <ManageAccountsIcon />,
  GroupsIcon: <GroupsIcon />,
  PersonIcon: <PersonIcon />,
  EscalatorWarningIcon: <EscalatorWarningIcon />,
  SchoolIcon: <SchoolIcon />,
  AutoStoriesIcon: <AutoStoriesIcon />,
  ImportContactsIcon: <ImportContactsIcon />,
  LocalLibraryIcon: <LocalLibraryIcon />,
  DisplaySettingsIcon: <DisplaySettingsIcon />,
  RoomPreferencesIcon: <RoomPreferencesIcon />,
  DomainIcon: <DomainIcon />,
  EventRepeatIcon: <EventRepeatIcon />,
  DoorbellIcon: <DoorbellIcon />,
  CalendarTodayIcon: <CalendarTodayIcon />,
  DateRangeIcon: <DateRangeIcon />,
  SettingsSuggestIcon: <SettingsSuggestIcon />,
  SettingsIcon: <SettingsIcon />,
  WebStoriesIcon: <WebStoriesIcon />,
  MailIcon: <MailIcon />,
};

export default icons;
