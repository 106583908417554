import * as React from 'react';
import {
  BooleanField,
} from 'react-admin';

export default React.memo((props:any) => {
  const { source } = props;
  return (
    <BooleanField source={source} sx={{ verticalAlign: 'text-top' }} />
  );
});
