import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserMenu,
  MenuItemLink,
  useLogout,
  useTranslate,
  useUserMenu,
} from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { actions } from '../redux/app';

const ProfileMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="/profile"
      primaryText={translate('dut.profile')}
      leftIcon={<ManageAccountsIcon />}
      onClick={onClose}
    />
  );
});

const ChangePasswordMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="/change-password"
      primaryText={translate('dut.changePassword')}
      leftIcon={<LockIcon />}
      onClick={onClose}
    />
  );
});

const LogoutMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const logout = useLogout();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const handleClick = () => {
    dispatch(actions.logout({
      onSuccess: () => logout(),
    }));
    onClose();
  };
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="#"
      primaryText={translate('dut.logout')}
      leftIcon={<ExitIcon />}
      onClick={handleClick}
    />
  );
});

export default React.memo((props: any) => {
  const { version } = useSelector((state: any) => state.profile);
  return (
    <UserMenu {...props} key={version}>
      <ProfileMenu />
      <ChangePasswordMenu />
      <LogoutMenu />
    </UserMenu>
  );
});
