const messages = {
  resources: {
    'user/students': {
      name: 'Sinh viên |||| Sinh viên',
      fields: {
        studentTitle: 'Loại sinh viên',
        status: 'Trạng thái',
        startPendingAt: 'Bắt đầu vào ngày',
        endPendingAt: 'Kết thúc vào ngày',
        import: 'Nhập sinh viên',
        roleIds: 'Vai trò',
        roleName: 'Vai trò',
        studentInformationLabel: 'Thông tin sinh viên',
        userInformationLabel: 'Thông tin cá nhân',
        yusInformationLabel: 'Thông tin đoàn viên',
        cpvInformationLabel: 'Thông tin đảng viên',
        positionInClass: 'Chức vụ trong lớp',
        currentPosition: 'Chức vụ trong lớp',
        positionInClassName: 'Chức vụ trong lớp',
        language: 'Ngoại ngữ',
        id: 'MSSV',
        userId: 'Mã người dùng',
        username: 'MSSV',
        password: 'Mật khẩu',
        confirmPassword: 'Xác nhận mật khẩu',
        email: 'Email sinh viên',
        phone: 'SĐT chính',
        email2: 'Email cá nhân',
        phone2: 'SĐT khác',
        male: 'Nam',
        female: 'Nữ',
        firstName: 'Tên',
        lastName: 'Họ và tên đệm',
        fullName: 'Họ tên',
        otherName: 'Biệt danh',
        healthStatus: 'Tình trạng sức khỏe',
        height: 'Chiều cao',
        weight: 'Cân nặng',
        candidate: 'Ứng viên',
        bloodGroup: 'Nhóm máu',
        job: 'Công việc',
        educationalLevel: 'Trình độ học vấn',
        englishLevel: 'Trình độ tiếng anh',
        computerLevel: 'Trình độ tin học',
        bestCertificate: 'Chứng chỉ cao nhất',
        politicalTheory: 'Lý luận chính trị',
        qlnn: 'QlNN',
        isWorkFarFromHome: 'Đi làm xa',
        gender: 'Giới tính',
        genderName: 'Giới tính',
        birthday: 'Ngày sinh',
        avatar: 'Ảnh thẻ',
        cover: 'Cover',
        identityCardNumber: 'CMND/CCCD',
        identityCardIssuer: 'Cơ quan cấp CMND/CCCD',
        identityCardIssueDate: 'Ngày cấp CMND/CCCD',
        identityCardFront: 'Mặt trước CMND/CCCD',
        theBackOfIdentityCard: 'Mặt sau CMND/CCCD',
        portraitPhoto: 'Ảnh chân dung',
        nationality: 'Quốc gia',
        ethicity: 'Dân tộc',
        religion: 'Tôn giáo',
        isYusMember: 'Là Đoàn viên',
        isCpvMember: 'Là Đảng viên',
        cpvDateJoining: 'Ngày tham gia Đảng',
        cpvPlaceJoining: 'Nơi tham gia Đảng',
        taxCode: 'Mã số thuế',
        note: 'Ghi chú',
        currentAddressLabel: 'Nơi ở hiện nay',
        currentAddress: {
          detailAddress: 'Địa chỉ cụ thể',
          communeId: 'Mã Phường/xã',
          districtId: 'Mã Quận/huyện',
          provinceId: 'Mã Tỉnh/Thành phố',
          communeName: 'Phường/xã',
          districtName: 'Quận/huyện',
          provinceName: 'Tỉnh/Thành phố',
        },
        birthPlaceLabel: 'Nơi sinh',
        permanentAddressLabel: 'Địa chỉ thường trú',
        nativeLandLabel: 'Quê quán',
        activityClassId: 'Lớp',
        activityClassName: 'Lớp',
        activityClass: 'Lớp',
        language1: 'Ngoại ngữ 1',
        language2: 'Ngoại ngữ 2',
        classPositions:
          {
            id: 'Mã',
            name: 'Tên',
            isActive: 'Hoạt động',
            from: 'Ngày bắt đầu',
            to: 'Ngày kết thúc',
          },
        facultyId: 'Khoa',
        facultyName: 'Khoa',
        faculty: 'Khoa',
        courseId: 'Khóa học',
        courseName: 'Khóa học',
        course: 'Khóa',
        majorId: 'Chuyên ngành',
        majorName: 'Chuyên ngành',
        major: 'Chuyên ngành',
        languages:
          {
            id: 'Mã',
            name: 'Tên',
            order: 'Thứ tự',
          },
        information: {
          firstName: 'Tên',
          lastName: 'Họ và tên đệm',
          otherName: 'Biệt danh',
          email: 'Email cá nhân',
          phone: 'SĐT khác',
          identityCardType: 'Loại giấy tờ',
          identityCardNumber: 'Số',
          identityCardIssuerId: 'Cơ quan cấp',
          identityCardIssuerName: 'Cơ quan cấp',
          identityCardIssueDate: 'Ngày cấp',
          gender: 'Giới tính',
          birthday: 'Ngày sinh',
          nationalityId: 'Quốc tịch',
          ethnicityId: 'Dân tộc',
          religion: 'Tôn giáo',
          height: 'Chiều cao',
          weight: 'Cân nặng',
          bloodGroup: 'Nhóm máu',
          job: 'Công việc',
          educationalLevel: 'Trình độ học vấn',
          englishLevel: 'Trình độ tiếng anh',
          computerLevel: 'Trình độ tin học',
          bestCertificate: 'Chứng chỉ cao nhất',
          politicalTheory: 'Lý luận chính trị',
          qlnn: 'QlNN',
          isWorkFarFromHome: 'Đi làm xa',
          healthStatus: 'Tình trạng sức khoẻ',
          familyFrom: 'Thành phần xuất thân',
          note: 'Ghi chú',
          birthPlaceCountryId: 'Quốc gia sinh',
          birthPlaceProvinceId: 'Tỉnh/Thành phố',
          birthPlaceProvinceName: 'Tỉnh/Thành phố',
          currentAddress: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
          permanentAddress: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
          nativeLand: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
          isYusMember: 'Là Đoàn viên',
        },
        yusInformation: {
          status: 'Trạng thái Đoàn viên',
          isMember: 'Là đoàn viên',
          isNewMember: 'Đoàn viên mới',
          isMovedMember: 'Đoàn viên chuyển đến',
          isTempMember: 'Đoàn viên hoạt động tạm thời',
          card: 'Thẻ Đoàn viên',
          prepareDate: 'Ngày vào Đoàn dự bị',
          officialDate: 'Ngày vào đoàn chính thức',
          movedDate: 'Ngày chuyển đến',
          yusClassBefore: 'Chi đoàn trước',
          isRegisterForYusActivities: 'Đăng ký chuyển hoạt động Đoàn',
          placeOfRegisterForYusActivities: 'Nơi đăng ký hoạt động Đoàn',
          regisStartDate: 'Ngày bắt đầu đăng ký',
          regisEndDate: 'Ngày kết thúc đăng ký',
          dateJoining: 'Ngày tham gia Đoàn',
          placeJoining: 'Nơi tham gia Đoàn',
          positionInYus: 'Chức vụ trong đoàn',
          currentPosition: 'Chức vụ trong đoàn',
        },
        cpvInformation: {
          status: 'Trạng thái Đảng viên',
          isCpvMember: 'Là Đảng viên',
          cpvDateJoining: 'Ngày vào Đảng dự bị',
          cpvRealDateJoining: 'Ngày vào Đảng chính thức',
          cpvPlaceOfActivityId: 'Chi bộ Đảng sinh hoạt',
          cpvPlaceJoining: 'Nơi vào Đảng',
        },
      },
    },
  },
};

export default messages;
