import * as React from 'react';
import {
  SimpleForm, useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PostionArrayInput from '../../../components/system/settings/positionArrayInput';
import { actions } from '../../../redux/system/settings';
import EditToolBar from '../../../components/commons/editToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SETTINGS);
  const canEditPosClass = canAccess(permissions, FUNC.POS_IN_CLASS_SETTING);
  const canEditPosFaculty = canAccess(permissions, FUNC.POS_IN_FACULTY_SETTING);
  const canEditPosMajor = canAccess(permissions, FUNC.POS_IN_MAJOR_SETTING);
  const canEditPosYus = canAccess(permissions, FUNC.POS_IN_YUS_SETTING);
  const canEditPosCpv = canAccess(permissions, FUNC.POS_IN_CPV_SETTING);
  const canEditPosDepartment = canAccess(permissions, FUNC.POS_IN_DEPARTMENT_SETTING);
  const [positionInClass, setPositionInClass] = useState(null);
  const [positionInFaculty, setPositionInFaculty] = useState(null);
  const [positionInMajor, setPositionInMajor] = useState(null);
  const [positionInYus, setPositionInYus] = useState(null);
  const [positionInCpv, setPositionInCpv] = useState(null);
  const [positionInDepartment, setPositionInDepartment] = useState(null);
  const { data } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();
  useEffect(() => {
    setPositionInClass(data?.list?.find((item: any) => item.id === 'positionInClass')?.value);
    setPositionInFaculty(data?.list?.find((item: any) => item.id === 'positionInFaculty')?.value);
    setPositionInMajor(data?.list?.find((item: any) => item.id === 'positionInMajor')?.value);
    setPositionInYus(data?.list?.find((item: any) => item.id === 'positionInYus')?.value);
    setPositionInCpv(data?.list?.find((item: any) => item.id === 'positionInCpv')?.value);
    setPositionInDepartment(data?.list?.find((item: any) => item.id === 'positionInDepartment')?.value);
  }, [data]);
  const positionKeys = ['name'];
  const hiddenKeys = ['id', 'order'];
  const notify = useNotify();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
  };
  const transform = (val: any) => {
    const list: any[] = [];
    Object.keys(val).forEach((key) => {
      const property = val[key];
      property.forEach((element: any, index: any) => {
        element.order = index + 1;
        if (element.id === '') {
          element.id = Math.max(...property.map((item: any) => item.id)) + 1;
        }
      });
      const position: { id: string, value: any } = { id: key, value: property };
      list.push(position);
    });
    dispatch(actions.putInt({ list, onSuccess }));
  };
  if (!canShow) return null;
  return (
    <SimpleForm toolbar={<EditToolBar />} onSubmit={transform}>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInClass}
              canEdit={canEditPosClass}
              source="positionInClass"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInFaculty}
              canEdit={canEditPosFaculty}
              source="positionInFaculty"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInMajor}
              canEdit={canEditPosMajor}
              source="positionInMajor"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInYus}
              canEdit={canEditPosYus}
              source="positionInYus"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInCpv}
              canEdit={canEditPosCpv}
              source="positionInCpv"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <PostionArrayInput
              keys={positionKeys}
              hiddenKeys={hiddenKeys}
              datas={positionInDepartment}
              canEdit={canEditPosDepartment}
              source="positionInDepartment"
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
});
