import * as React from 'react';
import {
  SelectInput,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import dataProvider from '../../../dataProvider';

export default React.memo((props: any) => {
  const scholasticId = useWatch({ name: 'scholasticId' });
  const [semesters, setSemesters] = React.useState<any>([]);

  React.useEffect(() => {
    const getSemester = async () => {
      if (!scholasticId) {
        return;
      }
      const data = await dataProvider.getList('master/semesters', {
        filter: {
          scholasticId,
        },
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'id',
          order: 'asc',
        },
      });
      if (data.data && data?.data?.length > 0) {
        setSemesters(data.data);
      } else {
        setSemesters([]);
      }
    };
    getSemester().catch(console.error);
  }, [scholasticId]);

  return (
    <SelectInput
      choices={semesters}
      optionText={(choice) => choice.name}
      {...props}
    />
  );
});
