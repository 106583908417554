const messages = {
  resources: {
    'master/scholastics': {
      name: 'Scholastics |||| Scholastic',
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        semesters: 'Semesters',
        startAt: 'Time From',
        endAt: 'Time To',
        startYear: 'Year From',
        endYear: 'Year To',
      },
    },
  },
};

export default messages;
