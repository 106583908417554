import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/integration/apiGroups/apiGroupsListContainer';
import { actions } from '../../../redux/integration/apiGroups';

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData({}));
  }, []);
  return (
    <Container />
  );
}
