import { configureStore } from '@reduxjs/toolkit';
// import { batchedSubscribe } from 'redux-batched-subscribe';
import createSagaMiddleware, { END } from 'redux-saga';
// import _ from 'lodash';
import logger from 'redux-logger';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const preloadedState = {};
const sagaMiddleware = createSagaMiddleware();
// const debounceNotify = _.debounce((notify: Function) => notify());

const store: any = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }).prepend(sagaMiddleware).concat(logger),
  // devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  // enhancers: [batchedSubscribe(debounceNotify)],
});

/**
  * If saga not running then run saga
  * -----------------------------------------
  * @author : QuyPN - 27/09/2022 - create
  * @access : public
  */
store.runSaga = () => {
  // Avoid running twice
  if (store.saga) return;
  store.saga = sagaMiddleware.run(rootSaga);
};

/**
  * If saga not running then stop saga
  * -----------------------------------------
  * @author : QuyPN - 27/09/2022 - create
  * @access : public
  */
store.stopSaga = async () => {
  // Avoid running twice
  if (!store.saga) return;
  store.dispatch(END);
  await store.saga.done;
  store.saga = null;
};

/**
  * Exec a saga process on a thread
  * -----------------------------------------
  * @author : QuyPN - 27/09/2022 - create
  * @param  : {bool} isServer - saga is running on server or on client
  * @param  : {function} tasks - thread to run saga
  * @access : public
  */
store.execSagaTasks = async (isServer: boolean, tasks: any) => {
  // run saga
  store.runSaga();
  // dispatch saga tasks
  tasks(store.dispatch);
  // Stop running and wait for the tasks to be done
  await store.stopSaga();
  // Re-run on client side
  if (!isServer) {
    store.runSaga();
  }
};

// Initial run
store.runSaga();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
