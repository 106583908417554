import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
};

export const screenGroups = createSlice({
  name: 'screenGroups',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
    },
    getInitDataFailure: (state) => {
      state.modules = [];
    },
  },
});

const actionsTmp: any = { ...screenGroups.actions };
actionsTmp.getInitData = createAction('screenGroups/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default screenGroups.reducer;
