import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/subject/subjectClasses/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_SUBJECT_CLASS);
  const canShow = canAccess(permissions, FUNC.SHOW_SUBJECT_CLASS_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_SUBJECT_CLASS);
  const canDelete = canAccess(permissions, FUNC.DELETE_SUBJECT_CLASS);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canAdd} />}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="name" />
        <TextField source="subjectName" />
        <TextField source="subjectClassCode" />
        <TextField source="typeName" />
        <TextField source="teacherName" />
        <TextField source="scholasticName" />
        <TextField source="semesterName" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
