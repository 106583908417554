import * as React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

export default React.memo((props: any) => {
  const { isSave } = props;
  return (
    <Toolbar>
      {isSave !== false && <SaveButton />}
    </Toolbar>
  );
});
