import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ReactJson from 'react-json-view';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

function ParsedJsonField(props: any) {
  const record = useRecordContext(props);
  let parsedJson = null;
  try {
    parsedJson = JSON.parse(record.model);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  return (
    <ReactJson
      name={null}
      displayDataTypes={false}
      src={parsedJson}
    />
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canSearch = canAccess(permissions, FUNC.LOG_EMAIL);
  return (
    <Show
      actions={<ShowActions isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.sendAt')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="sendAt"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.subject')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="subject"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.template')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="template"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.fromEmail')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="fromEmail"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.toEmail')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="toEmail"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.mailError')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="mailError"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.numberOfError')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="numberOfError"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.statusName')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="statusName"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.priorityName')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <TextField
              fullWidth
              source="priorityName"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.model')} />
          </Grid>
          <Grid item xs={9} md={11}>
            <ParsedJsonField
              source="model"
            />
          </Grid>
          <Grid item xs={0} md={1} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.system/logs/mail.fields.body')} />
          </Grid>
          <Grid item xs={9} md={12}>
            <RichTextField
              fullWidth
              source="body"
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
