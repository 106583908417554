const messages = {
  resources: {
    'master/faculties': {
      name: 'Faculties |||| Faculty',
      fields: {
        id: 'Id',
        name: 'Faculty name',
        facultyCode: 'Code',
        code: 'Code',
        typeName: 'Type',
        type: 'Type',
        description: 'Description',
        facultyInfo: 'Faculty Infomation',
        majorLabel: 'List Of Majors',
        majors: {
          code: 'Code',
          name: 'Major name',
          description: 'Description',
        },
      },
    },
  },
};

export default messages;
