const messages = {
  resources: {
    'integration/modules': {
      name: 'Mô đun |||| Mô đun',
      fields: {
        id: 'Id',
        name: 'Tên mô đun',
        isActive: 'Đang hoạt động',
        domain: 'Tên miền',
        logo: 'Logo',
        description: 'Mô tả',
        adminInfoLabel: 'Thông tin người quản trị',
        adminInfo: {
          firstName: 'Họ',
          lastName: 'Tên',
          username: 'Tên đăng nhập',
          email: 'Email',
          password: 'Mật khẩu',
          confirmPassword: 'Nhập lại mật khẩu',
        },
      },
    },
  },
};

export default messages;
