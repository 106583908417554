import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../../repositories/subject/subjectClasses';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getInitData() {
  yield takeLatest(actions.getInitData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getInitData, payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* importStudents() {
  yield takeLatest(actions.importStudents, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(() => repositories.importStudents(payload.id, payload.data));
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  });
}

export function* addStudentsToSubjectClass() {
  yield takeLatest(actions.addStudentsToSubjectClass, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(
        () => repositories.addStudentsToSubjectClass(payload.id, payload.data),
      );
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  });
}

export function* removeStudentsFromSubjectClass() {
  yield takeLatest(actions.removeStudentsFromSubjectClass, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(
        () => repositories.removeStudentsFromSubjectClass(payload.id, payload.studentIds),
      );
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getInitData),
    fork(importStudents),
    fork(addStudentsToSubjectClass),
    fork(removeStudentsFromSubjectClass),
  ]);
}
