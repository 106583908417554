/* eslint-disable no-param-reassign */
import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import {
  Table,
  TableBody,
  TableContainer,
  FormGroup,
  FormControlLabel,
  Paper,
  Grid,
  Checkbox,
} from '@mui/material';

export default React.memo((props: any) => {
  const {
    field,
  } = useInput(props);
  const translate = useTranslate();
  const applyData = (tmp: any[]) => {
    field.onChange(tmp);
  };
  const changePermission = (index: number) => {
    const tmp = [...field.value];
    tmp[index].isEnabled = tmp[index].isEnabled !== true;
    applyData(tmp);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="table-permission">
            <TableBody>
              <Grid
                container
                className="row-function"
              >
                {(field?.value ?? []).map((api: any, idx: number) => (
                  <React.Fragment key={api.apiId}>
                    <Grid item xs={3} className="table-item">
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={api.isEnabled}
                              onChange={() => changePermission(idx)}
                            />
                          )}
                          label={translate(api.name)}
                        />
                      </FormGroup>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});
