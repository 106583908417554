import React from 'react';
import {
  Datagrid,
  DateField,
  Button,
  List, ListActions,
  TextField, UrlField, useRecordContext, useTranslate, RichTextField,
  useRefresh, useNotify,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PostPagination from '../../commons/postPagination';
import ShowLabel from '../../commons/showLabel';
import { actions } from '../../../redux/integration/webhooks';
import historyFilter from './historyFilter';

function ParsedJsonField(props: any) {
  const record = useRecordContext(props);
  let parsedJson = null;
  try {
    parsedJson = JSON.parse(record.body);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  return (
    <ReactJson
      name={null}
      displayDataTypes={false}
      src={parsedJson}
      enableClipboard={false}
    />
  );
}

function ResponseField(props: any) {
  const record = useRecordContext(props);
  const {
    isJson,
  } = props;
  let parsedJson = null;
  if (isJson) {
    try {
      parsedJson = JSON.parse(record.response);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }
  return (
    <>
      {!isJson && (
        <RichTextField source="response" />
      )}
      {isJson && (
        <ReactJson
          name={null}
          displayDataTypes={false}
          src={parsedJson}
        />
      )}
    </>
  );
}

function ViewDialog(props: any) {
  const record = useRecordContext(props);
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const notify = useNotify();
  const isJson = record.response.startsWith('{') || record.response.startsWith('[');
  const onCancel = () => {
    setOpen(false);
  };
  const openDialog = () => {
    setOpen(true);
  };
  const refresh = useRefresh();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
    refresh();
  };
  const resend = () => {
    const { id } = record;
    dispatch(actions.resendWebHook({
      id,
      onSuccess,
    }));
  };
  return (
    <>
      <VisibilityIcon onClick={openDialog} sx={{ color: '#198add', marginRight: 1 }} />
      <Dialog
        open={open}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="historyDialog"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('resources.integration/webhooks.fields.histories.detail')}
          <IconButton
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
              <ShowLabel label={translate('resources.integration/webhooks.fields.histories.sendAt')} />
            </Grid>
            <Grid item xs={8} md={10} sx={{ borderBottom: '1' }}>
              <DateField
                source="sendAt"
                showTime
              />
            </Grid>
            <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
              <ShowLabel label={translate('resources.integration/webhooks.fields.histories.status')} />
            </Grid>
            <Grid item xs={8} md={10} sx={{ borderBottom: '1' }}>
              <TextField
                source="status"
              />
            </Grid>
            <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
              <ShowLabel label={translate('resources.integration/webhooks.fields.histories.url')} />
            </Grid>
            <Grid item xs={8} md={10} sx={{ borderBottom: '1' }}>
              <UrlField
                source="url"
              />
            </Grid>
            <Grid item xs={10} md={16} sx={{ borderBottom: '1' }}>
              <Grid item xs={12} md={12} sx={{ borderBottom: '1' }}>
                <ShowLabel label={translate('resources.integration/webhooks.fields.histories.body')} />
              </Grid>
              <Grid item xs={12} md={12} sx={{ borderBottom: '1' }}>
                <ParsedJsonField />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ borderBottom: '1' }}>
              <ShowLabel label={translate('resources.integration/webhooks.fields.histories.response')} />
            </Grid>
            <Grid item xs={12} md={12} sx={{ borderBottom: '1' }}>
              <ResponseField isJson={isJson} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label={translate('resources.integration/webhooks.fields.histories.resend')}
            onClick={resend}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
interface ISort {
  field: string,
  order: string,
}

const initSort : ISort = { field: 'sendAt', order: 'DESC' };

export default React.memo((props: any) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  return (
    <List
      resource="integration/webhooks/histories"
      title={translate('resources.integration/webhooks.fields.histories.name')}
      actions={<ListActions />}
      filters={historyFilter}
      pagination={<PostPagination />}
      filter={{ webhookId: record.id }}
      sort={initSort}
      perPage={100}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField
          source="sendAt"
          showTime
          label={translate('resources.integration/webhooks.fields.histories.sendAt')}
        />
        <TextField
          source="status"
          label={translate('resources.integration/webhooks.fields.histories.status')}
        />
        <UrlField
          source="url"
          label={translate('resources.integration/webhooks.fields.histories.url')}
        />
        <ViewDialog />
      </Datagrid>
    </List>
  );
});
