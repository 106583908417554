import semesterList from './semesterList';
import createSemester from './createSemester';
import editSemester from './editSemester';
import showSemester from './showSemester';

export default {
  list: semesterList,
  create: createSemester,
  edit: editSemester,
  show: showSemester,
};
