import createSubject from './createSubject';
import editSubject from './editSubject';
import showSubject from './showSubject';
import subjectList from './subjectList';

export default {
  list: subjectList,
  create: createSubject,
  edit: editSubject,
  show: showSubject,
};
