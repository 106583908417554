import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  required,
  useRecordContext,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import TablePermissionInput from '../../../components/system/roles/tablePermissionInput';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

function FormRole(props: any) {
  const record = useRecordContext(props);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const { modules } = useSelector((state: any) => state.roles);

  const canEdit = canAccess(permissions, FUNC.EDIT_USER);
  const canDelete = canAccess(permissions, FUNC.DELETE_USER);
  return (
    <TabbedForm toolbar={<EditToolBar isSave={canEdit} isDelete={canDelete} />}>
      <FormTab label="resources.system/roles.name">
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="showId"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 10 }}
              disabled
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="name"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              fullWidth
              variant="outlined"
              helperText={false}
              disabled
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <BooleanInput
              source="isDefaultForStudent"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <BooleanInput
              source="isDefaultForTeacher"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={16} md={10}>
            <TextInput
              multiline
              source="description"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 200 }}
              rows={3}
            />
          </Grid>
        </Grid>
      </FormTab>
      {record?.canGrantPermissions && (
        <FormTab label="dut.permissions">
          <TablePermissionInput source="permissions" />
        </FormTab>
      )}
      {record?.canGrantIamPermissions && (
        <FormTab label="dut.iamPermissions">
          <TablePermissionInput source="iamPermissions" />
        </FormTab>
      )}
    </TabbedForm>
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canSearch = canAccess(permissions, FUNC.SEARCH_USERS);
  const canShow = canAccess(permissions, FUNC.SHOW_USER_DETAIL);

  return (
    <div className="dut-form">
      <Edit
        actions={(
          <EditActions
            isShow={canShow}
            isList={canSearch}
          />
        )}
        mutationMode="pessimistic"
      >
        <FormRole />
      </Edit>
    </div>
  );
});
