import * as React from 'react';
import {
  Button as RAButton,
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';

export default React.memo((props:any) => {
  const {
    label,
    onClick,
    fileName,
  } = props;
  return (
    <RAButton
      color="primary"
      label={label}
      onClick={onClick}
      href={`/templates/${fileName}`}
    >
      <GetAppIcon style={{ fontSize: '20' }} />
    </RAButton>
  );
});
