const messages = {
  resources: {
    'system/roles': {
      name: 'Nhóm quyền',
      fields: {
        id: 'Mã quyền',
        showId: 'Mã quyền',
        name: 'Tên quyền',
        moduleName: 'Mô đun',
        moduleId: 'Mô đun',
        description: 'Mô tả',
        isAdmin: 'Là QTV',
        isDefaultForStudent: 'Mặc định cho SV',
        isDefaultForTeacher: 'Mặc định cho GV',
      },
    },
  },
  dut: {
    permissions: 'Phân quyền mô-đun',
    iamPermissions: 'Phân quyền hệ thống',
  },
};

export default messages;
