/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataProvider } from 'ra-core/dist/cjs/types';
import ResponseInterface from './interfaces/responseInterface';
import repositories from './repositories';

const dataProvider: DataProvider = {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    return repositories.get(`/${resource}`, {
      params: {
        CurrentPage: page,
        PageSize: perPage,
        SortBy: field,
        Sort: order,
        ...params.filter,
      },
    }).then((res: ResponseInterface) => ({
      data: res?.data?.list ?? [],
      total: res?.data?.paging?.totalRecord ?? 0,
      res: res ?? {},
    }));
  },

  getOne: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.get(`/${resource}/${id}`).then((res: ResponseInterface) => ({
      data: res?.data ?? {},
      res: res ?? {},
    }));
  },

  getMany: (resource: string, params: any) => repositories.get(`/${resource}`, {
    params: {
      ids: params.ids,
    },
  }).then((res: ResponseInterface) => ({
    data: res?.data ?? {},
    res: res ?? {},
  })),

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.get(`/${resource}`, {
      params: {
        CurrentPage: page,
        PageSize: perPage,
        Field: field,
        Order: order,
        ...params.filter,
        [params.target]: id,
      },
    }).then((res: ResponseInterface) => ({
      data: res?.data?.list ?? [],
      total: res?.data?.paging?.total ?? 0,
      res: res ?? {},
    }));
  },

  create: (resource: string, params: any) => repositories.post(`/${resource}`, params.data)
    .then((res: ResponseInterface) => ({
      data: { ...params.data, id: res?.data?.data?.id ?? 0 },
      res: res ?? {},
    })),

  createMany: (resource: string, params: any) => repositories.post(`/${resource}/import`, params.data)
    .then((res: ResponseInterface) => ({
      data: { ...params.data },
      res: res ?? {},
    })),

  update: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.put(`/${resource}/${id}`, params.data).then((res: ResponseInterface) => ({
      data: params.data ?? {},
      res: res ?? {},
    }));
  },

  updateMany: (resource: string, params: any) => repositories.put(`/${resource}`, params.data, {
    params: {
      ids: params.ids,
    },
  }).then((res: ResponseInterface) => ({
    data: res.data ?? {},
    res: res ?? {},
  })),

  delete: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories
      .delete(`/${resource}/${id}`, { params: params.data })
      .then((res: ResponseInterface) => ({
        data: res.data ?? {},
        res: res ?? {},
      }));
  },

  deleteMany: (resource: string, params: any) => repositories.delete(`/${resource}`, {
    params: {
      ids: params.ids.join(','),
    },
  }).then((res: ResponseInterface) => ({
    data: [],
    res: res ?? {},
  })),
};
export default dataProvider;
