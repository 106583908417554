import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../repositories/app';
import loginRepositories from '../../repositories/login';
import ResponseInterface from '../../interfaces/responseInterface';

export function* getAppSetting() {
  yield takeLatest(actions.getAppSetting, function* (data: any) {
    const { payload } = data;
    yield put(actions.noLoading(true));
    try {
      const response: ResponseInterface = yield call(repositories.getAppSetting);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getAppSettingSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getAppSettingFailure());
    }
    yield put(actions.noLoading(false));
  });
}

export function* getAppState() {
  yield takeLatest(actions.getAppState, function* (data: any) {
    const { payload } = data;
    yield put(actions.noLoading(true));
    try {
      const response: ResponseInterface = yield call(repositories.getAppState);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
      yield put(actions.getAppStateSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getAppStateFailure());
    }
    yield put(actions.noLoading(false));
  });
}

export function* logout() {
  yield takeLatest(actions.logout, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(loginRepositories.logout);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* forgotPassword() {
  yield takeLatest(actions.forgotPassword, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(loginRepositories.forgotPassword, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* resetPassword() {
  yield takeLatest(actions.resetPassword, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(loginRepositories.resetPassword, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getAppSetting),
    fork(getAppState),
    fork(logout),
    fork(forgotPassword),
    fork(resetPassword),
  ]);
}
