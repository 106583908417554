const messages = {
  resources: {
    'user/accounts': {
      name: 'Người dùng',
      fields: {
        fullName: 'Tên đầy đủ',
        firstName: 'Họ',
        lastName: 'Tên',
        username: 'Tên đăng nhập',
        email: 'Email',
        phone: 'Số điện thoại',
        Phone: 'Số điện thoại',
        lastLogin: 'Đăng nhập lần cuối',
        lastLogout: 'Đăng xuất lần cuối',
        isActive: 'Kích hoạt TK',
        isVerifyEmail: 'Xác thực Email',
        status: 'Trạng thái',
        Group: 'Nhóm tài khoản',
        Query: 'Tìm kiếm',
        groups: 'Nhóm tài khoản',
        gender: 'Giới tính',
        genderName: 'Giới tính',
        birthday: 'Sinh nhật',
        address: 'Địa chỉ',
        note: 'Ghi chú',
        password: 'Mật khẩu',
        confirmPassword: 'Nhập lại mật khẩu',
        Role: 'Nhóm quyền',
        roleIds: 'Nhóm quyền',
        statusName: 'Trạng thái',
        taxCode: 'Mã số thuế',
        nationality: 'Quốc gia',
        ethicity: 'Dân tộc',
        religion: 'Tôn giáo',
        wifiPassword: 'Mật khẩu wifi',
      },
    },
  },
  dut: {
    resetPassword: 'Đặt lại mật khẩu',
    lockAccount: 'Khoá',
    unlockAccount: 'Mở khoá',
    addToBlackList: 'Thêm vào danh sách đen',
    removeFromBlackList: 'Xoá khỏi danh sách đen',
  },
};

export default messages;
