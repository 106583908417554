import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.ADD_API);
  const canSearch = canAccess(permissions, FUNC.SEARCH_API);

  return (
    <Show
      actions={<ShowActions isEdit={canPut} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.id" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              source="showId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.module" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="moduleId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.groupName" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="groupName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.apiCode" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="apiCode"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.name" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.area" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="area"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.controller" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="controller"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.action" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="action"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/apis.fields.url" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="url"
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
