/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  useTranslate,
  regex,
  email,
  PasswordInput,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import { validateId } from '../../../utils/constant';
import sharedFunction from '../../../utils/sharedFunction';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MODULES);
  const canAdd = canAccess(permissions, FUNC.ADD_MODULE);
  const translate = useTranslate();
  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.adminInfo.password !== value) {
      return 'dut.msg.confirm_password_invalidate';
    }
    return undefined;
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={1} md={2}>
            <TextInput
              variant="outlined"
              source="id"
              validate={[
                required(),
                maxLength(10),
                validateId,
              ]}
            />
          </Grid>
          <Grid item xs={7} md={8}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(50)]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={7} md={8}>
            <TextInput
              fullWidth
              variant="outlined"
              source="domain"
              validate={[
                maxLength(255)]}
            />
          </Grid>
          <Grid item xs={1} md={2}>
            <BooleanInput
              source="isActive"
              className="center-checkbox"
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="logo"
              label={translate('resources.modules.fields.logo')}
              validate={[
                maxLength(255)]}
            />
          </Grid> */}
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(500)]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1.15} />
          <Typography variant="h6">
            {translate('resources.integration/modules.fields.adminInfoLabel')}
          </Typography>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="adminInfo.username"
              validate={[
                required(),
                regex(/^[a-zA-Z0-9_.-]{4,50}$/, 'dut.msg.username_format_invalidate'),
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{
                maxLength: 50,
                readOnly: true,
              }}
              onFocus={sharedFunction.removeReadOnly}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="adminInfo.email"
              validate={[required(), email()]}
              type="email"
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="adminInfo.password"
              validate={[
                required(),
                regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'dut.msg.password_format_invalidate'),
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="adminInfo.confirmPassword"
              validate={[
                required(),
                confirmPasswordValidation,
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="adminInfo.firstName"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="adminInfo.lastName"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
