const messages = {
  resources: {
    'integration/functions': {
      name: 'Chức năng |||| Chức năng',
      fields: {
        showId: 'Id',
        moduleId: 'Mô đun',
        functionCode: 'Mã',
        name: 'Tên',
        isReturnView: 'Trả về trang html',
        isRequired: 'Chức năng bắt buộc',
        isHidden: 'Chức năng ẩn',
        showRelatedFunctionId: 'Chức năng cha',
        screen: 'Màn hình',
        url: 'Đường dẫn',
        area: 'Area',
        controller: 'Controller',
        action: 'Action',
        sort: 'Sắp xếp',
        isActive: 'Đang hoạt động',
        relateScreen: 'Màn hình chứa chứa năng',
      },
    },
  },
};

export default messages;
