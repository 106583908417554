const messages = {
  resources: {
    'integration/api-groups': {
      name: 'API Group |||| API Groups',
      fields: {
        showId: 'Id',
        id: 'Id',
        module: 'Module',
        name: 'Name',
        description: 'Description',
        moduleId: 'Module',
        isActive: 'Is Active',
      },
    },
  },
};

export default messages;
