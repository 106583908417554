import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  Edit,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { actions } from '../../../redux/integration/screens';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const dispatch = useDispatch();
  const {
    groups, modules, functions,
  } = useSelector((state: any) => state.screens);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_SCREEN);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCREENS);
  const canShow = canAccess(permissions, FUNC.SHOW_SCREEN_DETAIL);
  const translate = useTranslate();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  // use to auto uncheck other checkbox while one is checked
  const uncheck = (data: any) => {
    const change = data.target.name;
    const elesContain = Array.from(document.getElementsByClassName('isView'));
    let input = null;
    elesContain.forEach((element) => {
      input = element.getElementsByTagName('INPUT')[0] as HTMLInputElement;
      if (input.name !== change && input.checked) {
        input.click();
      }
    });
  };
  // update data before submit
  const transform = (data:any) => {
    let haveView = false;
    if (data.functions.length > 0) {
      Array.from(data.functions).forEach((func: any) => {
        if (func.isReturnView) haveView = true;
      });
      if (!haveView) {
        data.functions[0].isReturnView = true;
      }
    }
    return data;
  };
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
      transform={transform}
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="showId"
              validate={[
                required(),
                maxLength(10),
              ]}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              onChange={(choice) => getData(choice.target.value)}
              validate={
                required()
              }
              disabled
            />
          </Grid>
          <Grid item xs={3} md={5}>
            <BooleanInput
              source="isDefault"
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(50)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="groupId"
              choices={groups ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={[
                required()]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="sort"
              validate={
                required()
              }
            />
          </Grid>
          <Grid container xs={12} md={12} mt={3} mx={1}>
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              {translate('resources.integration/screens.fields.listFunctions')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <ArrayInput
              label={false}
              source="functions"
              sx={{
                marginTop: '0',
              }}
            >
              <SimpleFormIterator className="rowfunction">
                <BooleanInput
                  source="isReturnView"
                  onClick={(change) => uncheck(change)}
                  defaultValue={false}
                  className="isView"
                />
                <SelectInput
                  focused={false}
                  source="id"
                  choices={functions ?? []}
                  emptyValue={null}
                  optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
                  validate={[required()]}
                  sx={{ width: '80%' }}
                  variant="outlined"
                  helperText={false}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
