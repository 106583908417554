import scholasticList from './scholasticList';
import createScholastic from './createScholastic';
import editScholastic from './editScholastic';
import showScholastic from './showScholastic';

export default {
  list: scholasticList,
  create: createScholastic,
  edit: editScholastic,
  show: showScholastic,
};
