import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  SelectInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { types } = useSelector((state: any) => state.faculties);
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canAdd = canAccess(permissions, FUNC.ADD_FACULTY);
  const canSearch = canAccess(permissions, FUNC.SEARCH_FACULTIES);

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <SelectInput
              source="type"
              choices={types ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="code"
              validate={[
                required(),
                maxLength(10),
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="name"
              fullWidth
              variant="outlined"
              helperText={false}
              validate={[
                required(),
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(200),
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
