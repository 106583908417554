/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_SEMESTER);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SEMESTERS);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.scholasticName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="scholasticName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.startAt')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <DateField
              locales="en-gb"
              fullWidth
              source="startAt"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.endAt')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <DateField
              locales="en-gb"
              fullWidth
              source="endAt"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.isDefault')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isDefault"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.isEnd')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isEnd"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/semesters.fields.description')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="description"
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
