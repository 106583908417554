import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useTranslate,
  Button as RAButton,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import PostPagination from '../../commons/postPagination';
import filters from '../../user/students/filters';
import ListActions from '../../commons/listActions';
import AddStudentToSubjectClassButton from './addStudentToSubjectClassButton';
import ShowField from '../../commons/showField';

export default React.memo((props: any) => {
  const {
    title,
    getStudents,
  } = props;

  const translate = useTranslate();

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenConfirmButton = () => setOpenDialog(true);
  const handleCloseConfirmButton = () => setOpenDialog(false);

  return (
    <>
      <RAButton
        label="dut.addStudentToSubjectClass"
        onClick={handleOpenConfirmButton}
      >
        <AddIcon />
      </RAButton>
      <Dialog maxWidth="lg" fullWidth onClose={() => setOpenDialog(false)} open={openDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List
              resource="user/students"
              actions={<ListActions isCreate={false} isExport={false} />}
              filters={filters}
              perPage={100}
              pagination={<PostPagination />}
              className="student-list"
              disableSyncWithLocation
            >
              <Datagrid
                optimized
                bulkActionButtons={(
                  <AddStudentToSubjectClassButton
                    onAddStudentSuccess={() => {
                      getStudents();
                      setOpenDialog(false);
                    }}
                  />
                )}
              >
                <TextField source="id" />
                <TextField source="fullName" />
                <ShowField source="genderName" isTranslate />
                <TextField source="facultyName" />
                <TextField source="courseName" />
                <TextField source="activityClassName" />
                <DateField locales="en-gb" source="birthday" />
              </Datagrid>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmButton}>
            {translate('ra.action.close')}
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
});
