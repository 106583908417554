/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import store from '../redux/store';
import { actions as appActions } from '../redux/app';
import msgNo from '../utils/msgNo';
import { AxiosInterface } from '../interfaces/axiosInterface';

const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PATH}`;
const apiKey = `${process.env.REACT_APP_API_KEY}`;
const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT ?? '600', 10) * 1000;

const repository: AxiosInterface = axios.create({
  baseURL: apiUrl,
  timeout,
  headers: {
    'x-apikey': apiKey,
  },
});

repository.interceptors.request.use(
  (config: any) => {
    config.headers['x-requestid'] = uuidv4();
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const locale = localStorage.getItem('locale');
    if (locale) {
      config.headers['x-lang'] = locale;
    }
    const {
      app: { noLoading },
    } = store.getState();
    if (!noLoading) {
      store.dispatch(appActions.loading(true));
      store.dispatch(appActions.increaseCountLoading());
    }
    return config;
  },
  (error) => Promise.reject(error),
);

repository.interceptors.response.use(
  (response) => {
    const {
      app: { countLoading },
    } = store.getState();
    if (countLoading - 1 <= 0) {
      store.dispatch(appActions.loading(false));
    }
    store.dispatch(appActions.decreaseCountLoading());
    const { data } = response;
    if (data.code && data.code !== 200) {
      store.dispatch(appActions.forceLoading(false));
      if (data?.data?.redirectUrl) {
        window.location = data.data.redirectUrl;
        return Promise.reject();
      }
      let content = 'dut.msg.server_error';
      if (data.code === 201) {
        content = 'dut.msg.input_validate_error';
      } else {
        const message = msgNo[data.msgNo ?? 'E500'];
        content = message ? `dut.msg.${message}` : content;
      }
      document.dispatchEvent(new CustomEvent('ApiError', {
        detail: {
          content,
          type: 'error',
        },
      }));
      return Promise.reject(new Error(content));
    }
    return response;
  },
  (error) => {
    const {
      app: { countLoading },
    } = store.getState();
    if (countLoading - 1 <= 0) {
      store.dispatch(appActions.loading(false));
      store.dispatch(appActions.forceLoading(false));
    }
    store.dispatch(appActions.decreaseCountLoading());
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('timeout');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
      return Promise.resolve();
    }
    if (response.status === 403) {
      window.location.href = '/no-access';
      return Promise.resolve();
    }
    if (response.status === 400) {
      return Promise.reject(new Error('dut.msg.input_validate_error'));
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(error);
      return Promise.reject(error);
    }
    return Promise.reject(new Error('dut.msg.server_error'));
  },
);

export default repository;
