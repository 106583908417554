import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  TabbedShowLayout,
  Tab,
  useRecordContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowActions from '../../../components/commons/showActions';
import ShowLabel from '../../../components/commons/showLabel';
import ShowField from '../../../components/commons/showField';
import TablePermissionFeild from '../../../components/system/roles/tablePermissionFeild';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

function ShowRole(props: any) {
  const record = useRecordContext(props);
  return (
    <TabbedShowLayout>
      <Tab label="resources.system/roles.name">
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.system/roles.fields.showId" />
          </Grid>
          <Grid className="show-row-odd" item xs={9} md={10}>
            <ShowField source="showId" className="highLightField" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.system/roles.fields.name" />
          </Grid>
          <Grid className="show-row-odd" item xs={9} md={10}>
            <ShowField source="name" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.system/roles.fields.moduleName" />
          </Grid>
          <Grid className="show-row-odd" item xs={9} md={10}>
            <ShowField source="moduleName" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.system/roles.fields.description" />
          </Grid>
          <Grid className="show-row-odd" item xs={9} md={10}>
            <ShowField source="description" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.system/roles.fields.isAdmin" />
          </Grid>
        </Grid>
      </Tab>
      {record?.canGrantPermissions && (
        <Tab label="dut.permissions">
          <TablePermissionFeild source="permissions" label="" />
        </Tab>
      )}
      {record?.canGrantIamPermissions && (
        <Tab label="dut.iamPermissions">
          <TablePermissionFeild source="iamPermissions" label="" />
        </Tab>
      )}
    </TabbedShowLayout>
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_ROLE);
  const canSearch = canAccess(permissions, FUNC.SEARCH_ROLES);
  return (
    <Show actions={<ShowActions isEdit={canEdit} isList={canSearch} />}>
      <ShowRole />
    </Show>
  );
});
