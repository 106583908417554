/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  DateTimeInput,
  useTranslate,
  Edit,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import sharedFunction from '../../../utils/sharedFunction';

export default React.memo(() => {
  const { modules, environments } = useSelector((state: any) => state.apiKeys);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_API_KEY);
  const canSearch = canAccess(permissions, FUNC.SEARCH_API_KEYS);
  const canShow = canAccess(permissions, FUNC.SHOW_API_KEY_DETAIL);
  const translate = useTranslate();
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
              disabled
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="aud"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="description"
              validate={[
                maxLength(200)]}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <DateTimeInput
              source="from"
              parse={sharedFunction.dateParse}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <DateTimeInput
              source="to"
              parse={sharedFunction.dateParse}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="redirectUrl"
              validate={[
                maxLength(255)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="appRedirectUrl"
              validate={[
                maxLength(255)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="iss"
              validate={[
                maxLength(255)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="crossOrigin"
              validate={[
                maxLength(500)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="env"
              choices={environments ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
