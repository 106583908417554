import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { types } = useSelector((state: any) => state.faculties);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const translate = useTranslate();

  const canEdit = canAccess(permissions, FUNC.EDIT_FACULTY);
  const canSearch = canAccess(permissions, FUNC.SEARCH_FACULTIES);
  const canShow = canAccess(permissions, FUNC.SHOW_FACULTY_DETAIL);
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
      transform={(data: any) => {
        data.code = data.facultyCode;
        return data;
      }}
    >
      <Grid
        container
        xs={12}
        md={12}
        mt={3}
        mx={1}
        sx={{ paddingLeft: 14 }}
      >
        <Typography variant="h6">
          {translate('resources.master/faculties.fields.facultyInfo')}
        </Typography>
      </Grid>
      <SimpleForm toolbar={<EditToolBar isSave={canEdit} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <SelectInput
              source="type"
              choices={types ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="facultyCode"
              validate={[
                required(),
                maxLength(10),
              ]}
              helperText={false}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="name"
              fullWidth
              variant="outlined"
              helperText={false}
              validate={[
                required(),
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(200),
              ]}
              helperText={false}
              rows={3}
            />
          </Grid>
          <Grid item md={1} />
          <Grid container xs={12} md={12} mt={3} mx={1} sx={{ paddingLeft: 14 }}>
            <Typography variant="h6">
              {translate('resources.master/faculties.fields.majorLabel')}
            </Typography>
          </Grid>
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <ArrayInput
              label={false}
              source="majors"
              sx={{
                marginTop: '0',
              }}
            >
              <SimpleFormIterator disableReordering>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="code"
                  validate={[
                    required(),
                    maxLength(100),
                  ]}
                  sx={{
                    marginTop: 2,
                  }}
                  helperText={false}
                />
                <TextInput
                  source="name"
                  fullWidth
                  variant="outlined"
                  helperText={false}
                  validate={[
                    required(),
                    maxLength(100),
                  ]}
                />
                <TextInput
                  fullWidth
                  multiline
                  variant="outlined"
                  source="description"
                  validate={[
                    maxLength(200),
                  ]}
                  helperText={false}
                  rows={2}
                  sx={{
                    marginBottom: 1.5,
                  }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
