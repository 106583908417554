import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  AutocompleteArrayInput,
  PasswordInput,
  TopToolbar,
  ListButton,
  useTranslate,
  required,
  regex,
  email,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import sharedFunction from '../../../utils/sharedFunction';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canAdd = canAccess(permissions, FUNC.ADD_USER);
  const canSearch = canAccess(permissions, FUNC.SEARCH_USERS);
  const translate = useTranslate();

  const { roles, genders } = useSelector((state: any) => state.users);

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="dut-form"
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />} defaultValues={{ gender: 1 }}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="firstName"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="lastName"
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="email"
              validate={[required(), email()]}
              type="email"
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="phone"
              variant="outlined"
              helperText={false}
              fullWidth
              validate={[
                regex(
                  /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                  'dut.msg.phone_number_format_invalidate',
                ),
              ]}
              inputProps={{ maxLength: 15 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="username"
              validate={[
                required(),
                regex(/^[a-zA-Z0-9_.-]{4,50}$/, 'dut.msg.username_format_invalidate'),
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{
                maxLength: 50,
                readOnly: true,
              }}
              onFocus={sharedFunction.removeReadOnly}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <AutocompleteArrayInput
              source="roleIds"
              choices={roles ?? []}
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="password"
              validate={[
                required(),
                regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'dut.msg.password_format_invalidate'),
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="confirmPassword"
              validate={[
                required(),
                sharedFunction.confirmPasswordValidation,
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <SelectInput
              source="gender"
              choices={genders ?? []}
              validate={required()}
              emptyValue={null}
              fullWidth
              variant="outlined"
              helperText={false}
              optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <DateInput
              source="birthday"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="taxCode"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 100 }}
              rows={3}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="nationality"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 255 }}
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="ethicity"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 255 }}
              rows={3}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="religion"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 255 }}
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={16} md={10}>
            <TextInput
              multiline
              source="note"
              variant="outlined"
              helperText={false}
              fullWidth
              inputProps={{ maxLength: 500 }}
              rows={3}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
