import * as React from 'react';
import {
  Button,
  Confirm,
  useListContext,
  useNotify,
  useTranslate,
  useUnselect,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/subject/subjectClasses';

interface IAddStudentsButtonProps {
  onAddStudentSuccess: (() => void),
}
export default React.memo((props: IAddStudentsButtonProps) => {
  const { onAddStudentSuccess } = props;
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = React.useState(false);

  const { resource, selectedIds } = useListContext();
  const unselect = useUnselect(resource);

  const handleOpenConfirmButton = () => setOpenDialog(true);
  const handleCloseConfirmButton = () => setOpenDialog(false);
  const onClickAddStudents = () => {
    dispatch(
      actions.addStudentsToSubjectClass(
        {
          data: { studentIds: selectedIds },
          id,
          onSuccess: (response: any) => {
            const { data: { addCount } } = response;
            notify(
              translate('dut.importStudentSuccess'),
              {
                type: 'success',
                autoHideDuration: 1000,
                messageArgs: {
                  smart_count: addCount,
                },
              },
            );
            setOpenDialog(false);
            unselect(selectedIds);
            onAddStudentSuccess();
          },
          onFailed: () => {
            setOpenDialog(false);
          },
          callback: () => {
            setOpenDialog(false);
          },
        },
      ),
    );
  };

  return (
    <>
      <Button
        label="ra.action.add"
        onClick={handleOpenConfirmButton}
      >
        <AddIcon />
      </Button>
      <Confirm
        isOpen={openDialog}
        title={translate('dut.addStudentTitle')}
        content={translate('dut.addStudentContentDialog', { count: selectedIds?.length || 0 })}
        onConfirm={onClickAddStudents}
        onClose={handleCloseConfirmButton}
      />
    </>
  );
});
