import React from 'react';
import { SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectFaculties({ source }: any) {
  const { faculties } = useSelector((state: any) => state.subjects);
  return (
    <SelectInput
      source={source}
      choices={faculties ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectFaculties
    source="facultyId"
  />,
];
