import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../../repositories/system/logs';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getInitData() {
  yield takeLatest(actions.getInitData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getInitData);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* resendEmail() {
  yield takeLatest(actions.resendEmail, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.resendEmail, data.payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getInitData),
    fork(resendEmail),
  ]);
}
