const messages = {
  resources: {
    'integration/apis': {
      name: 'Nhóm API |||| Nhóm API',
      fields: {
        showId: 'Mã',
        id: 'Mã',
        module: 'Mô đun',
        moduleId: 'Mô đun',
        groupName: 'Nhóm API',
        groupId: 'Nhóm API',
        name: 'Tên API',
        area: 'Khu vực',
        apiCode: 'Code',
        controller: 'Controller',
        action: 'Hành động',
        url: 'Đường dẫn',
        isActive: 'Đang hoạt động',
      },
    },
  },
};

export default messages;
