export default {
  SEARCH_ROLES: 'ADM_FUN01001',
  SHOW_ROLE_DETAIL: 'ADM_FUN01002',
  ADD_ROLE: 'ADM_FUN01003',
  EDIT_ROLE: 'ADM_FUN01004',
  DELETE_ROLE: 'ADM_FUN01005',
  GRANT_PERMISSION: 'ADM_FUN01006',
  SETTINGS: 'ADM_FUN01007',
  GENERAL_SETTING: 'ADM_FUN01008',
  EMAIL_SETTING: 'ADM_FUN01009',
  FOREIGN_LANGUAGE_SETTING: 'ADM_FUN01010',
  TYPE_OF_STUDENT_SETTING: 'ADM_FUN01011',
  TYPE_OF_TEACHER_SETTING: 'ADM_FUN01012',
  POS_IN_CLASS_SETTING: 'ADM_FUN01013',
  POS_IN_FACULTY_SETTING: 'ADM_FUN01014',
  POS_IN_MAJOR_SETTING: 'ADM_FUN01015',
  POS_IN_YUS_SETTING: 'ADM_FUN01016',
  POS_IN_CPV_SETTING: 'ADM_FUN01017',
  POS_IN_DEPARTMENT_SETTING: 'ADM_FUN01018',
  LOGS: 'ADM_FUN01019',
  LOG_CALL_API: 'ADM_FUN01020',
  LOG_NOTIFICATION: 'ADM_FUN01021',
  LOG_EMAIL: 'ADM_FUN01022',
  LOG_EXCEPTION: 'ADM_FUN01023',
  EXPORT_MODULE_DATA: 'ADM_FUN01024',
  IMPORT_MODULE_DATA: 'ADM_FUN01025',
  BLOB_STORAGE_SETTING: 'ADM_FUN01026',

  SEARCH_MODULES: 'ADM_FUN02001',
  SHOW_MODULE_DETAIL: 'ADM_FUN02002',
  ADD_MODULE: 'ADM_FUN02003',
  EDIT_MODULE: 'ADM_FUN02004',
  DELETE_MODULE: 'ADM_FUN02005',
  GRANT_MODULE_PERMISSION: 'ADM_FUN02006',
  SEARCH_API_KEYS: 'ADM_FUN02007',
  SHOW_API_KEY_DETAIL: 'ADM_FUN02008',
  ADD_API_KEY: 'ADM_FUN02009',
  EDIT_API_KEY: 'ADM_FUN02010',
  DELETE_API_KEY: 'ADM_FUN02011',
  SEARCH_SCREEN_GROUPS: 'ADM_FUN02012',
  SHOW_SCREEN_GROUP_DETAIL: 'ADM_FUN02013',
  ADD_SCREEN_GROUP: 'ADM_FUN02014',
  EDIT_SCREEN_GROUP: 'ADM_FUN02015',
  DELETE_SCREEN_GROUP: 'ADM_FUN02016',
  SEARCH_SCREENS: 'ADM_FUN02017',
  SHOW_SCREEN_DETAIL: 'ADM_FUN02018',
  ADD_SCREEN: 'ADM_FUN02019',
  EDIT_SCREEN: 'ADM_FUN02020',
  DELETE_SCREEN: 'ADM_FUN02021',
  SEARCH_FUNCTIONS: 'ADM_FUN02022',
  SHOW_FUNCTION_DETAIL: 'ADM_FUN02023',
  ADD_FUNCTION: 'ADM_FUN02024',
  EDIT_FUNCTION: 'ADM_FUN02025',
  DELETE_FUNCTION: 'ADM_FUN02026',
  SEARCH_MENU_ITEMS: 'ADM_FUN02027',
  SHOW_MENU_ITEM_DETAIL: 'ADM_FUN02028',
  ADD_MENU_ITEM: 'ADM_FUN02029',
  EDIT_MENU_ITEM: 'ADM_FUN02030',
  DELETE_MENU_ITEM: 'ADM_FUN02031',
  PREVIEW_MENU: 'ADM_FUN02032',
  ARRANGE_MENU: 'ADM_FUN02033',
  SEARCH_API_GROUPS: 'ADM_FUN02034',
  SHOW_API_GROUP_DETAIL: 'ADM_FUN02035',
  ADD_API_GROUP: 'ADM_FUN02036',
  EDIT_API_GROUP: 'ADM_FUN02037',
  DELETE_API_GROUP: 'ADM_FUN02038',
  SEARCH_API: 'ADM_FUN02039',
  SHOW_API_DETAIL: 'ADM_FUN02040',
  ADD_API: 'ADM_FUN02041',
  EDIT_API: 'ADM_FUN02042',
  DELETE_API: 'ADM_FUN02043',
  SEARCH_WEBHOOK: 'ADM_FUN02044',
  SHOW_WEBHOOK_DETAIL: 'ADM_FUN02045',
  ADD_WEBHOOK: 'ADM_FUN02046',
  EDIT_WEBHOOK: 'ADM_FUN02047',
  DELETE_WEBHOOK: 'ADM_FUN02048',

  SEARCH_USERS: 'ADM_FUN03001',
  SHOW_USER_DETAIL: 'ADM_FUN03002',
  ADD_USER: 'ADM_FUN03003',
  EDIT_USER: 'ADM_FUN03004',
  DELETE_USER: 'ADM_FUN03005',
  IMPORT_USERS: 'ADM_FUN03006',
  EXPORT_USERS: 'ADM_FUN03007',
  LOCK_ACCOUNT: 'ADM_FUN03008',
  UNLOCK_ACCOUNT: 'ADM_FUN03009',
  ADD_ACCOUNT_TO_BLACK_LIST: 'ADM_FUN03010',
  REMOVE_ACCOUNT_FROM_BLACK_LIST: 'ADM_FUN03011',
  SEARCH_STUDENTS: 'ADM_FUN03012',
  SHOW_STUDENT_DETAIL: 'ADM_FUN03013',
  ADD_STUDENT: 'ADM_FUN03014',
  EDIT_STUDENT: 'ADM_FUN03015',
  DELETE_STUDENT: 'ADM_FUN03016',
  IMPORT_STUDENT: 'ADM_FUN03017',
  EXPORT_STUDENT: 'ADM_FUN03018',
  SEARCH_TEACHERS: 'ADM_FUN03019',
  SHOW_TEACHER_DETAIL: 'ADM_FUN03020',
  ADD_TEACHER: 'ADM_FUN03021',
  EDIT_TEACHER: 'ADM_FUN03022',
  DELETE_TEACHER: 'ADM_FUN03023',
  IMPORT_TEACHER: 'ADM_FUN03024',
  EXPORT_TEACHER: 'ADM_FUN03025',
  EDIT_ROLE_STUDENT: 'ADM_FUN03026',
  EDIT_ROLE_TEACHER: 'ADM_FUN03027',

  SEARCH_SUBJECTS: 'ADM_FUN04001',
  SHOW_SUBJECT_DETAIL: 'ADM_FUN04002',
  ADD_SUBJECT: 'ADM_FUN04003',
  EDIT_SUBJECT: 'ADM_FUN04004',
  DELETE_SUBJECT: 'ADM_FUN04005',
  SEARCH_SUBJECT_CLASSES: 'ADM_FUN04006',
  SHOW_SUBJECT_CLASS_DETAIL: 'ADM_FUN04007',
  ADD_SUBJECT_CLASS: 'ADM_FUN04008',
  EDIT_SUBJECT_CLASS: 'ADM_FUN04009',
  DELETE_SUBJECT_CLASS: 'ADM_FUN04010',

  VIEW_CALENDAR: 'ADM_FUN05001',
  SEARCH_FACULTIES: 'ADM_FUN05002',
  SHOW_FACULTY_DETAIL: 'ADM_FUN05003',
  ADD_FACULTY: 'ADM_FUN05004',
  EDIT_FACULTY: 'ADM_FUN05005',
  DELETE_FACULTY: 'ADM_FUN05006',
  SEARCH_DEPARTMENTS: 'ADM_FUN05007',
  SHOW_DEPARTMENT_DETAIL: 'ADM_FUN05008',
  ADD_DEPARTMENT: 'ADM_FUN05009',
  EDIT_DEPARTMENT: 'ADM_FUN05010',
  DELETE_DEPARTMENT: 'ADM_FUN05011',
  SEARCH_COURSES: 'ADM_FUN05012',
  SHOW_COURSE_DETAIL: 'ADM_FUN05013',
  ADD_COURSE: 'ADM_FUN05014',
  EDIT_COURSE: 'ADM_FUN05015',
  DELETE_COURSE: 'ADM_FUN05016',
  SEARCH_ACTIVITY_CLASSES: 'ADM_FUN05017',
  SHOW_ACTIVITY_CLASS_DETAIL: 'ADM_FUN05018',
  ADD_ACTIVITY_CLASS: 'ADM_FUN05019',
  EDIT_ACTIVITY_CLASS: 'ADM_FUN05020',
  DELETE_ACTIVITY_CLASS: 'ADM_FUN05021',
  SEARCH_SCHOLASTICS: 'ADM_FUN05022',
  SHOW_SCHOLASTIC_DETAIL: 'ADM_FUN05023',
  ADD_SCHOLASTIC: 'ADM_FUN05024',
  EDIT_SCHOLASTIC: 'ADM_FUN05025',
  DELETE_SCHOLASTIC: 'ADM_FUN05026',
  SEARCH_SEMESTERS: 'ADM_FUN05027',
  SHOW_SEMESTER_DETAIL: 'ADM_FUN05028',
  ADD_SEMESTER: 'ADM_FUN05029',
  EDIT_SEMESTER: 'ADM_FUN05030',
  DELETE_SEMESTER: 'ADM_FUN05031',

};
