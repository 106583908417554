/* eslint-disable max-len */
const msg: any = {
  confirm_save_data: 'Do you really want to save this data?',
  confirm_delete_data: 'Do you really want to delete this data?',
  confirm_reset_password: 'Do you really want to reset the password for this account?',
  confirm_change_password: 'Do you really want to change your password?',
  confirm_unlock_account: 'Do you really want to unlock this account?',
  confirm_verify_account: 'Do you really want to verify this account?',
  confirm_lock_account: 'Do you really want to lock this account?',
  confirm_add_account_to_black_list: 'Do you really want to add this account to blacklist?',
  confirm_remove_account_from_black_list: 'Do you really want to remove this account from blacklist?',

  save_data_success: 'Saved data successfully.',
  delete_data_success: 'Deleted data successfully.',
  create_js_success: 'Successfully created javascript file.',
  build_file_success: 'Rebuilt system function file successfully.',
  reset_password_success: 'Password has been reset successfully.',
  clone_data_success: 'Data copied successfully.',
  send_email_for_reset_password_success: 'Please check your email to reset your password. If it doesn\'t appear for a few minutes, check your junk folder.',
  send_email_for_active_account_success: 'Activation mail has been sent to your registered email address, please check your email to activate your account.',
  change_password_success: 'Password changed successfully.',
  save_profile_success: 'Your profile has been updated.',

  warning_create_js: 'Regenerating this javascript file may cause some unexpected changes to the system, do you want to continue?',
  warning_build_file: 'Rebuilding the system\'s functions file may cause some unexpected changes, and it will have to rebuild the entire project, do you want to continue?',

  required: 'This content cannot be left blank.',
  email_invalidate: 'Email invalidate.',
  date_invalidate: 'The date is not in the correct format.',
  url_format_invalidate: 'The link is not in the correct format.',
  maxlength_invalidate: 'You are not allowed to enter more than {0} characters.',
  minlength_invalidate: 'You must enter at least {0} characters.',
  value_must_geater: 'This value must be greater than or equal to {0}.',
  value_must_lesser: 'This value must be less than or equal to {0}.',
  phone_number_format_invalidate: 'Invalid phone number.',
  time_from_must_be_less_than_time_to: 'The start time must be less than the end time.',
  data_duplicate: 'Duplicate data.',
  username_not_correct: 'Account does not exist.',
  password_not_correct: 'Incorrect password.',
  username_or_password_not_correct: 'Incorrect account or password.',
  username_had_used: 'This username is already registered. Please use another username!',
  system_must_have_least_1_user: 'The system must exist at least 1 account to use.',
  password_format_invalidate: 'Password must contain at least 8 characters, contain uppercase, lowercase and special characters.',
  confirm_password_invalidate: 'Confirm password does not match, please check again.',
  username_format_invalidate: 'Username must have at least 4 characters, can only enter letters, numbers and periods (.), hyphen (-), underscore (_).',
  file_size_too_larger: 'File size exceeds the allowed size.',
  extension_not_allow: 'The file is not in the correct format.',
  cannot_upload_many_files: 'Cannot upload more than {0} files at once.',
  upload_file_error: 'There was an error while downloading the file, please try again.',
  you_must_choose_file: 'Please select the file to upload.',
  size_of_img_invalidate: 'Image size is not correct.',
  error_min_item: 'This operation cannot be performed, because the number of items displayed on the homepage has reached the minimum.',
  slug_format_invalidate: 'This link can only contain unsigned strings consisting of lowercase, uppercase and hyphen (-).',
  save_data_error: 'Saving data failed, please try again.',
  delete_data_error: 'Delete data failed, please try again.',
  number_of_record_too_larger: 'An excessive number of results cannot be displayed.',
  create_js_error: 'There was an error while creating the file, please try again.',
  data_existing: 'This data already exists in the system.',
  message_code_existing: 'The code of the message already exists in the system.',
  function_code_existing: 'The code of the function already exists in the system.',
  group_have_account: 'There is an account in this group, so it can\'t be deleted, please delete all accounts of this group first.',
  data_export_not_exists: 'No export data.',
  email_not_correct: 'This email does not exist.',
  token_timeout: 'Credentials have expired, please try again.',
  email_had_used: 'This email is already registered. Please use another email!',
  account_was_locked: 'The account has been temporarily locked, please contact the administrator for assistance.',
  account_in_blacklist: 'Your account has been blacklisted, please contact your administrator for assistance.',
  value_from_must_be_less_than_value_to: 'The start value must be less than the end value.',
  select_at_least_1_line: 'You must select at least 1 line.',
  token_invalidate: 'The credentials are incorrect, please try again.',
  must_be_number: 'The value must be a number.',
  can_not_delete_module: 'The module cannot be deleted because there are resources in use.',
  module_unactive: 'The resource could not be added because the module is not active.',
  can_not_delete_screen: 'The Screen cannot be deleted because there are resources in use.',
  can_not_delete_function: 'The function cannot be deleted because there are resources in use.',
  menu_level_too_big: 'The level of the menu is not valid.',
  email_or_phone_not_verified: 'Email or phone number is not verified, please use username to login.',
  can_not_delete_api_group: 'Cannot delete the API group because there are APIs belonging to this API group that are currently in use.',
  can_not_delete_screen_group: 'Cannot delete the screen group because there are screens belonging to this screen group that are currently in use.',
  code_invalid: 'Data contains invalid character, can only enter letters, numbers and periods (.), hyphen (-), underscore (_).',
  cannot_delete_role_have_users: 'Cannot delete role(s) because there is an user in use',
  can_not_read_file_data: 'The data cannot be read from the file.',
  file_data_invalid: 'File data is invalid.',
  file_extension_invalid: 'File must has extensions .xlsx.',
  faculty_code_existing: 'Existed a faculty with this code.',
  semester_date_start_invalidate: 'Semester date start invalidate.',
  semester_date_end_invalidate: 'Semester date start invalidate.',
  invalid_faculty: 'The faculty is invalid.',
  mail_system_username_format_invalidate: 'Mail system username must have at least 4 characters, can only enter letters, numbers and periods (.), hyphen (-), underscore (_), at (@).',
  webhook_not_have_apikey: 'The webhook is not registered with an API key.',
  email_sending: 'Email is sending.',
  email_sended: 'Email sended.',
  webhook_stopped: 'Webhook is down, please go to the management page to change the status.',

  input_validate_error: 'The input data is not correct, please try again.',
  have_error: 'An error occurred, please try again later.',
  you_must_login: 'You need to be logged in to perform this action.',
  you_not_have_permission: 'You do not have permission to perform this action.',
  not_found: 'Requested data not found.',
  server_error: 'The system failed, please try again later.',
};
export default msg;
