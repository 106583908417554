import * as React from 'react';
import {
  required,
  useTranslate,
  useNotify,
  Form,
  PasswordInput,
  regex,
} from 'react-admin';
import {
  Avatar, Box, CardActions, CircularProgress, Button, Typography, Card,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/app';
import background from '../assets/imgs/common/loginBg.jpg';
import logo from '../assets/imgs/common/logo.png';

export default React.memo(() => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.password !== value) {
      return 'dut.msg.cornfirm_password_invalidate';
    }
    return undefined;
  };
  const onSuccess = () => {
    setLoading(false);
    setIsResetPassword(true);
  };

  const onError = () => {
    setLoading(false);
    notify('dut.msg.token_timeout', {
      type: 'error',
    });
  };
  const backLoginPage = () => {
    window.location.href = '/login';
  };

  const handleSubmit = (value: any) => {
    setLoading(true);
    const data = {
      ...value,
      email: searchParams.get('email'),
      token: searchParams.get('token'),
    };
    const payload = {
      data,
      onSuccess,
      onError,
    };
    dispatch(actions.resetPassword(payload));
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card
          sx={{
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: '#000',
            opacity: 0.4,
            zIndex: 1,
          }}
        />
        <Card
          sx={{
            minWidth: 300,
            position: 'relative',
            zIndex: 2,
            marginTop: '7em',
            marginBottom: '10em',
          }}
        >
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }} alt="logo" src={logo} variant="square" />
          </Box>
          <Box
            sx={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            DUT IAM
          </Box>
          {!isResetPassword && (
            <>
              <Box sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
              }}
              >
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{
                    margin: '1em',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    color: '#2D333A',
                  }}
                >
                  {translate('dut.reset_your_password')}
                </Typography>
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{ width: '100%' }}
                >
                  {translate('dut.type_password_to_reset')}
                </Typography>
                <Box sx={{ marginTop: '1em' }}>
                  <PasswordInput
                    source="password"
                    label={translate('dut.newPassword')}
                    validate={[
                      required(),
                      // eslint-disable-next-line max-len
                      regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'dut.msg.password_format_invalidate'),
                    ]}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    helperText={false}
                    disabled={loading}
                  />
                  <PasswordInput
                    source="confirmPassword"
                    label={translate('dut.confirmPassword')}
                    validate={[required(), confirmPasswordValidation]}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    helperText={false}
                    disabled={loading}
                  />
                </Box>
              </Box>
              <CardActions sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.reset_password')}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={backLoginPage}
                  color="primary"
                  disabled={loading}
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                  }}
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.back_login_page')}
                </Button>
              </CardActions>

            </>
          )}
          {isResetPassword && (
            <>
              <Box sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
              }}
              >
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{
                    margin: '1em',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    color: '#2D333A',
                  }}
                >
                  {translate('dut.password_change')}
                </Typography>
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{ width: '100%' }}
                >
                  {translate('dut.password_change_msg')}
                </Typography>
              </Box>
              <CardActions sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
              }}
              >
                <Button
                  variant="contained"
                  type="button"
                  onClick={backLoginPage}
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.back_login_page')}
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      </Box>
    </Form>
  );
});
