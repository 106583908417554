import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettingSuccess: (state, action) => {
      state.data = action.payload;
    },
    getSettingFailure: (state) => {
      state.data = [];
    },
  },
});

const actionsTmp: any = { ...settings.actions };
actionsTmp.getSetting = createAction('settings/getSetting');
actionsTmp.putInt = createAction('settings/putInt');
actionsTmp.putMailSystem = createAction('settings/putMailSystem');
actionsTmp.putString = createAction('settings/putString');
actionsTmp.putBlobStorage = createAction('settings/putBlobStorage');
actionsTmp.putOther = createAction('settings/putOther');

export const actions = { ...actionsTmp };

export const settingsSelector = (state: any) => state;

export default settings.reducer;
