import * as React from 'react';
import {
  Datagrid,
  DatagridHeader,
  DatagridBody,
  RecordContextProvider,
  DatagridRowProps,
  DatagridBodyProps,
  DatagridProps,
  FieldProps,
  DatagridHeaderProps,
} from 'react-admin';
import {
  TableCell, TableRow, Checkbox,
} from '@mui/material';
import BulkActionButtons from './bulkActionButtons';

function StudentDatagridRow({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}: DatagridRowProps) {
  return id ? (
    <RecordContextProvider value={record}>
      <TableRow>
        {/* first column: selection checkbox */}
        <TableCell>
          {selectable && (
            <Checkbox
              checked={selected}
              onClick={(event) => {
                if (onToggleItem) {
                  onToggleItem(id, event);
                }
              }}
              sx={{ padding: 0 }}
            />
          )}
        </TableCell>
        {/* data columns based on children */}
        {React.Children.map(children, (field) => (React.isValidElement<FieldProps>(field) ? (
          <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
        ) : null))}
      </TableRow>
    </RecordContextProvider>
  ) : null;
}

function StudentDatagridBody(props: DatagridBodyProps) {
  return <DatagridBody {...props} row={<StudentDatagridRow />} />;
}

function StudentDataGridHeader(props: DatagridHeaderProps) {
  return <DatagridHeader {...props} hasBulkActions />;
}

interface CustomDataGridProps {
  getStudents: (() => void)
}

export default React.memo((props: DatagridProps & CustomDataGridProps) => (
  <Datagrid
    {...props}
    body={<StudentDatagridBody />}
    header={<StudentDataGridHeader />}
    bulkActionButtons={(
      <BulkActionButtons
        getStudents={props.getStudents}
      />
    )}
  />
));
