const messages = {
  resources: {
    'subject/subjects': {
      name: 'Học phần |||| Học phần',
      fields: {
        id: 'Id',
        subjectCode: 'Mã học phần',
        code: 'Mã học phần',
        name: 'Tên học phần',
        facultyId: 'Khoa',
        facultyName: 'Khoa',
        description: 'Mô tả',
        subjectClass: 'Danh sách lớp học phần',
      },
    },
  },
};

export default messages;
