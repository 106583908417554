import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  types: [],
};

export const faculties = createSlice({
  name: 'faculties',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.types = action.payload.types;
    },
    getInitDataFailure: (state) => {
      state.types = [];
    },
  },
});

const actionsTmp: any = { ...faculties.actions };
actionsTmp.getInitData = createAction('faculties/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default faculties.reducer;
