const messages = {
  resources: {
    'master/scholastics': {
      name: 'Năm học |||| Năm học',
      fields: {
        id: 'Id',
        name: 'Thời gian',
        description: 'Mô tả',
        semesters: 'Học kỳ',
        startAt: 'Thời gian bắt đầu',
        endAt: 'Thời gian kết thúc',
        startYear: 'Năm bắt đầu',
        endYear: 'Năm kết thúc',
      },
    },
  },
};

export default messages;
