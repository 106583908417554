import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  SelectInput,
  useGetList,
  FormDataConsumer,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import { validateId } from '../../../utils/constant';

export default React.memo(() => {
  const { modules } = useSelector((state: any) => state.apis);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canAdd = canAccess(permissions, FUNC.ADD_API);
  const canSearch = canAccess(permissions, FUNC.SEARCH_API);

  const GetApiGroups = (moduleId: any) => {
    const { data, isLoading, error } = useGetList(
      'integration/api-groups',
      { filter: { moduleId, delFlag: false, isActive: true } },
    );
    if (isLoading) return [];
    if (error) return [];

    return data;
  };

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="id"
              validate={[
                required(),
                maxLength(15),
                validateId,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="apiCode"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <Grid item xs={12} md={5}>
                  <SelectInput
                    source="moduleId"
                    choices={modules ?? []}
                    fullWidth
                    optionText={(choice) => choice.name}
                    variant="outlined"
                    validate={
                      required()
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <SelectInput
                    source="groupId"
                    choices={GetApiGroups(formData.moduleId ?? '')}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </FormDataConsumer>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="area"
              validate={[
                maxLength(200)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="controller"
              validate={[
                maxLength(200)]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="action"
              validate={[
                maxLength(200)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="url"
              validate={[
                maxLength(200)]}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
