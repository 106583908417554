import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
  menus: [],
  functions: [],
  menuPosition: [],
};

export const menus = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
      state.menus = action.payload.menus;
      state.functions = action.payload.functions;
    },
    getInitDataFailure: (state) => {
      state.modules = [];
      state.menus = [];
      state.functions = [];
    },
    getPlaceMenuSuccess: (state, action) => {
      state.menuPosition = action.payload.menuPos;
    },
    getPlaceMenuFailure: (state) => {
      state.menuPosition = [];
    },
  },
});

const actionsTmp: any = { ...menus.actions };
actionsTmp.getInitData = createAction('menus/getInitData');
actionsTmp.getPlaceMenu = createAction('menus/getPlaceMenu');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default menus.reducer;
