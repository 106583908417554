import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  roles: [],
  departments: [],
  faculties: [],
  typeOfTeachers: [],
  positionsInYus: [],
  positionsInFaculty: [],
  positionsInDepartment: [],
  positionsInMajor: [],
  languages: [],
  provinces: [],
  genders: [],
  countries: [],
  ethnicities: [],
  religions: [],
  identityTypes: [],
  cCCDCardIssuer: '',
  cpvPlacesOfActivity: [],
  cpvMemberType: [],
};

export const teachers = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.roles = action.payload.roles;
      state.departments = action.payload.departments;
      state.faculties = action.payload.faculties;
      state.typeOfTeachers = action.payload.typeOfTeachers;
      state.positionsInYus = action.payload.positionsInYus;
      state.positionsInFaculty = action.payload.positionsInFaculty;
      state.positionsInDepartment = action.payload.positionsInDepartment;
      state.positionsInMajor = action.payload.positionsInMajor;
      state.languages = action.payload.languages;
      state.provinces = action.payload.provinces;
      state.genders = action.payload.genders;
      state.countries = action.payload.countries;
      state.ethnicities = action.payload.ethnicities;
      state.religions = action.payload.religions;
      state.identityTypes = action.payload.identityTypes;
      state.cCCDCardIssuer = action.payload.cccdCardIssuer;
      state.cpvPlacesOfActivity = action.payload.cpvPlacesOfActivity;
      state.cpvMemberType = action.payload.cpvMemberType;
    },
    getInitDataFailure: (state) => {
      state.roles = [];
      state.departments = [];
      state.faculties = [];
      state.typeOfTeachers = [];
      state.positionsInYus = [];
      state.positionsInFaculty = [];
      state.positionsInDepartment = [];
      state.positionsInMajor = [];
      state.languages = [];
      state.provinces = [];
      state.genders = [];
      state.countries = [];
      state.ethnicities = [];
      state.religions = [];
      state.identityTypes = [];
      state.cCCDCardIssuer = '';
      state.cpvPlacesOfActivity = [];
      state.cpvMemberType = [];
    },
  },
});

const actionsTmp: any = { ...teachers.actions };
actionsTmp.getInitData = createAction('teachers/teachers/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default teachers.reducer;
