import repositories from '..';

const resource = '/user/teachers';

export default {
  getInitData: (data:any) => {
    let paramerterString = '';
    if (Object.keys(data).length > 0) {
      const keys = Object.keys(data);
      keys.forEach((key:string, index: number) => {
        if (index === 0) {
          paramerterString += `?${key}=${data[key]}`;
        } else {
          paramerterString += `&${key}=${data[key]}`;
        }
      });
    }
    return repositories.get(`${resource}/data/init${paramerterString}`);
  },
  postAvatarImage: (
    teacherId: string,
    file: FormData,
  ) => repositories.postForm(`${resource}/${teacherId}/avatar`, file),
};
