/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import SubjectClassField from '../../../components/subject/subjects/subjectClassField';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_SUBJECT);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SUBJECTS);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subjects.fields.subjectCode')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="subjectCode"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subjects.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subjects.fields.facultyName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="facultyName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subjects.fields.description')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="description"
            />
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            mt={3}
            mx={1}
          >
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              {translate('resources.subject/subjects.fields.subjectClass')}
            </Typography>
          </Grid>
          <Grid item xs={16} md={16}>
            <SubjectClassField />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
