const messages = {
  resources: {
    'integration/api-keys': {
      name: 'ApiKeys |||| ApiKeys',
      fields: {
        id: 'Id',
        moduleId: 'Module',
        aud: 'Audience',
        name: 'Name',
        key: 'Key',
        loginKey: 'Login key',
        secret: 'Secret',
        description: 'Description',
        redirectUrl: 'Redirect Url',
        appRedirectUrl: 'App Redirect Url',
        iss: 'Issuer',
        crossOrigin: 'Cross Origin',
        from: 'Valid from',
        to: 'Expired at',
        env: 'Environment',
        isActive: 'Active',
      },
    },
  },
  dut: {
    development: 'Development',
    staging: 'Staging',
    production: 'Production',
  },
};

export default messages;
