import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
  DateField,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import ListActions from '../../../components/commons/listActions';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import PostPagination from '../../../components/commons/postPagination';
import TranslateField from '../../../components/commons/translateField';
import filters from '../../../components/user/teachers/filters';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_TEACHER);
  const canDelete = canAccess(permissions, FUNC.DELETE_TEACHER);
  const canPost = canAccess(permissions, FUNC.ADD_TEACHER);
  const canShow = canAccess(permissions, FUNC.SHOW_TEACHER_DETAIL);
  const canImport = canAccess(permissions, FUNC.IMPORT_TEACHER);
  const translate = useTranslate();
  return (
    <List
      filters={filters}
      actions={<ListActions resource="user/teachers" isExport isCreate={canPost} isImport={canImport} />}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="teacherCode" />
        <TextField source="fullName" />
        <TranslateField namespace="resources.user/teachers.fields" source="genderName" />
        <TextField label={translate('resources.user/teachers.fields.teachingFaculties')} source="teachingFaculties" />
        <DateField source="birthday" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="typeName" />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
