const messages = {
  resources: {
    'subject/subjects': {
      name: 'Subjects |||| Subject',
      fields: {
        id: 'Id',
        subjectCode: 'Code',
        code: 'Code',
        name: 'Name',
        facultyId: 'Faculty',
        facultyName: 'Faculty',
        description: 'Description',
        subjectClass: 'Subject classes',
      },
    },
  },
};

export default messages;
