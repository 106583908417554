const messages = {
  resources: {
    'master/faculties': {
      name: 'Đơn vị |||| đơn vị',
      fields: {
        id: 'Id',
        name: 'Tên đơn vị',
        facultyCode: 'Mã đơn vị',
        code: 'Mã chuyên ngành',
        typeName: 'Loại đơn vị',
        type: 'Loại đơn vị',
        description: 'Mô tả',
        facultyInfo: 'Thông tin khoa',
        majorLabel: 'Danh sách chuyên ngành',
        majors: {
          code: 'Mã chuyên ngành',
          name: 'Tên chuyên ngành',
          description: 'Mô tả',
        },
      },
    },
  },
};

export default messages;
