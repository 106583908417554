import React from 'react';
import {
  DateInput,
  SelectInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectProject({ source }: any) {
  const { projects } = useSelector((state: any) => state.logs);
  return (
    <SelectInput
      source={source}
      choices={projects ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectProject
    source="project"
  />,
  <DateInput
    source="from"
  />,
  <DateInput
    source="to"
  />,
];
