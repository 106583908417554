import * as React from 'react';
import { Layout, useNotify } from 'react-admin';
import appBar from './appBar';
import menu from './menu';

export default React.memo((props: any) => {
  const notify = useNotify();
  const showNotify = (e: any) => {
    const { detail } = e;
    notify(detail.content, {
      type: detail.type,
    });
  };
  React.useEffect(() => {
    document.removeEventListener('ApiError', showNotify);
    document.addEventListener('ApiError', showNotify);
  }, []);
  return (<Layout {...props} appBar={appBar} menu={menu} />);
});
