const messages = {
  resources: {
    'master/semesters': {
      name: 'Semesters |||| Semester',
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        semesters: 'Semesters',
        scholasticId: 'Scholastic',
        scholasticName: 'Scholastic',
        startAt: 'Time start',
        endAt: 'Time end',
        isDefault: 'Default semester',
        isEnd: 'Semester has ended',
        startYear: 'Year begin',
        endYear: 'Year end',
      },
    },
  },
};

export default messages;
