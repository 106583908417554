import userList from './userList';
import editUser from './editUser';
import createUser from './createUser';
import showUser from './showUser';

export default {
  list: userList,
  edit: editUser,
  create: createUser,
  show: showUser,
};
