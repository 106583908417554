/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  Edit,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_COURSE);
  const canSearch = canAccess(permissions, FUNC.SEARCH_COURSES);
  const canShow = canAccess(permissions, FUNC.SHOW_COURSE_DETAIL);
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canEdit} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={1} />
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={8}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(10),
              ]}
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={1} />
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={8}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(200),
              ]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
