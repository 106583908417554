import menuList from './menuList';
import createMenu from './createMenu';
import editMenu from './editMenu';
import showMenu from './showMenu';

export default {
  list: menuList,
  create: createMenu,
  edit: editMenu,
  show: showMenu,
};
