import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
};

export const oauth = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
  },
});

const actionsTmp: any = { ...oauth.actions };
actionsTmp.checkClient = createAction('oauth/checkClient');

export const actions = { ...actionsTmp };

export const oauthSelector = (state: any) => state;

export default oauth.reducer;
