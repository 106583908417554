import React from 'react';
import {
  BooleanInput,
  Button as RAButton,
  FileField,
  FileInput,
  SimpleForm,
  useNotify,
  useTranslate,
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import dataProvider from '../../dataProvider';

export default React.memo((props:any) => {
  const { label, title, resource } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const onCancel = () => {
    setOpen(false);
  };

  const clickImportButton = () => {
    setOpen(true);
  };

  const handleSubmit = async (value:any) => {
    const formData = new FormData();
    formData.append('fileData', value.fileData.rawFile);
    formData.append('isOverride', value.isOverride);
    setOpen(false);
    const response = await dataProvider.createMany(resource, { data: formData });

    const { res } = response;
    if (res.data && res.data.code === 200) {
      notify('import success', { type: 'success', autoHideDuration: 1000 });
    }
  };

  return (
    <>
      <RAButton
        color="primary"
        label={label}
        onClick={clickImportButton}
      >
        <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
      </RAButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SimpleForm toolbar={false} onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {title}
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FileInput
              source="fileData"
              multiple={false}
              accept=".xlsx"
              fullWidth
              label={false}
            >
              <FileField source="src" title="title" />
            </FileInput>
            <BooleanInput source="isOverride" fullWidth label={translate('dut.isOverride')} />
          </DialogContent>
          <DialogActions>
            <RAButton type="submit" label={translate('ra.action.save')} />
            <RAButton onClick={onCancel} label={translate('ra.action.cancel')} />
          </DialogActions>
        </SimpleForm>
      </Dialog>
    </>
  );
});
