import * as React from 'react';
import {
  SelectInput, required,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/integration/webhooks';

export default React.memo((props: any) => {
  const moduleId = useWatch({ name: 'moduleId' });
  const {
    apiKeys,
  } = useSelector((state: any) => state.webhooks);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData(moduleId));
  }, []);

  return (
    <SelectInput
      choices={apiKeys ?? []}
      emptyValue=""
      fullWidth
      optionText={(choice) => choice.name}
      variant="outlined"
      helperText={false}
      validate={
        required()
      }
      {...props}
    />
  );
});
