const messages = {
  resources: {
    'subject/subject-classes': {
      name: 'Lớp học phần |||| Lớp học phần',
      fields: {
        id: 'Id',
        name: 'Tên lớp',
        subjectId: 'Học phần',
        subjectName: 'Học phần',
        subjectClassCode: 'Mã lớp',
        code: 'Mã lớp',
        type: 'Loại lớp',
        typeName: 'Loại lớp',
        teacherId: 'Giảng viên',
        teacherName: 'Giảng viên',
        scholasticId: 'Năm học',
        scholasticName: 'Năm học',
        semesterId: 'Học kỳ',
        semesterName: 'Học kỳ',
        subTeacherIds: 'Trợ giảng',
        subTeachers: 'Trợ giảng',
        import: 'Import sinh viên',
        studentId: 'Mã sinh viên',
        fullName: 'Họ tên',
        genderName: 'Giới tính',
        facultyName: 'Khoa',
        courseName: 'Khoá',
        activityClassName: 'Lớp',
        birthday: 'Ngày sinh',
        students: 'Danh sách sinh viên trong lớp',
      },
    },
  },
  dut: {
    importStudentSuccess: 'Thêm thành công %{smart_count} sinh viên vào lớp học phần',
    removeStudentSuccess: 'Xoá thành công %{smart_count} sinh viên khỏi lớp học phần',
    downloadFileTemplate: 'Xem file import mẫu',
    removeStudentTitle: 'Xoá sinh viên khỏi lớp học phần',
    removeStudentContentDialog: 'Bạn có muốn xoá %{count} sinh viên khỏi lớp học phần',
    addStudentTitle: 'Thêm sinh viên vào lớp học phần',
    addStudentContentDialog: 'Bạn có muốn thêm %{count} sinh viên vào lớp học phần',
    addStudentToSubjectClass: 'Thêm sinh viên',
  },
};

export default messages;
