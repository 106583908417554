export default {
  confirmPasswordValidation: (value: string, allValues: any) => {
    if (allValues.password !== value) {
      return 'dut.msg.confirm_password_invalidate';
    }
    return undefined;
  },
  removeReadOnly: (event:any) => {
    const input = event.target;
    input.readOnly = false;
  },
  dateParse: (value: any) => {
    if (value == null || value === '') return null;
    return value;
  },
};
