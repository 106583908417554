import React from 'react';
import { SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectFaculty({ source }: any) {
  const { types } = useSelector((state: any) => state.faculties);
  return (
    <SelectInput
      source={source}
      choices={types ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectFaculty
    source="type"
  />,
];
