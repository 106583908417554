import React from 'react';
import {
  SelectInput, useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectFaculties({ source }: any) {
  const { faculties } = useSelector((state: any) => state.teachers);
  return (
    <SelectInput
      source={source}
      choices={faculties ?? []}
      variant="outlined"
    />
  );
}

function SelectTeacherType({ source }: any) {
  const { types } = useSelector((state: any) => state.teachers);
  return (
    <SelectInput
      source={source}
      choices={types ?? []}
      variant="outlined"
    />
  );
}

function SelectTeacherGender({ source }: any) {
  const { genders } = useSelector((state: any) => state.teachers);
  const translate = useTranslate();
  return (
    <SelectInput
      source={source}
      choices={genders ?? []}
      optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectFaculties
    source="facultyId"
  />,
  <SelectTeacherType
    source="type"
  />,
  <SelectTeacherGender
    source="gender"
  />,
];
