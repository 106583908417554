const messages = {
  resources: {
    'system/logs': {
      name: 'Log |||| Logs',
      fields: {
        id: 'Mã',
        createdAt: 'Thời gian',
        project: 'Project',
        class: 'Class',
        method: 'Method',
        message: 'Message',
        source: 'Source',
        createdIp: 'Địa chỉ Ip',
        innerException: 'Exception',
        requestId: 'Request Id',
        data: 'Data',
        stackTrace: 'Stack trace',
        username: 'Tên đăng nhập',
      },
    },
  },
};

export default messages;
