import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  courses: [],
  faculties: [],
};

export const activityClasses = createSlice({
  name: 'activityClasses',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.courses = action.payload.courses;
      state.faculties = action.payload.faculties;
    },
    getInitDataFailure: (state) => {
      state.courses = [];
      state.faculties = [];
    },
  },
});

const actionsTmp: any = { ...activityClasses.actions };
actionsTmp.getInitData = createAction('activityClasses/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default activityClasses.reducer;
