import vietnameseMessages from './defaults/ra-language-vietnamese';
import commons from './commons/vi';
import msg from './systemMessages/vi';
import pageUsers from './pages/user/accounts/vi';
import pageStudents from './pages/user/students/vi';
import pageModules from './pages/integration/modules/vi';
import pageApiKeys from './pages/integration/apiKeys/vi';
import pageScreens from './pages/integration/screens/vi';
import pageFunctions from './pages/integration/functions/vi';
import pageMenus from './pages/integration/menuItems/vi';
import pageApiGroups from './pages/integration/apiGroups/vi';
import pageApis from './pages/integration/apis/vi';
import pageRoles from './pages/system/roles/vi';
import pageScreenGroups from './pages/integration/screenGroups/vi';
import pageWebhooks from './pages/integration/webhooks/vi';
import teacherGroups from './pages/user/teachers/vi';
import system from './pages/system/system/vi';
import pageCourses from './pages/master/courses/vi';
import pageScholastics from './pages/master/scholastics/vi';
import pageSemesters from './pages/master/semesters/vi';
import pageActivityClasses from './pages/master/activityClasses/vi';
import pageFaculties from './pages/master/faculties/vi';
import pageSubjects from './pages/subject/subjects/vi';
import pageSubjectClasses from './pages/subject/subjectClasses/vi';
import pageSettings from './pages/system/settings/vi';
import pageLogs from './pages/system/logs/vi';
import pageMailLogs from './pages/system/mailLogs/vi';
import pageMajors from './pages/master/majors/vi';

const msgTitle: any = {};
const messages = {
  ...vietnameseMessages,
  resources: {
    ...pageUsers.resources,
    ...pageModules.resources,
    ...pageApiKeys.resources,
    ...pageScreens.resources,
    ...pageFunctions.resources,
    ...pageMenus.resources,
    ...pageApiGroups.resources,
    ...pageApis.resources,
    ...pageScreenGroups.resources,
    ...pageRoles.resources,
    ...pageStudents.resources,
    ...teacherGroups.resources,
    ...system.resources,
    ...pageWebhooks.resources,
    ...pageCourses.resources,
    ...pageScholastics.resources,
    ...pageSemesters.resources,
    ...pageActivityClasses.resources,
    ...pageFaculties.resources,
    ...pageSubjects.resources,
    ...pageSubjectClasses.resources,
    ...pageSettings.resources,
    ...pageLogs.resources,
    ...pageMailLogs.resources,
    ...pageMajors.resources,
  },
  dut: {
    ...commons,
    ...pageUsers.dut,
    ...pageRoles.dut,
    ...pageSubjectClasses.dut,
    ...teacherGroups.dut,
    msg: {
      ...msg,
    },
    msgTitle: {
      ...msgTitle,
    },
    ...pageApiKeys.dut,
  },
  ...commons.menu,
  ...commons.api,
  ...commons.functions,
  ...commons.screens,
};

export default messages;
