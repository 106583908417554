import * as React from 'react';
import { TopToolbar, ListButton, EditButton } from 'react-admin';

export default React.memo((props: any) => {
  const { isEdit, isList } = props;
  return (
    <TopToolbar>
      {isEdit !== false && <EditButton />}
      {isList !== false && <ListButton />}
    </TopToolbar>
  );
});
