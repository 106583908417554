const messages = {
  resources: {
    'master/activity-classes': {
      name: 'Lớp sinh hoạt |||| Lớp sinh hoạt',
      fields: {
        id: 'Id',
        name: 'Tên lớp sinh hoạt',
        description: 'Mô tả',
        courseId: 'Khoá',
        courseName: 'Khoá',
        facultyId: 'Khoa',
        facultyName: 'Khoa',
        students: 'Danh sách sinh viên',
        studentQuantity: 'Số lượng sinh viên',
        studentFields: {
          studentId: 'MSSV',
          fullName: 'Họ và tên',
          phone: 'SĐT',
        },
      },
    },
  },
};

export default messages;
