import React from 'react';
import {
  Datagrid,
  List, ListActions,
  TextField, useRecordContext, useTranslate,
} from 'react-admin';
import PostPagination from '../../commons/postPagination';
import filters from '../subjectClasses/filters';

export default React.memo((props: any) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  return (
    <List
      resource="subject/subject-classes"
      title={translate('resources.subject/subject-classes.name')}
      actions={<ListActions />}
      filters={filters}
      pagination={<PostPagination />}
      filter={{ subjectClassId: record.id }}
      perPage={100}
    >
      <Datagrid bulkActionButtons={false} optimized>
        <TextField source="name" />
        <TextField source="subjectClassCode" />
        <TextField source="typeName" />
        <TextField source="teacherName" />
        <TextField source="scholasticName" />
        <TextField source="semesterName" />
      </Datagrid>
    </List>
  );
});
