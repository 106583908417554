const messages = {
  resources: {
    'integration/menu/items': {
      name: 'Danh mục |||| Danh mục',
      fields: {
        id: 'Mã',
        showId: 'Mã',
        moduleId: 'Mô đun',
        name: 'Tên',
        showParentId: 'Danh mục cha',
        showFunctionId: 'Chức năng mặc định',
        icon: 'Biểu tượng',
        router: 'Định tuyến',
        sort: 'Sắp xếp',
        level: 'Cấp',
        isActive: 'Đang hoạt động',
      },
    },
  },
};

export default messages;
