/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TopToolbar,
  ListButton,
  SelectInput,
  TextInput,
  required,
  maxLength,
} from 'react-admin';
import { Grid } from '@mui/material';
import { actions } from '../../../redux/integration/menuItems';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import { validateId } from '../../../utils/constant';

export default React.memo(() => {
  const { modules, menus, functions } = useSelector((state: any) => state.menuItems);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPost = canAccess(permissions, FUNC.ADD_MENU_ITEM);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MENU_ITEMS);
  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canPost} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="id"
              validate={[
                required(),
                maxLength(10),
                validateId,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              defaultValue={modules[0].id ?? ''}
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              onChange={(choice) => getData(choice.target.value)}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="parentId"
              choices={menus ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="functionId"
              choices={functions ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="icon"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="router"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="sort"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
