/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  maxLength,
  Edit,
  useRecordContext,
  TabbedForm,
  FormTab,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import ApiPermissionInput from '../../../components/integration/modules/apiPermissionInput';

function ModuleForm(props: any) {
  const record = useRecordContext(props);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_MODULE);
  const canDelete = canAccess(permissions, FUNC.DELETE_MODULE);
  return (
    <>
      {!record?.canGrandPermissions && (
        <SimpleForm toolbar={<EditToolBar isSave={canEdit} isDelete={canDelete} />}>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={1} md={2}>
              <TextInput
                variant="outlined"
                source="id"
                disabled
                validate={[
                  required(),
                  maxLength(10),
                ]}
              />
            </Grid>
            <Grid item xs={8} md={10}>
              <TextInput
                fullWidth
                variant="outlined"
                source="name"
                validate={[
                  required(),
                  maxLength(50)]}
              />
            </Grid>
            <Grid item xs={3} md={5}>
              <BooleanInput
                source="isActive"
              />
            </Grid>
            <Grid item xs={8} md={10}>
              <TextInput
                fullWidth
                variant="outlined"
                source="domain"
                validate={[
                  maxLength(255)]}
              />
            </Grid>
            {/* <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="logo"
              validate={[
                maxLength(255)]}
            />
          </Grid> */}
            <Grid item xs={8} md={10}>
              <TextInput
                fullWidth
                multiline
                variant="outlined"
                source="description"
                validate={[
                  maxLength(500)]}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      )}
      {record?.canGrandPermissions && (
        <TabbedForm toolbar={<EditToolBar isSave={canEdit} isDelete={canDelete} />}>
          <FormTab label="resources.integration/modules.name">
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item xs={1} md={2}>
                <TextInput
                  variant="outlined"
                  source="id"
                  disabled
                  validate={[
                    required(),
                    maxLength(10),
                  ]}
                />
              </Grid>
              <Grid item xs={8} md={10}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="name"
                  validate={[
                    required(),
                    maxLength(50)]}
                />
              </Grid>
              <Grid item xs={3} md={5}>
                <BooleanInput
                  source="isActive"
                />
              </Grid>
              <Grid item xs={8} md={10}>
                <TextInput
                  fullWidth
                  variant="outlined"
                  source="domain"
                  validate={[
                    maxLength(255)]}
                />
              </Grid>
              {/* <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="logo"
              validate={[
                maxLength(255)]}
            />
          </Grid> */}
              <Grid item xs={8} md={10}>
                <TextInput
                  fullWidth
                  multiline
                  variant="outlined"
                  source="description"
                  validate={[
                    maxLength(500)]}
                />
              </Grid>
            </Grid>
          </FormTab>
          <FormTab label="dut.permissions">
            <ApiPermissionInput source="permissions" />
          </FormTab>
        </TabbedForm>
      )}
    </>
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SHOW_MODULE_DETAIL);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MODULES);
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <ModuleForm />
    </Edit>
  );
});
