import repositories from '..';

interface DeleteAfunctionInscreenProps {
  id:string;
  functionId:string;
}
const resource = '/integration/screens';

export default {
  getInitData: (data: any) => repositories.get(`${resource}/data/init?moduleId=${data}`),
  deleteAfunctionInscreen:
  (data: DeleteAfunctionInscreenProps) => repositories
    .delete(`${resource}/${data.id ?? 0}/items/${data.functionId ?? ''}`),
};
