/* eslint-disable max-len */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  SelectInput,
  regex,
} from 'react-admin';
import { Grid } from '@mui/material';
import { actions } from '../../../redux/integration/webhooks';
import CreateToolBar from '../../../components/commons/createToolBar';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { modules, apiKeys, triggers } = useSelector((state: any) => state.webhooks);
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canAdd = canAccess(permissions, FUNC.ADD_WEBHOOK);
  const canSearch = canAccess(permissions, FUNC.SEARCH_WEBHOOK);

  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              onChange={(choice) => getData(choice.target.value)}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="apiKeyId"
              choices={apiKeys ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              fullWidth
              variant="outlined"
              choices={triggers ?? []}
              emptyValue=""
              optionText={(choice) => choice.name}
              source="triggerName"
              validate={[
                required(),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              fullWidth
              variant="outlined"
              source="url"
              validate={[
                maxLength(200),
                regex(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, 'dut.msg.url_format_invalidate'),
              ]}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
