import * as React from 'react';
import {
  number,
  TextInput,
  maxValue,
} from 'react-admin';
import { InputAdornment } from '@mui/material';

export default React.memo((props:any) => {
  const {
    symbol,
    source,
  } = props;
  return (
    <TextInput
      source={source}
      validate={[number(), maxValue(300)]}
      fullWidth
      variant="outlined"
      helperText={false}
      InputProps={{
        endAdornment: <InputAdornment position="end">{symbol ?? ''}</InputAdornment>,
      }}
    />
  );
});
