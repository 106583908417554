const messages = {
  resources: {
    'master/majors': {
      name: 'Major |||| Majors',
      fields: {
        id: 'Id',
        code: 'Code',
        Name: 'Major name',
        description: 'Description',
      },
    },
  },
};

export default messages;
