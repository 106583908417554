import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  TopToolbar,
  ListButton,
  useTranslate,
  TabbedForm,
  FormTab,
} from 'react-admin';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import UserInfoContainer from '../userInfoContainer';
import UserYusInfoContainer from '../userYusInfoContainer';
import UserCpvInfoContainer from '../userCpvInfoContainer';
import TeacherInfoContainer from './teacherInfoContainer';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canAdd = canAccess(permissions, FUNC.ADD_TEACHER);
  const canSearch = canAccess(permissions, FUNC.SEARCH_TEACHERS);
  const canUpdateTeacherRole = canAccess(permissions, FUNC.EDIT_ROLE_TEACHER);
  const translate = useTranslate();
  const transform = (data:any) => {
    if (!data.information.isYusMember) {
      data.yusInformation = null;
    }
    return data;
  };

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      transform={transform}
      className="mai-form"
      redirect="list"
    >
      <TabbedForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <FormTab
          sx={{ flexDirection: 'row' }}
          label={translate('resources.user/teachers.fields.teacherInformationLabel')}
        >
          <TeacherInfoContainer canUpdateTeacherRole={canUpdateTeacherRole} />
        </FormTab>
        <FormTab
          label={translate('resources.user/teachers.fields.userInformationLabel')}
        >
          <UserInfoContainer roleUser="teachers" />
        </FormTab>
        <FormTab label={translate('resources.user/teachers.fields.yusInformationLabel')}>
          <UserYusInfoContainer isTeachers />
        </FormTab>
        <FormTab label={translate('resources.user/teachers.fields.cpvInformationLabel')}>
          <UserCpvInfoContainer isTeachers />
        </FormTab>
      </TabbedForm>
    </Create>
  );
});
