import * as React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';

export default React.memo((props: any) => {
  const { isSave, isDelete } = props;
  return (
    <Toolbar>
      <div className="RaToolbar-defaultToolbar">
        {isSave !== false && <SaveButton />}
        {isDelete !== false && <DeleteButton />}
      </div>
    </Toolbar>
  );
});
