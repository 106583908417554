import appReducer from './app';
import profileReducer from './profile';
import usersReducer from './user/accounts';
import oauthReducer from './oauth';
import apiKeysReducer from './integration/apiKeys';
import screensReducer from './integration/screens';
import functionsReducer from './integration/functions';
import menuItemsReducer from './integration/menuItems';
import apisReducer from './integration/apis';
import apiGroupsReducer from './integration/apiGroups';
import screenGroupsReducer from './integration/screenGroups';
import rolesReducer from './system/roles';
import studentsReducer from './user/students';
import teachersReducer from './user/teachers';
import districtsReducer from './master/districts';
import communesReducer from './master/communes';
import webhooksReducer from './integration/webhooks';
import semestersReducer from './master/semesters';
import activityClassesReducer from './master/activityClasses';
import facultiesReducer from './master/faculties';
import subjectsReducer from './subject/subjects';
import subjectClassesReducer from './subject/subjectClasses';
import settingsReducer from './system/settings';
import logsReducer from './system/logs';

export default {
  app: appReducer,
  profile: profileReducer,
  users: usersReducer,
  oauth: oauthReducer,
  apiKeys: apiKeysReducer,
  screens: screensReducer,
  functions: functionsReducer,
  menuItems: menuItemsReducer,
  apis: apisReducer,
  apiGroups: apiGroupsReducer,
  screenGroups: screenGroupsReducer,
  roles: rolesReducer,
  students: studentsReducer,
  teachers: teachersReducer,
  districts: districtsReducer,
  communes: communesReducer,
  webhooks: webhooksReducer,
  semesters: semestersReducer,
  activityClasses: activityClassesReducer,
  faculties: facultiesReducer,
  subjects: subjectsReducer,
  subjectClasses: subjectClassesReducer,
  settings: settingsReducer,
  logs: logsReducer,
};
