const messages = {
  resources: {
    'integration/webhooks': {
      name: 'Webhooks |||| Webhook',
      fields: {
        id: 'Id',
        moduleId: 'Module',
        triggerName: 'Trigger Name',
        url: 'Url',
        moduleName: 'Module',
        apiKeyName: 'ApiKey',
        statusName: 'Status',
        histories: {
          name: 'Webhook Histories',
          body: 'Data',
          detail: 'Detail',
          response: 'API Response',
          resend: 'Resend',
          status: 'Status',
          sendAt: 'Send At',
          url: 'Url',
        },
      },
    },
  },
};

export default messages;
