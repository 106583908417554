/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Button, useNotify, useRecordContext, useRefresh,
} from 'react-admin';
import {
} from '@mui/material';
import { useDispatch } from 'react-redux';
import ReplayIcon from '@mui/icons-material/Replay';
import { EMAIL_STATUS } from '../../../utils/constant';
import { actions } from '../../../redux/system/logs';

export default React.memo((props: any) => {
  const record = useRecordContext(props);
  const resend = record.status === EMAIL_STATUS.ERROR;
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
    refresh();
  };
  const resendEmail = () => {
    const { id } = record;
    dispatch(actions.resendEmail({
      id,
      onSuccess,
    }));
  };
  return (
    <>
      {resend && (
        <Button onClick={resendEmail} resource="id">
          <ReplayIcon sx={{ color: '#1976d2', marginRight: 1 }} />
        </Button>
      )}
    </>
  );
});
