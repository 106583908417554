import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/integration/modules/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import ExportModuleButton from '../../../components/integration/modules/exportModuleButton';
import ImportButton from '../../../components/commons/importButton';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_MODULE);
  const canShow = canAccess(permissions, FUNC.SHOW_MODULE_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_MODULE);
  const canDelete = canAccess(permissions, FUNC.DELETE_MODULE);
  const canExport = canAccess(permissions, FUNC.EXPORT_MODULE_DATA);
  const canImport = canAccess(permissions, FUNC.IMPORT_MODULE_DATA);
  return (
    <List
      filters={filters}
      actions={<ListActions resource="system/system" isExport isCreate={canAdd} isImport={canImport} />}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="isActive" />
        <TextField source="domain" />
        {/* <TextField source="logo" /> */}
        {canExport && <ExportModuleButton />}
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
        <ImportButton resource="system/system" />
      </Datagrid>
    </List>
  );
});
