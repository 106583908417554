const messages = {
  resources: {
    'integration/api-groups': {
      name: 'Nhóm API |||| Nhóm API',
      fields: {
        showId: 'Mã',
        id: 'Mã',
        module: 'Mô đun',
        name: 'Tên nhóm API',
        description: 'Mô tả',
        moduleId: 'Mô đun',
        isActive: 'Đang hoạt động',
      },
    },
  },
};

export default messages;
