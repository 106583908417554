import apiGroupsList from './apiGroupsList';
import createApiGroup from './createApiGroup';
import editApiGroup from './editApiGroup';
import showApiGroup from './showApiGroup';

export default {
  list: apiGroupsList,
  create: createApiGroup,
  edit: editApiGroup,
  show: showApiGroup,
};
