import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  mailStatus: [],
  mailPriorities: [],
  mailLogs: [],
};

export const logs = createSlice({
  name: 'system/logs',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.projects = action.payload.projects;
      state.mailStatus = action.payload.mailStatus;
      state.mailPriorities = action.payload.mailPriorities;
    },
    getInitDataFailure: (state) => {
      state.projects = [];
      state.mailStatus = [];
      state.mailPriorities = [];
    },
  },
});

const actionsTmp: any = { ...logs.actions };
actionsTmp.getInitData = createAction('system/logs/getInitData');
actionsTmp.getMailLog = createAction('system/logs/getMailLog');
actionsTmp.resendEmail = createAction('system/logs/resendEmail');

export const actions = { ...actionsTmp };

export const logsSelector = (state: any) => state;

export default logs.reducer;
