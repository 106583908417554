import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../../repositories/system/settings';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getSetting() {
  yield takeLatest(actions.getSetting, function* (data: any) {
    const { payload } = data;
    try {
      yield put(actions.getSettingFailure());
      const response: ResponseInterface = yield call(repositories.getSetting, payload.value);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getSettingSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getSettingFailure(error));
    }
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback();
    }
  });
}

export function* putInt() {
  yield takeLatest(actions.putInt, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.putInt, data.payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* putMailSystem() {
  yield takeLatest(actions.putMailSystem, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.putMailSystem, data.payload.value);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* putString() {
  yield takeLatest(actions.putString, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.putString, data.payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* putOther() {
  yield takeLatest(actions.putOther, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.putOther, data.payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export function* putBlobStorage() {
  yield takeLatest(actions.putBlobStorage, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.putBlobStorage, data.payload.value);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getSetting),
    fork(putInt),
    fork(putMailSystem),
    fork(putString),
    fork(putBlobStorage),
    fork(putOther),
  ]);
}
