import screenGroupList from './screenGroupList';
import createScreenGroup from './createScreenGroup';
import editScreenGroup from './editScreenGroups';
import showScreenGroups from './showScreenGroups';

export default {
  list: screenGroupList,
  create: createScreenGroup,
  edit: editScreenGroup,
  show: showScreenGroups,
};
