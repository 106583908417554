import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/integration/menuItems/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import NameField from '../../../components/integration/menuItems/nameField';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_MENU_ITEM);
  const canDelete = canAccess(permissions, FUNC.DELETE_MENU_ITEM);
  const canPost = canAccess(permissions, FUNC.ADD_MENU_ITEM);
  const canShow = canAccess(permissions, FUNC.SHOW_MENU_ITEM_DETAIL);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canPost} />}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="showId" />
        <TextField source="moduleId" />
        <NameField source="name" />
        <TextField source="showFunctionId" />
        <TextField source="router" />
        <TextField source="sort" />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
