const messages = {
  resources: {
    'system/roles': {
      name: 'Role |||| Roles',
      fields: {
        id: 'Id',
        showId: 'Id',
        name: 'Name',
        moduleName: 'Module',
        moduleId: 'Module',
        description: 'Description',
        isAdmin: 'Is Administrator',
        isDefaultForStudent: 'Is Default For Student',
        isDefaultForTeacher: 'Is Default For Teacher',
      },
    },
  },
  dut: {
    permissions: 'Permissions of module',
    iamPermissions: 'Permissions',
  },
};

export default messages;
