import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Admin,
  Resource,
  CustomRoutes,
  Authenticated,
  resolveBrowserLocale,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import history from './history';
import './assets/scss/style.scss';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import messages from './i18n';
import { actions as appActions } from './redux/app';
import { lightTheme } from './shared/themes';

import layout from './shared/layout';
import Loading from './shared/loading';
import dashboard from './shared/dashboard';
import loginPage from './shared/loginPage';
import NoAccess from './shared/noAccess';
import ChangePassword from './pages/changePassword';
import Profile from './pages/profile';
import Oauth from './pages/oauth';
import Logout from './pages/oauth/logout';
import ForgotPassword from './shared/forgotPassword';
import ResetPassword from './shared/resetPassword';

import accounts from './pages/user/accounts';
import modules from './pages/integration/modules';
import apiKeys from './pages/integration/apiKeys';
import screenGroups from './pages/integration/screenGroups';
import screens from './pages/integration/screens';
import functions from './pages/integration/functions';
import menuItems from './pages/integration/menuItems';
import apiGroups from './pages/integration/apiGroups';
import apis from './pages/integration/apis';
import roles from './pages/system/roles';
import settings from './pages/system/settings';
import students from './pages/user/students';
import teachers from './pages/user/teachers';
import webhooks from './pages/integration/webhooks';
import courses from './pages/master/courses';
import scholastics from './pages/master/scholastics';
import semesters from './pages/master/semesters';
import activityClasses from './pages/master/activityClasses';
import faculties from './pages/master/faculties';
import subjects from './pages/subject/subjects';
import subjectClasses from './pages/subject/subjectClasses';
import ProgressBar from './components/commons/progressBar';
import logs from './pages/system/logs';
import mailLogs from './pages/system/mailLogs';

function App() {
  const dispatch = useDispatch();
  const {
    loading,
    forceLoading,
    isProgressLoading,
    progressLoading,
    version,
  } = useSelector((state: any) => state.app);
  const defaultLocale = resolveBrowserLocale('vi');
  const getI18nProvider = () => polyglotI18nProvider(
    (locale: string) => messages[locale],
    defaultLocale,
    { allowMissing: true },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [i18nProvider, setI18nProvider] = React.useState(getI18nProvider);
  React.useEffect(() => {
    localStorage.setItem('getProfile', '0');
    dispatch(appActions.getAppSetting({}));
    dispatch(appActions.getAppState({}));
  }, []);
  // React.useEffect(() => {
  //   setI18nProvider(getI18nProvider);
  // }, [version]);
  return (
    <BrowserRouter>
      {(forceLoading || loading) && !isProgressLoading && <Loading />}
      {(forceLoading || loading) && isProgressLoading && <ProgressBar value={progressLoading} />}
      <Admin
        key={version}
        history={history}
        layout={layout}
        dashboard={dashboard}
        loginPage={loginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={lightTheme}
      >
        <Resource name="user/accounts" {...accounts} />
        <Resource name="integration/modules" {...modules} />
        <Resource name="integration/api-keys" {...apiKeys} />
        <Resource name="integration/screen-groups" {...screenGroups} />
        <Resource name="integration/screens" {...screens} />
        <Resource name="integration/functions" {...functions} />
        <Resource name="integration/menu/items" {...menuItems} />
        <Resource name="integration/api-groups" {...apiGroups} />
        <Resource name="integration/apis" {...apis} />
        <Resource name="integration/webhooks" {...webhooks} />
        <Resource name="system/roles" {...roles} />
        <Resource name="system/settings" {...settings} />
        <Resource name="system/logs" {...logs} />
        <Resource name="system/logs/mail" {...mailLogs} />
        <Resource name="user/students" {...students} />
        <Resource name="user/teachers" {...teachers} />
        <Resource name="master/courses" {...courses} />
        <Resource name="master/scholastics" {...scholastics} />
        <Resource name="master/semesters" {...semesters} />
        <Resource name="master/activity-classes" {...activityClasses} />
        <Resource name="master/faculties" {...faculties} />
        <Resource name="subject/subjects" {...subjects} />
        <Resource name="subject/subject-classes" {...subjectClasses} />
        <CustomRoutes>
          <Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
          <Route path="/change-password" element={<Authenticated><ChangePassword /></Authenticated>} />
          <Route path="/no-access" element={<NoAccess />} />
          <Route path="/oauth" element={<Oauth />} />
          <Route path="/oauth/logout" element={<Logout />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/reset-password" element={<ResetPassword />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
}

export default App;
