import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  types: [],
  groups: [],
  modules: [],
  functions: [],
};

export const screens = createSlice({
  name: 'screens',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.types = action.payload.types;
      state.groups = action.payload.groups;
      state.modules = action.payload.modules;
      state.functions = action.payload.functions;
    },
    getInitDataFailure: (state) => {
      state.types = [];
      state.groups = [];
      state.modules = [];
      state.functions = [];
    },
  },
});

const actionsTmp: any = { ...screens.actions };
actionsTmp.getInitData = createAction('screens/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default screens.reducer;
