import React from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { actions } from '../../redux/profile';
import ChangePasswordContainer from '../../containers/changePassword/changePasswordContainer';

export default function () {
  const dispatch = useDispatch();
  const notify = useNotify();
  const savePassword = (data: any, resetValue: Function | undefined) => {
    dispatch(actions.changePassword({
      data,
      onSuccess: () => {
        notify('dut.msg.change_password_success', {
          type: 'info',
        });
        if (resetValue) {
          resetValue();
        }
      },
    }));
  };
  return (
    <ChangePasswordContainer onSave={savePassword} />
  );
}
