import createSubjectClass from './createSubjectClass';
import editSubjectClass from './editSubjectClass';
import showSubjectClass from './showSubjectClass';
import subjectClassList from './subjectClassList';

export default {
  list: subjectClassList,
  create: createSubjectClass,
  edit: editSubjectClass,
  show: showSubjectClass,
};
