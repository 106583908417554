/* eslint-disable max-len */
const msg: any = {
  confirm_save_data: 'Bạn có thực sự muốn lưu dữ liệu này không?',
  confirm_delete_data: 'Bạn có thực sự muốn xóa dữ liệu này không?',
  confirm_reset_password: 'Bạn có thực sự muốn đặt lại mật khẩu cho tài khoản này không?',
  confirm_change_password: 'Bạn có thực sự muốn thay đổi mật khẩu của mình không?',
  confirm_unlock_account: 'Bạn có thực sự muốn mở khóa tài khoản này không?',
  confirm_verify_account: 'Bạn có thực sự muốn xác minh tài khoản này không?',
  confirm_lock_account: 'Bạn có thực sự muốn khóa tài khoản này không?',
  confirm_add_account_to_black_list: 'Bạn có thật sự muốn đưa tài khoản này vào blacklist hay không?',
  confirm_remove_account_from_black_list: 'Bạn có thật sự muốn xoá tài khoản này khỏi blacklist hay không?',

  save_data_success: 'Đã lưu dữ liệu thành công.',
  delete_data_success: 'Đã xóa dữ liệu thành công.',
  create_js_success: 'Đã tạo file javascript thành công',
  build_file_success: 'Đã tạo lại file chức năng hệ thống thành công',
  reset_password_success: 'Mật khẩu đã được đặt lại thành công.',
  clone_data_success: 'Đã sao chép thành công dữ liệu.',
  send_email_for_reset_password_success: 'Vui lòng kiểm tra email của bạn để đặt lại mật khẩu của bạn. Nếu nó không xuất hiện trong vài phút, hãy kiểm tra thư mục rác của bạn.',
  send_email_for_active_account_success: 'Thư kích hoạt đã được gửi đến địa chỉ email đăng ký của bạn, vui lòng kiểm tra email để kích hoạt tài khoản của bạn.',
  change_password_success: 'Đã thay đổi mật khẩu thành công.',
  save_profile_success: 'Đã lưu thông tin cá nhân thành công.',

  warning_create_js: 'Việc tạo lại file javascript này có thể gây ra một số thay đổi không mong muốn đối với hệ thống, bạn có muốn tiếp tục không?',
  warning_build_file: 'Việc tạo lại file chức năng của hệ thống có thể gây ra một số thay đổi không mong muốn và sẽ phải build lại project, bạn có muốn tiếp tục không?',

  required: 'Nội dung này không được để trống.',
  email_invalidate: 'Email không đúng định dạng.',
  date_invalidate: 'Ngày không đúng định dạng.',
  url_format_invalidate: 'Liên kết không đúng định dạng.',
  maxlength_invalidate: 'Bạn không được phép nhập nhiều hơn {0} ký tự.',
  minlength_invalidate: 'Bạn phải nhập ít nhất {0} ký tự.',
  value_must_geater: 'Giá trị này phải lớn hơn hoặc bằng {0}.',
  value_must_lesser: 'Giá trị này phải nhỏ hơn hoặc bằng {0}.',
  phone_number_format_invalidate: 'Số điện thoại không hợp lệ.',
  time_from_must_be_less_than_time_to: 'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc.',
  data_duplicate: 'Dữ liệu trùng lặp.',
  username_not_correct: 'Tài khoản không tồn tại.',
  password_not_correct: 'Mật khẩu không đúng.',
  username_or_password_not_correct: 'Tài khoản hoặc mật khẩu không chính xác.',
  username_had_used: 'Tên người dùng này đã được đăng ký. Vui lòng sử dụng tên người dùng khác!',
  system_must_have_least_1_user: 'Hệ thống phải tồn tại ít nhất 1 tài khoản để sử dụng.',
  password_format_invalidate: 'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và các ký tự đặc biệt.',
  confirm_password_invalidate: 'Xác nhận mật khẩu không khớp, vui lòng kiểm tra lại.',
  username_format_invalidate: 'Tên người dùng phải có ít nhất 4 ký tự, chỉ được nhập chữ cái, số và dấu chấm (.), Dấu gạch ngang (-), dấu gạch dưới (_).',
  file_size_too_larger: 'Kích thước tệp vượt quá kích thước cho phép.',
  extension_not_allow: 'Tệp không đúng định dạng.',
  cannot_upload_many_files: 'Không thể tải lên nhiều hơn {0} tệp cùng một lúc.',
  upload_file_error: 'Đã xảy ra lỗi khi tải tệp xuống, vui lòng thử lại.',
  you_must_choose_file: 'Vui lòng chọn tệp để tải lên.',
  size_of_img_invalidate: 'Kích thước hình ảnh không đúng.',
  error_min_item: 'Thao tác này không thể thực hiện được vì số lượng mục hiển thị trên trang chủ đã đạt mức tối thiểu.',
  slug_format_invalidate: 'Liên kết này chỉ có thể chứa các chuỗi không dấu bao gồm chữ thường, chữ hoa và dấu gạch ngang (-).',
  save_data_error: 'Lưu dữ liệu không thành công, vui lòng thử lại.',
  delete_data_error: 'Xóa dữ liệu không thành công, vui lòng thử lại.',
  number_of_record_too_larger: 'Không thể hiển thị quá nhiều kết quả.',
  create_js_error: 'Đã xảy ra lỗi khi tạo tệp, vui lòng thử lại.',
  data_existing: 'Dữ liệu này đã tồn tại trong hệ thống.',
  message_code_existing: 'Mã của tin nhắn đã tồn tại trong hệ thống.',
  function_code_existing: 'Mã của chức năng đã tồn tại trong hệ thống.',
  group_have_account: 'Có một tài khoản trong nhóm này nên không thể xóa được, trước tiên hãy xóa tất cả các tài khoản của nhóm này.',
  data_export_not_exists: 'Không có dữ liệu xuất.',
  email_not_correct: 'Email này không tồn tại.',
  token_timeout: 'Thông tin đăng nhập đã hết hạn, vui lòng thử lại.',
  email_had_used: 'Email này đã được đăng ký. Vui lòng sử dụng email khác!',
  account_was_locked: 'Tài khoản đã tạm thời bị khóa, vui lòng liên hệ với quản trị viên để được hỗ trợ.',
  account_in_blacklist: 'Tài khoản của bạn đã bị đưa vào blacklist, vui lòng liên hệ với quản trị viên để được hỗ trợ.',
  value_from_must_be_less_than_value_to: 'Giá trị bắt đầu phải nhỏ hơn giá trị kết thúc.',
  select_at_least_1_line: 'Bạn phải chọn ít nhất 1 dòng.',
  token_invalidate: 'Thông tin đăng nhập không chính xác, vui lòng thử lại.',
  must_be_number: 'Giá trị phải là một số.',
  can_not_delete_module: 'Không thể xoá module vì có thông tin đang được sử dụng.',
  module_unactive: 'Không thể thêm tài nguyên vì module không hoạt động.',
  can_not_delete_screen: 'Không thể xoá screen vì có function đang được sử dụng.',
  can_not_delete_function: 'Không thể xoá function vì có tài nguyên đang được sử dụng.',
  menu_level_too_big: 'Cấp của menu không hợp lệ.',
  email_or_phone_not_verified: 'Email hoặc số điện thoại đăng nhập chưa được xác thực, vui lòng xử dụng tên đăng nhập để đăng nhập.',
  can_not_delete_api_group: 'Không thể xoá api group vì có api đang thuộc api group này đang được sử dụng.',
  can_not_delete_screen_group: 'Không thể xoá screen group vì có screen đang thuộc screen group này đang được sử dụng.',
  code_invalid: 'Dữ liệu chứa kí tự không hợp lệ, chỉ được nhập chữ cái, số và dấu chấm (.), Dấu gạch ngang (-), dấu gạch dưới (_).',
  cannot_delete_role_have_users: 'Không thể xoá nhóm quyền vì có tài khoản đang sử dụng.',
  can_not_read_file_data: 'Không thể đọc dữ liệu từ file.',
  file_data_invalid: 'Dữ liệu đọc không đúng định dạng.',
  file_extension_invalid: 'Upload file không đúng định dạng (phải là .xlsx).',
  faculty_code_existing: 'Đã tồn tại khoa với code trên.',
  semester_date_start_invalidate: 'Thời gian bắt đầu học kì phải nằm trong khoảng thời gian năm học diễn ra.',
  semester_date_end_invalidate: 'Thời gian kết thúc học kì phải nằm trong khoảng thời gian năm học diễn ra.',
  invalid_faculty: 'Khoa không hợp lệ.',
  mail_system_username_format_invalidate: 'Tên mail system phải có ít nhất 4 ký tự, chỉ được nhập chữ cái, số và dấu chấm (.), Dấu gạch ngang (-), dấu gạch dưới (_), dấu a còng (@).',
  webhook_not_have_apikey: 'Webhook chưa đăng kí ApiKey.',
  email_sending: 'Email đang được gửi.',
  email_sended: 'Email đã được gửi.',
  webhook_stopped: 'Webhook ngừng hoạt động, vui lòng vào trang quản lý để thay đổi trạng thái.',

  input_validate_error: 'Dữ liệu nhập vào không đúng yêu cầu, vui lòng thử lại.',
  have_error: 'Có lỗi sảy ra, vui lòng thử lại sau.',
  you_must_login: 'Bạn cần đăng nhập để thực hiện hành động này.',
  you_not_have_permission: 'Bạn không có quyền thực hiện hành động này.',
  not_found: 'Dữ liệu được yêu cầu không được tìm thấy.',
  server_error: 'Hệ thống có lỗi, vui lòng thử lại sau.',
};
export default msg;
