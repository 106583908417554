import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from '@mui/material';
import {
  Form,
  required,
  TextInput,
  PasswordInput,
  useLogin,
  useTranslate,
  useNotify,
} from 'react-admin';
import Box from '@mui/material/Box';
import helpers from '../utils/helpers';
import background from '../assets/imgs/common/loginBg.jpg';
import logo from '../assets/imgs/common/logo.png';

function MyLoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [browser, setBrowser] = useState('');
  const [latOfMap, setLatOfMap] = useState('');
  const [longOfMap, setLongOfMap] = useState('');
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const getPosition = () => {
    fetch('https://ipinfo.io/json?token=fc7a1a15e4945b')
      .then((res: any) => res.json())
      .then((res: any) => {
        if (res.loc) {
          const loc = res.loc.split(',');
          if (loc.length > 1) {
            setLatOfMap(`${loc[0] ?? ''}`);
            setLongOfMap(`${loc[1] ?? ''}`);
          }
        }
      }).catch(console.log);
  };

  const handleSubmit = () => {
    setLoading(true);
    login({
      username, password, browser, latOfMap, longOfMap, client,
    }).catch((e) => {
      setLoading(false);
      if (e !== undefined) {
        notify('dut.msg.username_or_password_not_correct');
      }
    });
  };

  React.useEffect(() => {
    try {
      const p = searchParams.get('p');
      const data = JSON.parse(helpers.base64Decode(p));
      if (data.loginKey) {
        setClient(data);
      }
    } catch (e: any) {
      console.log(`getParam: ${e.message}`);
    }
    if (navigator) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          setLatOfMap(`${position.coords.latitude ?? ''}`);
          setLongOfMap(`${position.coords.longitude ?? ''}`);
        });
      } else {
        getPosition();
      }
      setBrowser(navigator.userAgent);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card
          sx={{
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: '#000',
            opacity: 0.4,
            zIndex: 1,
          }}
        />
        <Card
          sx={{
            minWidth: 300,
            position: 'relative',
            zIndex: 2,
            marginBottom: '10em',
          }}
        >
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }} alt="logo" src={logo} variant="square" />
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            DUT IAM
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="username"
                label={translate('dut.username')}
                disabled={loading}
                inputProps={{ maxLength: 50 }}
                validate={required()}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <PasswordInput
                source="password"
                label={translate('dut.password')}
                disabled={loading}
                inputProps={{ maxLength: 50 }}
                validate={required()}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Box>
            <Button
              type="button"
              sx={{
                fontSize: '1em',
                textTransform: 'none',
              }}
              onClick={() => { window.location.href = '/forgot-password'; }}
            >
              {translate('dut.forgot_password')}
            </Button>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress size={25} thickness={2} />
              )}
              {translate('dut.login')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
}

export default MyLoginPage;
