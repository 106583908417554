import { defaultTheme } from 'react-admin';

export const darkTheme = {
  ...defaultTheme,
  palette: {
    mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  ...defaultTheme,
};
