import * as React from 'react';
import {
  ArrayField, useRecordContext, useTranslate,
} from 'react-admin';
import CustomedDeleteButton from '../../commons/customedDeleteButton';

export default React.memo((props: any) => {
  const {
    deleteHandle, sourceFunctionId, screenId,
  } = props;
  const record = useRecordContext();
  const FunctionId = record[sourceFunctionId];
  const translate = useTranslate();
  const handleClick = () => {
    deleteHandle(screenId, FunctionId);
  };
  return (
    <ArrayField source="functions">
      <CustomedDeleteButton
        title={translate('dut.delete_title_screen')}
        content={translate('dut.delete_content_screen')}
        onClick={handleClick}
        label=""
        mutationMode="pessimistic"
      />
    </ArrayField>
  );
});
