import * as React from 'react';
import {
  DateField,
  ListContextProvider,
  ShowButton,
  TextField,
  TopToolbar,
  useList,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router';
import {
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  TablePagination,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dataProvider from '../../../dataProvider';
import ImportButton from './importStudentButton';
import { TEMPLATE_FILE_NAME } from '../../../utils/constant';
import DownloadTemplateButton from '../../commons/downloadTemplateButton';
import StudentDataGrid from './studentDataGrid';
import DeleteStudentButton from './deleteStudentButton';
import ShowStudentListButton from './showStudentListButton';
import ShowField from '../../commons/showField';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ISort {
  field: string,
  order: string,
}

const initSort : ISort = { field: 'id', order: 'DESC' };

export default React.memo((props: any) => {
  const { isImport } = props;
  const { id } = useParams();
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShowStudent = canAccess(permissions, FUNC.SHOW_STUDENT_DETAIL);

  const [data, setData] = React.useState<any>([]);
  const [total, setTotal] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState<ISort>(initSort);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const translate = useTranslate();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStudents = async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    const result = await dataProvider.getList(`subject/subject-classes/${id}/students`, {
      filter: {
      },
      pagination: {
        page: page + 1,
        perPage: rowsPerPage,
      },
      sort,
    });
    if (result.data && result?.data?.length > 0) {
      setData(result.data);
      setTotal(result.total ?? 0);
    } else {
      setData([]);
      setTotal(0);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    getStudents().catch(console.error);
  }, [sort, page, rowsPerPage]);

  const listContext = useList({
    data,
    isLoading,
    page,
    sort,
    perPage: rowsPerPage,
  });

  return (
    <>
      <Grid container padding={2} paddingBottom={0} sx={{ display: 'flex' }}>
        <CardHeader
          title={translate('resources.subject/subject-classes.fields.students')}
          titleTypographyProps={{
            sx: {
              fontSize: '1rem',
              fontWeight: 'bold',
            },
          }}
          sx={{ borderBottom: '1px solid', width: '100%', marginBottom: '10px' }}
          action={(
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        />
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0 }}>
          <ListContextProvider value={listContext}>
            <TopToolbar className="studentListToolBar">
              {isImport && (
                <>
                  <ShowStudentListButton
                    title={translate('dut.addStudentToSubjectClass')}
                    selectedIds={data && data.length > 0 ? data.map((item: any) => item.id) : []}
                    getStudents={getStudents}
                  />
                  <ImportButton
                    id={id}
                    label={translate('resources.subject/subject-classes.fields.import')}
                    title={translate('resources.subject/subject-classes.fields.import')}
                    getStudents={getStudents}
                  />
                  <DownloadTemplateButton
                    label={translate('dut.downloadFileTemplate')}
                    fileName={TEMPLATE_FILE_NAME.STUDENT_TO_SUBJECT_CLASS}
                  />
                </>
              )}
            </TopToolbar>
            <StudentDataGrid
              data={data}
              total={total}
              isLoading={isLoading}
              sort={sort}
              setSort={(e: ISort) => {
                setSort(e);
              }}
              getStudents={getStudents}
            >
              <TextField source="studentId" />
              <TextField source="fullName" />
              <ShowField source="genderName" isTranslate />
              <TextField source="facultyName" />
              <TextField source="courseName" />
              <TextField source="activityClassName" />
              <DateField locales="en-gb" source="birthday" />
              {canShowStudent && <ShowButton resource="user/students" className="parentPaddingNone" label="" />}
              <DeleteStudentButton getStudents={getStudents} />
            </StudentDataGrid>
            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
              labelDisplayedRows={({ from, to, count }) => translate('ra.navigation.page_range_info', {
                offsetBegin: from,
                offsetEnd: to,
                total: count,
              })}
            />
          </ListContextProvider>
        </CardContent>
      </Collapse>
    </>
  );
});
