import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/user/students/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import TranslateField from '../../../components/commons/translateField';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_STUDENT);
  const canShow = canAccess(permissions, FUNC.SHOW_STUDENT_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_STUDENT);
  const canDelete = canAccess(permissions, FUNC.DELETE_STUDENT);
  const canImport = canAccess(permissions, FUNC.IMPORT_STUDENT);
  return (
    <List
      filters={filters}
      actions={<ListActions resource="user/students" isExport isCreate={canAdd} isImport={canImport} />}
      perPage={100}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="id" />
        <TextField source="fullName" />
        <TranslateField namespace="resources.user/students.fields" source="genderName" />
        <TextField source="facultyName" />
        <TextField source="courseName" />
        <TextField source="activityClassName" />
        <DateField source="birthday" />
        <TextField source="email" />
        <TextField source="phone" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
