import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  Edit,
  AutocompleteArrayInput,
  AutocompleteInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import SemesterSelectInput from '../../../components/subject/subjectClasses/SemesterSelectInput';
import StudentList from '../../../components/subject/subjectClasses/studentList';

export default React.memo(() => {
  const {
    types,
    teachers,
    scholastics,
    subjects,
  } = useSelector((state: any) => state.subjectClasses);

  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_SUBJECT_CLASS);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SUBJECT_CLASSES);
  const canShow = canAccess(permissions, FUNC.SHOW_SUBJECT_CLASS_DETAIL);

  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
      transform={(data: any) => {
        data.code = data.subjectClassCode;
        return data;
      }}
    >
      <SimpleForm toolbar={<EditToolBar isSave={canEdit} />}>
        <Grid container paddingX={12} spacing={2} rowSpacing={0.5}>
          <Grid item xs={12} md={4}>
            <SelectInput
              source="scholasticId"
              choices={scholastics ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SemesterSelectInput
              source="semesterId"
              emptyValue=""
              fullWidth
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              source="subjectId"
              choices={subjects ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              source="name"
              fullWidth
              variant="outlined"
              helperText={false}
              validate={[
                required(),
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              fullWidth
              variant="outlined"
              source="subjectClassCode"
              validate={[
                required(),
                maxLength(10),
              ]}
              helperText={false}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              source="type"
              choices={types ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutocompleteInput
              source="teacherId"
              choices={teachers ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4}>
            <AutocompleteArrayInput
              source="subTeacherIds"
              choices={teachers ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={required()}
            />
          </Grid>
        </Grid>
        <Grid container paddingX={12} spacing={2} rowSpacing={0.5}>
          <Grid item xs={12}>
            <StudentList isImport={canEdit} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
