/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  AutocompleteInput,
  Edit,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { courses, faculties } = useSelector((state: any) => state.activityClasses);
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_ACTIVITY_CLASS);
  const canSearch = canAccess(permissions, FUNC.SEARCH_ACTIVITY_CLASSES);
  const canShow = canAccess(permissions, FUNC.SHOW_ACTIVITY_CLASS_DETAIL);
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canEdit} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <AutocompleteInput
              source="facultyId"
              choices={faculties ?? []}
              defaultValue={null}
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <AutocompleteInput
              source="courseId"
              choices={courses ?? []}
              defaultValue={null}
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              helperText={false}
              validate={[
                required(),
                maxLength(50),
              ]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="description"
              validate={[
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
