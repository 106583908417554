import { regex } from 'react-admin';
import msgNo from './msgNo';

/* eslint-disable import/prefer-default-export */
export const SYSTEM = {
  INNER: 1,
  IAM: 2,
};

export const ACCOUNT_STATUS = {
  NORMAL: 0,
  TEMP_LOCK: 10,
  LOCK: 20,
  BLACKLIST: 30,
  CANCEL: 40,
};
export const validateId = regex(/^[\w-.]+$/, msgNo.E054);

export const ADDRESS_TYPE = {
  BIRTH_PLACE: 1,
  PERMANENT_ADDRESS: 2,
  CURRENT_ADDRESS: 3,
  NATIVE_LAND: 4,
};

export const IDENTITY_TYPE = {
  CMND: '10',
  CCCD: '20',
  PASSPORT: '30',
};

export const STUDENT_STATUS = {
  STUDYING: '10',
  GRADUATED: '20',
  SUSPENSION: '30',
  PENDING: '40',
  QUIT: '50',
};

export const VIETNAM_COUNTRY_ID = 'VN';

export const CPV_MEMBER_TYPE = {
  SUB: 'SUB',
  OFFICIAL: 'OFFICIAL',
};

export const TEMPLATE_FILE_NAME = {
  STUDENT_TO_SUBJECT_CLASS: 'ImportStudentToSubjectClassTemplate.xlsx',
};

export const EMAIL_STATUS = {
  NEW: 10,
  SENDING: 20,
  SENT: 30,
  ERROR: 40,
};
