import React from 'react';
import { BooleanInput } from 'react-admin';
import CustomSearchInput from '../../commons/customSearchInput';

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <BooleanInput
    source="isActive"
    className="active-module"
  />,
];
