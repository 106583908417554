import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField,
} from 'react-admin';
import filters from '../../../components/integration/screens/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import GroupField from '../../../components/integration/screens/groupField';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { groups } = useSelector((state: any) => state.screens);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_SCREEN);
  const canDelete = canAccess(permissions, FUNC.DELETE_SCREEN);
  const canPost = canAccess(permissions, FUNC.ADD_SCREEN);
  const canShow = canAccess(permissions, FUNC.SHOW_SCREEN_DETAIL);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canPost} />}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="showId" />
        <TextField source="moduleId" />
        <TextField source="name" />
        <TextField source="url" />
        <BooleanField source="isDefault" />
        <GroupField
          groups={groups}
          source="group"
          fullWidth
        />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
