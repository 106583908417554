/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  NumberInput,
  minValue,
  maxValue,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from '../../../components/commons/createToolBar';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canAdd = canAccess(permissions, FUNC.ADD_SCHOLASTIC);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCHOLASTICS);

  const dateRangeValidation = (value: number, allValues: any) => {
    if (value < allValues.startYear) {
      return 'dut.msg.scholastic_date_range_invalidate';
    }
    return undefined;
  };

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="startYear"
              validate={[
                required(),
                minValue(2020),
                maxValue(2100),
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="endYear"
              validate={[
                required(),
                minValue(2020),
                maxValue(2100),
                dateRangeValidation,
              ]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(200),
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
