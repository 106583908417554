import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
} from 'react-admin';
import filters from '../../../components/system/logs/logfilters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canShow = canAccess(permissions, FUNC.LOG_EXCEPTION);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={false} />}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={false} optimized>
        <DateField source="createdAt" showTime />
        <TextField source="project" />
        <TextField source="class" />
        <TextField source="method" />
        <TextField source="message" />
        <TextField source="source" />
        <TextField source="createdIp" />
        <TextField source="innerException" />
        {canShow && <ShowButton label="" />}
      </Datagrid>
    </List>
  );
});
