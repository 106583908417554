import * as React from 'react';
import {
  NumberInput,
  PasswordInput, SelectInput, SimpleForm, TextInput, email, maxLength, regex, required, useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { actions } from '../../../redux/system/settings';
import EditToolBar from '../../../components/commons/editToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SETTINGS);
  const canEdit = canAccess(permissions, FUNC.EMAIL_SETTING);
  const [mailSystem, setMailSystem] = useState(null);
  const { data } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();
  useEffect(() => {
    setMailSystem(data);
  }, [data]);
  const stmpAuths = [{ id: 'tls', name: 'tls' }, { id: 'ssl', name: 'ssl' }];
  const notify = useNotify();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
  };
  const transform = (value: any) => {
    dispatch(actions.putMailSystem({ value, onSuccess }));
  };
  return (
    <SimpleForm record={mailSystem ?? []} toolbar={<EditToolBar isSave={canEdit} />} onSubmit={transform}>
      {mailSystem && canShow && (
        <>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="mailName"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={10} md={6}>
              <TextInput
                source="mailer"
                variant="outlined"
                disabled
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectInput
                source="mailStmpAuth"
                variant="outlined"
                helperText={false}
                fullWidth
                choices={stmpAuths ?? []}
                validate={[
                  required(),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={10} md={6}>
              <TextInput
                source="mailHost"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <NumberInput
                source="mailPort"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="mailUser"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  regex(/^[a-zA-Z0-9_.-@]{4,50}$/, 'dut.msg.mail_system_username_format_invalidate'),
                  maxLength(50),
                ]}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <PasswordInput
                source="mailPassword"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="mailFrom"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  email(),
                  maxLength(255)]}
                type="email"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="mailReceived"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  email(),
                  maxLength(255)]}
                type="email"
              />
            </Grid>
          </Grid>

        </>
      )}
    </SimpleForm>
  );
});
