import repositories from '..';

const resource = '/user/students';

export default {
  getInitData: (data: any) => {
    if (data) {
      const paramerterString = `?facultyId=${data.facultyId ?? 0}&courseId=${data.courseId ?? 0}`;
      return repositories.get(`${resource}/data/init${paramerterString}`);
    }
    return repositories.get(`${resource}/data/init`);
  },
  getAvatarImage: (fileName: string) => repositories.get(
    `/master/files?fileName=${fileName}`,
    {
      responseType: 'blob',
    },
  ),
  postAvatarImage: (
    studentId: string,
    file: FormData,
  ) => repositories.postForm(`${resource}/${studentId}/avatar`, file),
};
