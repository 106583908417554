import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/system/mailLogs/mailLogListContainer';
import { actions } from '../../../redux/system/logs';

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData({}));
  }, []);
  return (
    <Container />
  );
}
