import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  scholastics: [],
};

export const semesters = createSlice({
  name: 'semesters',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.scholastics = action.payload.scholastics;
    },
    getInitDataFailure: (state) => {
      state.scholastics = [];
    },
  },
});

const actionsTmp: any = { ...semesters.actions };
actionsTmp.getInitData = createAction('semesters/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default semesters.reducer;
