import activityClassList from './activityClassList';
import createActivityClass from './createActivityClass';
import editActivityClass from './editActivityClass';
import showActivityClass from './showActivityClass';

export default {
  list: activityClassList,
  create: createActivityClass,
  edit: editActivityClass,
  show: showActivityClass,
};
