import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
  DateField,
} from 'react-admin';
import filters from '../../../components/integration/apiKeys/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import PasswordTextField from '../../../components/commons/passwordTextField';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_API_KEY);
  const canDelete = canAccess(permissions, FUNC.DELETE_API_KEY);
  const canPost = canAccess(permissions, FUNC.ADD_API_KEY);
  const canShow = canAccess(permissions, FUNC.SHOW_API_KEY_DETAIL);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canPost} />}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="moduleId" />
        <TextField source="name" />
        <PasswordTextField source="key" className="alignItems-class" />
        <PasswordTextField source="loginKey" className="alignItems-class" />
        <PasswordTextField source="secret" className="alignItems-class" />
        <DateField source="from" showTime />
        <DateField source="to" showTime />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
