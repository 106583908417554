import * as React from 'react';
import {
  Button,
  Confirm,
  useListContext,
  useNotify,
  useTranslate,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/subject/subjectClasses';

interface IBulkActionButtonProps {
  getStudents: (() => void),
}
export default React.memo((props: IBulkActionButtonProps) => {
  const { getStudents } = props;
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = React.useState(false);

  const { onUnselectItems, selectedIds } = useListContext();

  const handleUnselectAllClick = React.useCallback(() => {
    onUnselectItems();
  }, [onUnselectItems]);

  const handleOpenConfirmButton = () => setOpenDialog(true);
  const handleCloseConfirmButton = () => setOpenDialog(false);
  const onClickRemoveStudents = () => {
    dispatch(
      actions.removeStudentsFromSubjectClass(
        {
          studentIds: selectedIds,
          id,
          onSuccess: (response: any) => {
            const { data: { removeCount } } = response;
            notify(
              translate('dut.removeStudentSuccess'),
              {
                type: 'success',
                autoHideDuration: 1000,
                messageArgs: {
                  smart_count: removeCount,
                },
              },
            );
            getStudents();
            setOpenDialog(false);
            handleUnselectAllClick();
          },
          onFailed: () => {
            setOpenDialog(false);
          },
          callback: () => {
            setOpenDialog(false);
          },
        },
      ),
    );
  };

  return (
    <>
      <Button
        label="ra.action.delete"
        onClick={handleOpenConfirmButton}
        sx={{ color: 'red' }}
      >
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={openDialog}
        title={translate('dut.removeStudentTitle')}
        content={translate('dut.removeStudentContentDialog', { count: selectedIds?.length || 0 })}
        onConfirm={onClickRemoveStudents}
        onClose={handleCloseConfirmButton}
      />
    </>
  );
});
