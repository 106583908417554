import React from 'react';
import {
  SelectInput,
  TextInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectRole({ source }: any) {
  const { roles } = useSelector((state: any) => state.users);
  return (
    <SelectInput
      source={source}
      choices={roles ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <TextInput
    source="Phone"
    inputProps={{ maxLength: 15 }}
    variant="outlined"
  />,
  <TextInput
    source="Email"
    inputProps={{ maxLength: 255 }}
    variant="outlined"
  />,
  <SelectRole
    source="Role"
  />,
];
