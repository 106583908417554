const messages = {
  resources: {
    'integration/modules': {
      name: 'Modules |||| Modules',
      fields: {
        id: 'Id',
        name: 'Name',
        isActive: 'Active',
        domain: 'Domain',
        logo: 'Logo',
        description: 'Description',
        adminInfoLabel: 'Admin Infomation',
        adminInfo: {
          firstName: 'First name',
          lastName: 'Last name',
          username: 'Username',
          email: 'Email',
          password: 'Password',
          confirmPassword: 'Confirm password',
        },
      },
    },
  },
};

export default messages;
