import * as React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Menu, MenuItemLink, useTranslate } from 'react-admin';
import SubMenu from './subMenu';
import icons from './icon';
import { ParentMenu } from '../../interfaces/menuInterface';

export default function (props: any) {
  const { onMenuClick } = props;
  const { appState } = useSelector((state: any) => state.app);
  const { menu } = appState;
  const [currentMenus, setCurrentMenus] = React.useState(menu);
  const translate = useTranslate();

  React.useEffect(() => {
    const tmpMenu: ParentMenu[] = [];
    [...(appState.menu ?? [])].forEach((item: ParentMenu) => {
      const menuItem = { ...item, isOpen: false };
      menuItem.isOpen = false;
      item.childItems?.forEach((child: any) => {
        if (child.url != null && child.url !== '' && _.startsWith(window.location.pathname, child.url)) {
          menuItem.isOpen = true;
        }
      });
      tmpMenu.push(menuItem);
    });
    setCurrentMenus(tmpMenu);
  }, [appState]);
  const handleToggle = (id: string) => {
    const tmpMenu = [...currentMenus];
    tmpMenu.forEach((item, idx) => {
      if (item.id === id) {
        tmpMenu[idx].isOpen = tmpMenu[idx].isOpen !== true;
      } else {
        tmpMenu[idx].isOpen = false;
      }
    });
    setCurrentMenus(tmpMenu);
  };
  return (
    <Menu
      {...props}
      sx={{
        width: 250,
        '&.RaMenu-closed': {
          width: 50,
        },
      }}
      className="submenu"
    >
      {(currentMenus ?? []).map((item: any) => (
        item.childItems.length === 0
          ? (
            <MenuItemLink
              key={item.id}
              to={item.url}
              primaryText={item.name}
              leftIcon={icons[item.icon]}
              onClick={onMenuClick}
              className="single-menu"
            />
          )
          : (
            <SubMenu
              key={item.id}
              handleToggle={() => handleToggle(item.id)}
              isOpen={item.isOpen}
              name={translate(item.name)}
              icon={icons[item.icon]}
            >
              {item.childItems.map((child: any) => (
                <MenuItemLink
                  key={child.id}
                  to={child.url}
                  leftIcon={icons[child.icon]}
                  primaryText={child.name}
                  onClick={onMenuClick}
                />
              ))}
            </SubMenu>
          )
      ))}
    </Menu>
  );
}
