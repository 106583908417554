const messages = {
  resources: {
    'master/majors': {
      name: 'Chuyên ngành |||| chuyên ngành',
      fields: {
        id: 'Id',
        code: 'Mã chuyên ngành',
        name: 'Tên chuyên ngành',
        description: 'Mô tả',
      },
    },
  },
};

export default messages;
