import createStudent from './createStudent';
import editStudent from './editStudent';
import showStudent from './showStudent';
import studentList from './studentList';

export default {
  list: studentList,
  create: createStudent,
  show: showStudent,
  edit: editStudent,
};
