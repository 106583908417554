const messages = {
  resources: {
    'system/settings': {
      name: 'Cài đặt',
      position: 'Chức vụ',
      mail: 'Hộp thư điện tử',
      language: 'Ngoại ngữ',
      typeStudent: 'Loại sinh viên',
      typeCadres: 'Loại cán bộ',
      blobStorage: 'Lưu trữ',
      other: 'Khác',
      fields: {
        id: 'Mã',
        name: 'Tên',
        positionInClass: 'Chức vụ trong lớp',
        positionInFaculty: 'Chức vụ trong khoa',
        positionInMajor: 'Chức vụ trong chuyên ngành',
        positionInYus: 'Chức vụ trong đoàn',
        positionInCpv: 'Chức vụ trong đảng',
        positionInDepartment: 'Chức vụ trong phòng ban',
        foreignLanguage: 'Ngoại ngữ',
        typeOfStudent: 'Loại sinh viên',
        typeOfEmployee: 'Loại nhân viên',
        typeOfTeacher: 'Loại giảng viên',
        mailName: 'Tên',
        mailer: 'Loại',
        mailStmpAuth: 'Phương thức mã hoá',
        mailHost: 'Địa chỉ',
        mailPort: 'Cổng',
        mailUser: 'Tên đăng nhập',
        mailPassword: 'Mật khẩu',
        mailFrom: 'Mail từ',
        mailReceived: 'Mail nhận',
        cccdIdentityCardIssuer: 'Nơi cấp căn cước công dân',
        cnd: 'CDN',
        mediaUrl: 'Hệ thống lưu trữ',
        religion: 'Tôn giáo',
        studentEmailDomain: 'Tên miền Email sinh viên',
        bucketName: 'Tên Bucket',
        endpoint: 'Địa chỉ',
        accessKey: 'Khoá truy cập',
        secretKey: 'Khoá bí mật',
      },
    },
  },
};

export default messages;
