import webhookList from './webhookList';
import createWebhook from './createWebhook';
import editWebhook from './editWebhook';
import showWebhook from './showWebhook';

export default {
  list: webhookList,
  create: createWebhook,
  edit: editWebhook,
  show: showWebhook,
};
