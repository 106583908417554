import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../repositories/profile';
import ResponseInterface from '../../interfaces/responseInterface';

export function* getProfile() {
  yield takeLatest(actions.getProfile, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getProfile);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getProfileSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getProfileFailure(error));
    }
  });
}

export function* getProfileBase() {
  yield takeLatest(actions.getProfileBase, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getProfileBase);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      console.log(response.data);
      yield put(actions.getProfileBaseSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getProfileBaseFailure(error));
    }
  });
}

export function* saveProfile() {
  yield takeLatest(actions.saveProfile, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.saveProfile, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
      // yield put(actions.saveProfileSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.saveProfileFailure(error));
    }
  });
}

export function* changePassword() {
  yield takeLatest(actions.changePassword, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.changePassword, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response);
      }
      yield put(actions.changePasswordSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.changePasswordFailure(error));
    }
  });
}

export function* getInitData() {
  yield takeLatest(actions.getInitData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getInitData);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getInitDataFailure(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProfile),
    fork(getProfileBase),
    fork(saveProfile),
    fork(changePassword),
    fork(getInitData),
  ]);
}
