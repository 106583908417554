import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import ProfileContainer from '../../containers/profile/profileContainer';
import { actions } from '../../redux/profile';

export default function () {
  const dispatch = useDispatch();
  const notify = useNotify();
  useEffect(() => {
    dispatch(actions.getProfile({}));
    dispatch(actions.getInitData({}));
  }, []);
  const saveProfile = (data: any) => {
    dispatch(actions.saveProfile({
      data,
      onSuccess: () => {
        notify('dut.msg.save_profile_success', {
          type: 'info',
        });
        localStorage.setItem('getProfile', '0');
        dispatch(actions.changeVersionProfile());
        dispatch(actions.getProfile({}));
        dispatch(actions.getInitData({}));
      },
    }));
  };
  return (
    <ProfileContainer onSave={saveProfile} />
  );
}
