import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Create,
  TopToolbar,
  ListButton,
  useTranslate,
  TabbedForm,
  FormTab,
  useRedirect,
} from 'react-admin';
import CreateToolBar from '../../../components/commons/createToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import StudentInfoContainer from './studentInfoContainer';
import UserInfoContainer from '../userInfoContainer';
import UserYusInfoContainer from '../userYusInfoContainer';
import UserCpvInfoContainer from '../userCpvInfoContainer';
import repositories from '../../../repositories/user/students';

export default React.memo(() => {
  const redirect = useRedirect();
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canAdd = canAccess(permissions, FUNC.ADD_STUDENT);
  const canSearch = canAccess(permissions, FUNC.SEARCH_STUDENTS);
  const canUpdateStudentRole = canAccess(permissions, FUNC.EDIT_ROLE_STUDENT);

  const translate = useTranslate();
  const transform = (data:any) => {
    if (!data.information.isYusMember) {
      data.yusInformation = null;
    }
    return data;
  };
  const onSuccess = (data:any) => {
    if (data?.information?.avatar?.rawFile) {
      const studentId = data.id;
      const formData = new FormData();
      formData.append('file', data?.information?.avatar?.rawFile);
      repositories.postAvatarImage(studentId, formData);
    }
    redirect('/user/students');
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      transform={transform}
      className="mai-form"
      redirect="list"
      mutationOptions={{ onSuccess }}
    >
      <TabbedForm toolbar={<CreateToolBar isSave={canAdd} />}>
        <FormTab
          sx={{ flexDirection: 'row' }}
          label={translate('resources.user/students.fields.studentInformationLabel')}
        >
          <StudentInfoContainer canUpdateStudentRole={canUpdateStudentRole} />
        </FormTab>
        <FormTab
          label={translate('resources.user/students.fields.userInformationLabel')}
        >
          <UserInfoContainer roleUser="students" />
        </FormTab>
        <FormTab label={translate('resources.user/students.fields.yusInformationLabel')}>
          <UserYusInfoContainer isTeachers={false} />
        </FormTab>
        <FormTab label={translate('resources.user/students.fields.cpvInformationLabel')}>
          <UserCpvInfoContainer isTeachers={false} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
});
