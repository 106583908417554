const messages = {
  resources: {
    'integration/webhooks': {
      name: 'Webhook |||| Webhooks',
      fields: {
        id: 'Id',
        moduleId: 'Mô đun',
        triggerName: 'Tên sự kiện',
        url: 'Địa chỉ nhận',
        moduleName: 'Tên mô đun',
        apiKeyName: 'ApiKey',
        statusName: 'Trạng thái',
        status: 'Trạng thái',
        histories: {
          name: 'Lịch sử webhook',
          body: 'Dữ liệu',
          detail: 'Chi tiết',
          response: 'Dữ liệu trả về',
          resend: 'Gửi lại',
          status: 'Trạng thái',
          sendAt: 'Gửi lúc',
          url: 'Địa chỉ',
        },
      },
    },
  },
};

export default messages;
