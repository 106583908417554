/* eslint-disable no-param-reassign */
import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormGroup,
  FormControlLabel,
  TableRow,
  Paper,
  Grid,
  Checkbox,
} from '@mui/material';

export default React.memo((props: any) => {
  const {
    field,
  } = useInput(props);
  const translate = useTranslate();
  const changeOtherFunction = (tmp: any[], functionId: string, value: boolean) => {
    tmp.forEach((scr: any, screenIndex: number) => {
      scr.functions.forEach((func: any, index: number) => {
        if (func.id === functionId && func.isEnabled !== value) {
          tmp[screenIndex].functions[index].isEnabled = value;
        }
      });
    });
  };
  const setSelectAllScreen = (tmp: any[]) => {
    tmp.forEach((scr: any, screenIndex: number) => {
      tmp[screenIndex].isEnabledAll = true;
      let haveEnabledFnc = false;
      scr.functions.forEach((func: any) => {
        haveEnabledFnc = haveEnabledFnc || func.isEnabled;
      });
      scr.functions.forEach((func: any, funcIndex: number) => {
        if (haveEnabledFnc && func.isReturnView && !func.isEnabled) {
          tmp[screenIndex].functions[funcIndex].isEnabled = true;
        }
      });
      scr.functions.forEach((func: any) => {
        tmp[screenIndex].isEnabledAll = tmp[screenIndex].isEnabledAll && func.isEnabled;
      });
    });
  };
  const applyData = (tmp: any[]) => {
    setSelectAllScreen(tmp);
    field.onChange(tmp);
  };
  const changeScreen = (index: number) => {
    const tmp = [...field.value];
    tmp[index].isEnabledAll = tmp[index].isEnabledAll !== true;
    tmp[index].functions.forEach((func: any, i: number) => {
      tmp[index].functions[i].isEnabled = tmp[index].isEnabledAll;
      changeOtherFunction(tmp, func.id, tmp[index].isEnabledAll);
    });
    applyData(tmp);
  };
  const changeFunction = (screenIndex: number, index: number) => {
    const tmp = [...field.value];
    tmp[screenIndex].functions[index].isEnabled = tmp[screenIndex].functions[index].isEnabled !== true;
    changeOtherFunction(
      tmp,
      tmp[screenIndex].functions[index].id,
      tmp[screenIndex].functions[index].isEnabled,
    );
    if (tmp[screenIndex].functions[index].isReturnView && !tmp[screenIndex].functions[index].isEnabled) {
      tmp[screenIndex].functions.forEach((func: any, i: number) => {
        tmp[screenIndex].functions[i].isEnabled = false;
        changeOtherFunction(tmp, func.id, false);
      });
    }
    applyData(tmp);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="table-permission">
            <TableBody>
              {(field?.value ?? []).map((screen: any, idx: number) => (
                <React.Fragment key={screen.id}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className="row-screen"
                  >
                    <TableCell component="th" scope="row">
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={screen.isEnabledAll}
                              onChange={() => changeScreen(idx)}
                            />
                          )}
                          label={translate(screen.name)}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className="row-function"
                  >
                    <TableCell scope="row">
                      <Grid container spacing={2}>
                        {screen.functions.map((func: any, i: number) => (
                          <Grid item xs={3} key={func.id}>
                            <FormGroup>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={func.isEnabled}
                                    onChange={() => changeFunction(idx, i)}
                                  />
                                )}
                                label={translate(func.name)}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});
