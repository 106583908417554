const messages = {
  resources: {
    'system/system': {
      name: 'Hệ thống',
      fields: {
        import: 'Nhập dữ liệu của mô đun',
      },
    },
  },
};

export default messages;
