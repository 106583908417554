import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/user/teachers/createTeacherContainer';
import { actions } from '../../../redux/user/teachers';

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData(''));
  }, []);
  return (
    <Container />
  );
}
