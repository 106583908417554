import React from 'react';
import { BooleanInput, SelectInput } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/integration/menuItems';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectModule({ source }: any) {
  const { modules } = useSelector((state: any) => state.menuItems);
  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  return (
    <SelectInput
      source={source}
      choices={modules ?? []}
      onChange={(choice) => getData(choice.target.value)}
      variant="outlined"
    />
  );
}

function SelectParent({ source }: any) {
  const { menus } = useSelector((state: any) => state.menuItems);
  return (
    <SelectInput
      source={source}
      choices={menus ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectModule
    source="moduleId"
  />,
  <SelectParent
    source="parentId"
  />,
  <BooleanInput
    source="isActive"
    className="active-module"
  />,
];
