import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  screens: [],
  functions: [],
  modules: [],
};

export const functions = createSlice({
  name: 'functions',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
      state.screens = action.payload.screens;
      state.functions = action.payload.functions;
    },
    getInitDataFailure: (state) => {
      state.screens = [];
      state.functions = [];
      state.modules = [];
    },
  },
});

const actionsTmp: any = { ...functions.actions };
actionsTmp.getInitData = createAction('functions/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default functions.reducer;
