import React from 'react';
import {
  Datagrid,
  List, ListActions,
  ShowButton,
  TextField,
} from 'react-admin';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import PostPagination from '../../commons/postPagination';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import keywordFilter from '../../commons/keywordFilter';

export default React.memo(() => {
  const { id } = useParams();
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SHOW_ACTIVITY_CLASS_DETAIL);
  return (
    <List
      filters={keywordFilter}
      title=""
      resource="master/activity-classes"
      actions={<ListActions hasCreate={false} />}
      pagination={<PostPagination />}
      empty={false}
      filter={{ courseId: id, facultyId: 0 }}
      perPage={100}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="facultyName" />
        <TextField source="description" />
        {canShow && <ShowButton label="" />}
      </Datagrid>
    </List>
  );
});
