/* eslint-disable indent */
import { createSlice, createAction } from '@reduxjs/toolkit';
import { ADDRESS_TYPE } from '../../../utils/constant';

const initialState = {
  nativeLandDistricts: [],
  birthPlaceDistricts: [],
  permanentAddressDistricts: [],
  currentAddressDistricts: [],
};

export const districts = createSlice({
  name: 'districts',
  initialState,
  reducers: {
    getDataSuccess: (state, action) => {
      switch (action.payload.type) {
        case ADDRESS_TYPE.BIRTH_PLACE:
          state.birthPlaceDistricts = action.payload.districts;
          break;
        case ADDRESS_TYPE.PERMANENT_ADDRESS:
          state.permanentAddressDistricts = action.payload.districts;
          break;
        case ADDRESS_TYPE.CURRENT_ADDRESS:
          state.currentAddressDistricts = action.payload.districts;
          break;
        case ADDRESS_TYPE.NATIVE_LAND:
          state.nativeLandDistricts = action.payload.districts;
          break;
        default:
          break;
      }
    },
    getDataFailure: () => {
    },
  },
});

const actionsTmp: any = { ...districts.actions };
actionsTmp.getData = createAction('master/district/getData');

export const districtsActions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default districts.reducer;
