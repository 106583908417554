const messages = {
  resources: {
    'system/system': {
      name: 'System',
      fields: {
        import: 'Import module data',
      },
    },
  },
};

export default messages;
