const messages = {
  resources: {
    'master/courses': {
      name: 'Courses |||| Course',
      fields: {
        id: 'Id',
        name: 'Course name',
        description: 'Description',
        activityClasses: 'Activity classes',
        activityClassName: 'Activity class name',
      },
    },
  },
};

export default messages;
