import React from 'react';
import {
  Datagrid,
  DateField,
  List, ListActions,
  ShowButton,
  TextField,
} from 'react-admin';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import PostPagination from '../../commons/postPagination';
import TranslateField from '../../commons/translateField';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import keywordFilter from '../../commons/keywordFilter';

export default React.memo(() => {
  const { id } = useParams();
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShowStudent = canAccess(permissions, FUNC.SHOW_STUDENT_DETAIL);
  return (
    <List
      filters={keywordFilter}
      title=""
      resource="user/students"
      actions={<ListActions hasCreate={false} />}
      pagination={<PostPagination />}
      empty={false}
      filter={{ activityClass: id }}
      perPage={100}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="fullName" />
        <TranslateField namespace="resources.user/students.fields" source="genderName" />
        <TextField source="facultyName" />
        <DateField source="birthday" />
        <TextField source="email" />
        <TextField source="phone" />
        {canShowStudent && <ShowButton label="" />}
      </Datagrid>
    </List>
  );
});
