import {
  all, takeLatest, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../repositories/oauth';
import ResponseInterface from '../../interfaces/responseInterface';

export function* checkClient() {
  yield takeLatest(actions.checkClient, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.checkClient, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      // yield put(actions.getProfileSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.getProfileFailure(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkClient),
  ]);
}
