/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_FUNCTION);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCREENS);
  const canShowScreen = canAccess(permissions, FUNC.SHOW_SCREEN_DETAIL);
  const canPutScreen = canAccess(permissions, FUNC.EDIT_FUNCTION);
  return (
    <Show
      actions={<ShowActions isEdit={canPut} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.showId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              source="showId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.moduleId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="moduleId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.functionCode" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="functionCode"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.name" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.isReturnView" />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isReturnView"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.isRequired" />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isRequired"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.isHidden" />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isHidden"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.showRelatedFunctionId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="showRelatedFunctionId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.url" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="url"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.area" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="area"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.controller" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="controller"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.action" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="action"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.sort" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="sort"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/functions.fields.relateScreen" />
          </Grid>
          <ArrayField source="screens">
            <Datagrid bulkActionButtons={false}>
              <TextField source="showId" />
              <TextField source="name" />
              {canShowScreen && <ShowButton resource="integration/screens" label="" />}
              {canPutScreen && <EditButton resource="integration/screens" label="" />}
              {canPutScreen && <DeleteWithConfirmButton resource="integration/screens/functions" label="" mutationMode="pessimistic" />}
            </Datagrid>
          </ArrayField>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
