const messages = {
  resources: {
    'subject/subject-classes': {
      name: 'Subject Classes |||| Subject Class',
      fields: {
        id: 'Id',
        name: 'Name',
        subjectId: 'Subject',
        subjectName: 'Subject',
        subjectClassCode: 'Code',
        code: 'Code',
        type: 'Type',
        typeName: 'Type',
        teacherId: 'Teacher',
        teacherName: 'Teacher',
        scholasticId: 'Scholastic',
        scholasticName: 'Scholastic',
        semesterId: 'Semester',
        semesterName: 'Semester',
        subTeacherIds: 'Sub Teachers',
        subTeachers: 'Sub Teachers',
        import: 'Import Students',
        studentId: 'Student Id',
        fullName: 'FullName',
        genderName: 'Gender',
        facultyName: 'Faculty',
        courseName: 'Course',
        activityClassName: 'Activity Class',
        birthday: 'Birthday',
        students: 'Students',
      },
    },
  },
  dut: {
    importStudentSuccess: 'Import student success |||| Import %{smart_count} students success',
    removeStudentSuccess: 'Xoá thành công %{smart_count} sinh viên khỏi lớp học phần',
    downloadFileTemplate: 'Download file template',
    removeStudentTitle: 'Remove students from subject class',
    removeStudentContentDialog: 'Are you sure want to remove %{count} students from this subject class',
    addStudentToSubjectClass: 'Add student',
    addStudentTitle: 'Thêm sinh viên vào lớp học phần',
    addStudentContentDialog: 'Bạn có muốn thêm %{count} sinh viên vào lớp học phần',
  },
};

export default messages;
