import * as React from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

export default React.memo((props: any) => {
  const {
    keys,
    hiddenKeys,
    datas,
    canEdit,
    source,
  } = props;

  const methods = useFormContext();
  const [isInit, setIsInit] = React.useState(true);
  const translate = useTranslate();

  React.useEffect(() => {
    if (datas && datas?.length > 0 && isInit) {
      methods.setValue(source, datas);
      setIsInit(false);
    }
  }, [datas]);

  return (
    <ArrayInput
      hiddenLabel
      source={source}
      sx={{
        marginTop: '0',
      }}
    >
      <SimpleFormIterator className="rowfunction">
        {keys.map((key: string) => (
          <TextInput
            focused={false}
            source={key}
            label={translate(`resources.system/settings.fields.${key}`)}
            validate={[required()]}
            sx={{ width: '100%' }}
            variant="outlined"
            helperText={false}
            disabled={!canEdit}
          />
        ))}
        {hiddenKeys?.map((key: string) => (
          <TextInput
            source={key}
            style={{ display: 'none' }}
          />
        ))}
      </SimpleFormIterator>
    </ArrayInput>
  );
});
