import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import { useSelector } from 'react-redux';

export default React.memo(() => {
  const { lang } = useSelector((state: any) => state.app);
  const [locale, setLocale] = React.useState(lang);
  React.useEffect(() => {
    setLocale(lang);
  }, [lang]);
  return (
    <Card>
      <Title title="dut.dashboard" />
      <CardContent>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locales={allLocales}
          locale={locale}
        />
      </CardContent>
    </Card>
  );
});
