const messages = {
  resources: {
    'system/logs/mail': {
      name: 'Mail Log |||| Mail Logs',
      fields: {
        id: 'Id',
        sendAt: 'Send at',
        subject: 'Subject',
        template: 'Template',
        fromEmail: 'From Email',
        toEmail: 'Receive Email',
        mailError: 'Error',
        numberOfError: 'Number of error',
        statusName: 'Status',
        priorityName: 'Priority',
        model: 'Data',
        body: 'Content',
      },
    },
  },
};

export default messages;
