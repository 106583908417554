import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/system/roles/editRoleContainer';
import { actions } from '../../../redux/system/roles';

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData({}));
  }, []);
  return (
    <Container />
  );
}
