import React from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/integration/webhooks/createWebhookContainer';
import { actions } from '../../../redux/integration/webhooks';

export default function () {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.getInitData('\'\''));
  }, []);
  return (
    <Container />
  );
}
