import {
  all, takeEvery, put, call, fork,
} from 'redux-saga/effects';
import { districtsActions } from '.';
import repositories from '../../../repositories/master/districts';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getData() {
  yield takeEvery(districtsActions.getData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.get, data.payload.provinceId);
      const districts = { type: payload.type, districts: response.data };
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(districts);
      }
      yield put(districtsActions.getDataSuccess(districts));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(districtsActions.getDataFailure(error));
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getData),
  ]);
}
