import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Confirm,
} from 'react-admin';

export default React.memo((props:any) => {
  const { onClick, title, content } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    onClick();
    setOpen(false);
  };

  return (
    <>
      <DeleteIcon
        className="pointerClass"
        fontSize="small"
        sx={{
          paddingLeft: '5px', color: '#d32f2f', paddingRight: '5px', marginTop: '5px',
        }}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        title={title}
        content={content}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
});
