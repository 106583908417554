const messages = {
  resources: {
    'system/logs': {
      name: 'Log |||| Logs',
      fields: {
        id: 'Id',
        createdAt: 'Time',
        project: 'Project',
        class: 'Class',
        method: 'Method',
        message: 'Message',
        source: 'Source',
        createdIp: 'Ip address',
        innerException: 'Exception',
        requestId: 'Request Id',
        data: 'Data',
        stackTrace: 'Stack trace',
        username: 'Username',
      },
    },
  },
};

export default messages;
