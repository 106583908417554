import React from 'react';
import {
  Button as RAButton, useRecordContext, useTranslate,
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Tooltip } from '@mui/material';
import repository from '../../../repositories';

export default React.memo(() => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) {
    return null;
  }
  const { id } = record;
  const resource = '/system/system/export';

  const clickImportButton = () => {
    if (!id) {
      return;
    }
    repository.get(`${resource}?moduleId=${id}`, { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = `Data-${id}-${new Date().toISOString().slice(0, 10)}.xlsx`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
  };

  return (
    <Tooltip title={translate('ra.action.export')} placement="top-end" arrow>
      <RAButton
        color="primary"
        onClick={clickImportButton}
      >
        <GetAppIcon style={{ fontSize: '20' }} />
      </RAButton>
    </Tooltip>
  );
});
