import * as React from 'react';
import { TopToolbar, ListButton, ShowButton } from 'react-admin';

export default React.memo((props: any) => {
  const { isShow, isList } = props;
  return (
    <TopToolbar>
      {isShow !== false && <ShowButton />}
      {isList !== false && <ListButton />}
    </TopToolbar>
  );
});
