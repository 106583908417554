import functionList from './functionList';
import createFunction from './createFunction';
import editFunction from './editFunction';
import showFunction from './showFunction';

export default {
  list: functionList,
  create: createFunction,
  edit: editFunction,
  show: showFunction,
};
