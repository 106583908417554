const messages = {
  resources: {
    'integration/functions': {
      name: 'Functions |||| Functions',
      fields: {
        showId: 'Id',
        moduleId: 'Module',
        functionCode: 'Code',
        name: 'Name',
        isReturnView: 'Return View',
        isRequired: 'Required',
        isHidden: 'Hidden',
        showRelatedFunctionId: 'Related Function',
        screen: 'Screen',
        url: 'Url',
        area: 'Area',
        controller: 'Controller',
        action: 'Action',
        sort: 'Sort',
        isActive: 'Active',
        relateScreen: 'Screens has this function',
      },
    },
  },
};

export default messages;
