import * as React from 'react';
import {
  SimpleForm,
  PasswordInput,
  Title,
  Toolbar,
  SaveButton,
  required,
  useTranslate,
  regex,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

export default React.memo((props: any) => {
  const translate = useTranslate();
  const { password, versionPass } = useSelector((state: any) => state.profile);
  const { onSave } = props;
  const savePassword = (data: any) => {
    if (typeof onSave === 'function') {
      onSave(data);
    }
  };
  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.newPassword !== value) {
      return 'dut.msg.cornfirm_password_invalidate';
    }
    return undefined;
  };
  return (
    <div className="dut-form">
      <SimpleForm
        key={versionPass}
        toolbar={<Toolbar><SaveButton /></Toolbar>}
        record={password}
        onSubmit={savePassword}
      >
        <Title title={translate('dut.changePassword')} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="oldPassword"
              label={translate('dut.oldPassword')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="newPassword"
              label={translate('dut.newPassword')}
              validate={[
                required(),
                regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'dut.msg.password_format_invalidate'),
              ]}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="confirmPassword"
              label={translate('dut.confirmPassword')}
              validate={[required(), confirmPasswordValidation]}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </div>
  );
});
