import * as React from 'react';
import {
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useListContext,
  useTranslate,
} from 'react-admin';
import ImportButton from './importButton';

export default React.memo((props: any) => {
  const {
    resource,
    isCreate,
    isExport,
    isImport,
    exporter,
  } = props;
  const translate = useTranslate();
  const { total, isLoading } = useListContext();
  const canCreate = isCreate === undefined || isCreate === null ? true : isCreate;
  return (
    <TopToolbar>
      <FilterButton />
      { canCreate && <CreateButton /> }
      { isExport && <ExportButton disabled={isLoading || total === 0} exporter={exporter} /> }
      { isImport && (
        <ImportButton
          resource={resource}
          label={translate('dut.import_button')}
          title={translate(`resources.${resource}.fields.import`)}
        />
      )}
    </TopToolbar>
  );
});
