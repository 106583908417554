import React from 'react';
import { BooleanInput, SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectGroup({ source }: any) {
  const { groups } = useSelector((state: any) => state.screens);
  return (
    <SelectInput
      source={source}
      choices={groups ?? []}
      variant="outlined"
    />
  );
}

function SelectModule({ source }: any) {
  const { modules } = useSelector((state: any) => state.screens);
  return (
    <SelectInput
      source={source}
      choices={modules ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectModule
    source="moduleId"
  />,
  <SelectGroup
    source="group"
  />,
  <BooleanInput
    source="isDefault"
    className="active-module"
  />,
  <BooleanInput
    source="isActive"
    className="active-module"
    sx={{
      marginBottom: 1.7,
    }}
  />,
];
