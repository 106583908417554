/* eslint-disable indent */
import { createSlice, createAction } from '@reduxjs/toolkit';
import { ADDRESS_TYPE } from '../../../utils/constant';

const initialState = {
  nativeLandCommunes: [],
  birthPlaceCommunes: [],
  permanentAddressCommunes: [],
  currentAddressCommunes: [],
};

export const communes = createSlice({
  name: 'communes',
  initialState,
  reducers: {
    getDataSuccess: (state, action) => {
      switch (action.payload.type) {
        case ADDRESS_TYPE.BIRTH_PLACE:
          state.birthPlaceCommunes = action.payload.communes;
          break;
        case ADDRESS_TYPE.PERMANENT_ADDRESS:
          state.permanentAddressCommunes = action.payload.communes;
          break;
        case ADDRESS_TYPE.CURRENT_ADDRESS:
          state.currentAddressCommunes = action.payload.communes;
          break;
        case ADDRESS_TYPE.NATIVE_LAND:
          state.nativeLandCommunes = action.payload.communes;
          break;
        default:
          break;
      }
    },
    getDataFailure: () => {
    },
  },
});

const actionsTmp: any = { ...communes.actions };
actionsTmp.getData = createAction('master/commune/getData');

export const communesActions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default communes.reducer;
