import React from 'react';
import { BooleanInput, SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectModule({ source }: any) {
  const { modules } = useSelector((state: any) => state.apiKeys);
  return (
    <SelectInput
      source={source}
      choices={modules ?? []}
      variant="outlined"
    />
  );
}

function SelectEnvironment({ source }: any) {
  const { environments } = useSelector((state: any) => state.apiKeys);
  return (
    <SelectInput
      source={source}
      choices={environments ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <CustomSearchInput source="domain" placeholder="domain" />,
  <SelectModule
    source="moduleId"
  />,
  <SelectEnvironment
    source="env"
  />,
  <BooleanInput
    source="isActive"
    className="active-module"
  />,
];
