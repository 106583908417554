import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  teachers: [],
  subjects: [],
  scholastics: [],
  faculties: [],
  types: [],
};

export const subjectClasses = createSlice({
  name: 'subjectClasses',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.teachers = action.payload.teachers;
      state.subjects = action.payload.subjects;
      state.scholastics = action.payload.scholastics;
      state.faculties = action.payload.faculties;
      state.types = action.payload.types;
    },
    getInitDataFailure: (state) => {
      state.teachers = [];
    },
  },
});

const actionsTmp: any = { ...subjectClasses.actions };
actionsTmp.getInitData = createAction('subjectClasses/getInitData');
actionsTmp.importStudents = createAction('subjectClasses/importStudents');
actionsTmp.addStudentsToSubjectClass = createAction('subjectClasses/addStudentsToSubjectClass');
actionsTmp.removeStudentsFromSubjectClass = createAction('subjectClasses/removeStudentsFromSubjectClass');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default subjectClasses.reducer;
