const msgNo: any = {
  C001: 'confirm_save_data',
  C002: 'confirm_delete_data',
  C003: 'confirm_reset_password',
  C004: 'confirm_change_password',
  C005: 'confirm_unlock_account',
  C006: 'confirm_verify_account',
  C007: 'confirm_lock_account',
  C008: 'confirm_add_account_to_black_list',
  C009: 'confirm_remove_account_from_black_list',

  S001: 'save_data_success',
  S002: 'delete_data_success',
  S003: 'create_js_success',
  S004: 'build_file_success',
  S005: 'reset_password_success',
  S006: 'clone_data_success',
  S007: 'send_email_for_reset_password_success',
  S008: 'send_email_for_active_account_success',
  S009: 'change_password_success',
  S010: 'save_profile_success',
  S011: 'type_email_for_reset_password',

  W001: 'warning_create_js',
  W002: 'warning_build_file',

  E001: 'required',
  E002: 'email_invalidate',
  E003: 'date_invalidate',
  E004: 'url_format_invalidate',
  E005: 'maxlength_invalidate',
  E006: 'minlength_invalidate',
  E007: 'value_must_geater',
  E008: 'value_must_lesser',
  E009: 'phone_number_format_invalidate',
  E010: 'time_from_must_be_less_than_time_to',
  E011: 'data_duplicate',
  E012: 'username_not_correct',
  E013: 'password_not_correct',
  E014: 'username_or_password_not_correct',
  E015: 'username_had_used',
  E016: 'system_must_have_least_1_user',
  E017: 'password_format_invalidate',
  E018: 'cornfirm_password_invalidate',
  E019: 'username_format_invalidate',
  E020: 'file_size_too_larger',
  E021: 'extension_not_allow',
  E022: 'cannot_upload_many_files',
  E023: 'upload_file_error',
  E024: 'you_must_choose_file',
  E025: 'size_of_img_invalidate',
  E026: 'eror_min_item',
  E027: 'slug_format_invalidate',
  E028: 'save_data_error',
  E029: 'delete_data_error',
  E030: 'number_of_record_too_larger',
  E031: 'create_js_error',
  E032: 'data_existing',
  E033: 'message_code_existing',
  E034: 'function_code_existing',
  E035: 'group_have_account',
  E036: 'data_export_not_exists',
  E037: 'email_not_correct',
  E038: 'token_timeout',
  E039: 'email_had_used',
  E040: 'account_was_locked',
  E041: 'account_in_blacklist',
  E042: 'value_from_must_be_less_than_value_to',
  E043: 'select_at_least_1_line',
  E044: 'token_invalidate',
  E045: 'must_be_number',
  E046: 'can_not_delete_module',
  E047: 'module_unactive',
  E048: 'can_not_delete_screen',
  E049: 'can_not_delete_function',
  E050: 'menu_level_too_big',
  E051: 'email_or_phone_not_verified',
  E052: 'can_not_delete_api_group',
  E053: 'can_not_delete_screen_group',
  E054: 'code_invalid',
  E055: 'cannot_delete_role_have_users',
  E056: 'cannot_read_file_data',
  E057: 'file_data_invalid',
  E058: 'file_extension_invalid',
  E059: 'faculty_code_existing',
  E060: 'semester_date_start_invalidate',
  E061: 'semester_date_end_invalidate',
  E062: 'invalid_faculty',
  E063: 'mail_system_username_format_invalidate',
  E064: 'webhook_not_have_apikey',
  E065: 'email_sending',
  E066: 'email_sended',
  E067: 'webhook_stopped',

  E201: 'input_validate_error',
  E202: 'have_error',
  E401: 'you_must_login',
  E403: 'you_not_have_permission',
  E404: 'not_found',
  E500: 'server_error',
};
export default msgNo;
