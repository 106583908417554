const messages = {
  resources: {
    'user/teachers': {
      name: 'Teachers |||| Teachers',
      fields: {
        import: 'Import teachers',
        teacherInformationLabel: 'Teacher Information',
        userInformationLabel: 'User Infomation',
        yusInformationLabel: 'Yus Information',
        cpvInformationLabel: 'Cpv Information',
        language: 'Language',
        language1: 'Language 1',
        language2: 'Language 2',
        currentAddressLabel: 'Current Address',
        teacherCode: 'Teacher code',
        permanentAddressLabel: 'Permanent Address',
        birthPlaceLabel: 'Birthplace Address',
        nativeLandLabel: 'Native Land',
        id: 'id',
        userId: 'User Id',
        username: 'User name',
        teacherNote: 'Teacher note',
        email: 'Education email',
        phone: 'Phone Number 1',
        email2: 'Personal Email',
        phone2: 'Phone Number 2',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        male: 'Male',
        female: 'Female',
        firstName: 'First Name',
        lastName: 'Last name',
        fullName: 'Full name',
        otherName: 'Other name',
        healthStatus: 'Health status',
        height: 'Height',
        weight: 'Weight',
        candidate: 'Candidate',
        bloodGroup: 'Blood group',
        job: 'Job',
        educationalLevel: 'Educational level',
        englishLevel: 'English level',
        computerLevel: 'Computer level',
        bestCertificate: 'Best certificate',
        politicalTheory: 'Political theory',
        qlnn: 'Qlnn',
        isWorkFarFromHome: 'Work far from home',
        gender: 'Gender',
        genderName: 'Gender',
        birthday: 'Birthday',
        avatar: 'Avatar',
        cover: 'Cover',
        portraitPhoto: 'Portrait photo',
        cpvDateJoining: 'Cpv date joining',
        cpvPlaceJoining: 'Cpv place joining',
        taxCode: 'Tax code',
        note: 'Note',
        type: 'Type',
        typeName: 'Teaching position',
        activityClassId: 'Activity class',
        activityClassName: 'Activity class',
        facultyId: 'Faculty',
        facultyName: 'Faculty',
        faculty: 'Faculty',
        teachingFaculties: 'Teaching faculties',
        listDepartments: 'Work departments',
        roleIds: 'Roles',
        faculties: {
          id: 'Faculty',
          name: 'Faculty',
          positions: {
            id: 'Id',
            label: 'Positions in faculty',
            name: 'Position',
            isActive: 'Is active',
            from: 'From',
            to: 'To',
          },
        },
        majors: {
          id: 'Major',
          name: 'Major',
          positions:
              {
                id: 'Id',
                label: 'Positions in major',
                name: 'Position',
                isActive: 'Is active',
                from: 'From',
                to: 'To',
              },
        },
        languages:
          {
            id: 'Id',
            name: 'Name',
            order: 'Order',
          },
        information: {
          firstName: 'First name',
          lastName: 'Last name',
          otherName: 'Other name',
          email: 'Personal email',
          phone: 'Phone number 2',
          isYusMember: 'Yus member',
          dentityCardType: 'Identity card type',
          identityCardIssuerId: 'Identity card issuer',
          identityCardIssuerName: 'Identity card issuer',
          identityCardNumber: 'Identity card number',
          identityCardIssueDate: 'Identity card issue date',
          identityCardFront: 'Identity card front',
          theBackOfIdentityCard: 'The back of identity card',
          gender: 'Gender',
          birthday: 'Birthday',
          nationalityId: 'Nationality',
          ethnicityId: 'Ethicity',
          religion: 'Religion',
          height: 'Height',
          weight: 'Weight',
          candidate: 'Candidate',
          bloodGroup: 'BloodGroup',
          job: 'Job',
          educationalLevel: 'Educational level',
          englishLevel: 'English level',
          computerLevel: 'Computer level',
          bestCertificate: 'Best certificate',
          politicalTheory: 'Political theory',
          qlnn: 'QLNN',
          isWorkFarFromHome: 'Is Work Far From Home',
          healthStatus: 'Health status',
          familyFrom: 'Family From',
          note: 'Note',
          birthPlaceCountryId: 'Birth place country',
          birthPlaceProvinceId: 'Birth place province',
          birthPlaceProvinceName: 'Birth place province',
          language1: 'Language 1',
          language2: 'Language 2',
          currentAddress: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
          nativeLand: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
          permanentAddress: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
        },
        departments: {
          id: 'Id',
          name: 'Department',
          department: 'Department',
          positions: {
            id: 'Position',
            label: 'Positions in department',
            name: 'Position',
            isActive: 'Is active',
            from: 'From',
            to: 'To',
          },
        },
        yusInformation: {
          status: 'Status',
          isMember: 'Is member',
          isNewMember: 'New member',
          isMovedMember: 'Moved member',
          isTempMember: 'Temp member',
          card: 'Card',
          prepareDate: 'Prepare date',
          officialDate: 'Official date',
          movedDate: 'Moved date',
          yusClassBefore: 'Yus class before',
          isRegisterForYusActivities: 'Register for yus activities',
          placeOfRegisterForYusActivities: 'Place of register for yus activities',
          regisStartDate: 'Registration start date',
          regisEndDate: 'Registration end date',
          dateJoining: 'Date joining',
          placeJoining: 'Place joining',
          positionInYusId: 'Position in yus',
        },
        cpvInformation: {
          status: 'Status',
          isCpvMember: 'Is Cpv member',
          cpvDateJoining: 'Sub date joining',
          cpvRealDateJoining: 'Real date joining',
          cpvPlaceOfActivityId: 'Place of activity',
          cpvPlaceJoining: 'Place joining',
        },
      },
    },
  },
  dut: {
    removeFaculty: 'Remove faculty',
    addFaculty: 'Add faculty',
    addPosition: 'Add position',
    removePositionInFaculty: 'Remove position in faculty',
    removePositionMajor: 'Remove position in major',
    removeDepartment: 'Remove department',
    addDepartment: 'Add department',
    removePositionDepartment: 'Remove position in department',
  },
};

export default messages;
