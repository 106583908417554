import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  EditButton, ShowButton, TextField, useRecordContext,
} from 'react-admin';
import CustomedDeleteButton from './customedDeleteButton';

export default React.memo((props: any) => {
  const {
    deleteHandle, sourceScreenId, canShowFunction, canPutFunction, canPut,
  } = props;
  const record = useRecordContext();
  const id = record[sourceScreenId];
  return (
    <ArrayField source="functions">
      <Datagrid bulkActionButtons={false}>
        <TextField source="showId" />
        <TextField source="functionCode" />
        <TextField source="name" />
        {canShowFunction && <ShowButton resource="integration/functions" label="" />}
        {canPutFunction && <EditButton resource="integration/functions" label="" />}
        {canPut && (
          <CustomedDeleteButton
            deleteHandle={deleteHandle}
            sourceFunctionId="id"
            screenId={id}
          />
        )}
      </Datagrid>
    </ArrayField>
  );
});
