/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  Edit,
  NumberInput,
  maxValue,
  minValue,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_SCHOLASTIC);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCHOLASTICS);
  const canShow = canAccess(permissions, FUNC.SHOW_SCHOLASTIC_DETAIL);

  const dateRangeValidation = (value: number, allValues: any) => {
    if (value < allValues.startYear) {
      return 'dut.msg.scholastic_date_range_invalidate';
    }
    return undefined;
  };

  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canEdit} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="startYear"
              validate={[
                required(),
                minValue(2020),
                maxValue(2100),
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <NumberInput
              fullWidth
              variant="outlined"
              source="endYear"
              validate={[
                required(),
                minValue(2020),
                maxValue(2100),
                dateRangeValidation,
              ]}
              helperText={false}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              multiline
              variant="outlined"
              source="description"
              validate={[
                maxLength(200),
              ]}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
