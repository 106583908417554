/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_MODULE);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MODULES);
  const translate = useTranslate();
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.id')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              source="id"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.domain')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="domain"
            />
          </Grid>
          {/* <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.logo')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="logo"
            />
          </Grid> */}
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.description')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="description"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/modules.fields.isActive')} />
          </Grid>
          <Grid item xs={3} md={5}>
            <BooleanField label="Active" source="isActive" />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
