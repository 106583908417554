/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import WebhookHistoryList from '../../../components/integration/webhooks/webhookHistoryList';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_WEBHOOK);
  const canSearch = canAccess(permissions, FUNC.SEARCH_WEBHOOK);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/webhooks.fields.moduleName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="moduleName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/webhooks.fields.apiKeyName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="apiKeyName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/webhooks.fields.triggerName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="triggerName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/webhooks.fields.statusName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="statusName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.integration/webhooks.fields.url')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <UrlField
              fullWidth
              source="url"
            />
          </Grid>
          <Grid container xs={12} md={12} mt={4}>
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
              {translate('resources.integration/webhooks.fields.histories.name')}
            </Typography>
          </Grid>
          <Grid item xs={10} md={16}>
            <WebhookHistoryList />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
