const messages = {
  resources: {
    'integration/screen-groups': {
      name: 'Nhóm màn hình |||| Nhóm màn hình',
      fields: {
        id: 'Mã',
        moduleId: 'Mô đun',
        moduleName: 'Tên Mô đun',
        name: 'Tên',
        description: 'Mô tả',
        showId: 'Mã',
      },
    },
  },
};

export default messages;
