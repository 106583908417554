import React from 'react';
import { useTranslate, FunctionField } from 'react-admin';
import get from 'lodash/get';

export default React.memo(({ namespace, ...props }: any) => {
  const translate = useTranslate();
  const getKey = (content: string) => {
    try {
      return content.toLowerCase().replace(/ /g, '_');
    } catch {
      return content;
    }
  };
  return (
    <FunctionField
      {...props}
      render={(record: string, source: any) => (get(record, source)
        ? translate(`${namespace ?? 'dut'}.${getKey(get(record, source))}`)
        : '')}
    />
  );
});
