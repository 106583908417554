import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
  environments: [],
};

export const apiKeys = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.modules = action.payload.modules;
      state.environments = action.payload.environments;
    },
    getInitDataFailure: (state) => {
      state.modules = [];
      state.environments = [];
    },
  },
});

const actionsTmp: any = { ...apiKeys.actions };
actionsTmp.getInitData = createAction('apiKeys/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default apiKeys.reducer;
