import React from 'react';
import {
  Button as RAButton,
  FileField,
  FileInput,
  SimpleForm,
  useNotify,
  useTranslate,
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { HubConnectionBuilder } from '@microsoft/signalr';
import store from '../../../redux/store';
import { actions as appActions } from '../../../redux/app';
import { actions } from '../../../redux/subject/subjectClasses';

export default React.memo((props:any) => {
  const {
    label,
    title,
    id,
    getStudents,
  } = props;
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  const onCancel = () => {
    setOpen(false);
  };

  const clickImportButton = () => {
    setOpen(true);
  };

  const handleSubmit = async (value:any) => {
    const formData = new FormData();
    formData.append('fileData', value.fileData.rawFile);
    setOpen(false);

    const token = localStorage.getItem('token');
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const url = `${baseUrl}/hub/notification?token=${token}`;

    try {
      const connect = new HubConnectionBuilder()
        .withUrl(url, {
          headers: {
            'x-apikey': apiKey,
          },
        })
        .build();

      store.dispatch(appActions.isProgressLoading(true));

      if (connect) {
        connect.start()
          .then(async () => {
            connect.on('NotifyCurrentProgress', (message: any) => {
              const { progress } = message;
              store.dispatch(appActions.setProgressLoading(progress));
            });

            dispatch(actions.importStudents({
              id,
              data: formData,
              onSuccess: (response: any) => {
                const { data: { importCount } } = response;
                notify(
                  translate('dut.importStudentSuccess'),
                  {
                    type: 'success',
                    autoHideDuration: 1000,
                    messageArgs: {
                      smart_count: importCount,
                    },
                  },
                );
                if (getStudents && typeof getStudents === 'function') {
                  getStudents();
                }
              },
              onFailed: () => {
              },
              callback: () => {
                store.dispatch(appActions.isProgressLoading(false));
                store.dispatch(appActions.setProgressLoading(0));
                connect.stop();
              },
            }));
          })
          .catch(() => {
            store.dispatch(appActions.isProgressLoading(false));
            store.dispatch(appActions.setProgressLoading(0));
            connect.stop();
          });
      }
    } catch (e: any) {
      store.dispatch(appActions.isProgressLoading(false));
      store.dispatch(appActions.setProgressLoading(0));
    }
  };

  return (
    <>
      <RAButton
        color="primary"
        label={label}
        onClick={clickImportButton}
      >
        <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
      </RAButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SimpleForm toolbar={false} onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {title}
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FileInput
              source="fileData"
              multiple={false}
              accept=".xlsx"
              fullWidth
              label={false}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </DialogContent>
          <DialogActions>
            <RAButton type="submit" label={translate('ra.action.save')} />
            <RAButton onClick={onCancel} label={translate('ra.action.cancel')} />
          </DialogActions>
        </SimpleForm>
      </Dialog>
    </>
  );
});
