const messages = {
  resources: {
    'integration/apis': {
      name: 'API |||| APIs',
      fields: {
        showId: 'Id',
        id: 'Id',
        module: 'Module',
        moduleId: 'Module',
        groupName: 'API Group',
        groupId: 'API Group',
        name: 'Name',
        area: 'Area',
        apiCode: 'Code',
        controller: 'Controller',
        action: 'Action',
        url: 'Url',
        isActive: 'Is Active',
      },
    },
  },
};

export default messages;
