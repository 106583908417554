import { get } from 'lodash';
import * as React from 'react';
import {
  SelectInput, SelectInputProps, useRecordContext,
} from 'react-admin';

interface Props extends SelectInputProps {
  initFunction:(value: string) => void,
}
export default React.memo((props: Props) => {
  const { initFunction, ...rest } = props;
  const record = useRecordContext();
  const value = get(record, props.source ?? '');
  React.useEffect(() => {
    initFunction(value);
  }, []);
  return (
    <SelectInput
      {...rest}
    />
  );
});
