import apiKeyList from './apiKeyList';
import createApiKey from './createApiKey';
import editApiKey from './editApiKey';
import showApiKey from './showApiKey';

export default {
  list: apiKeyList,
  create: createApiKey,
  edit: editApiKey,
  show: showApiKey,
};
