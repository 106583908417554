import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../../repositories/user/accounts';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getInitData() {
  yield takeLatest(actions.getInitData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getInitData);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* lockAccount() {
  yield takeLatest(actions.lockAccount, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.lockAccount, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      // yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* unlockAccount() {
  yield takeLatest(actions.unlockAccount, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.unlockAccount, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      // yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* addToBlackList() {
  yield takeLatest(actions.addToBlackList, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.addToBlackList, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      // yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* removeFromBlackList() {
  yield takeLatest(actions.removeFromBlackList, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.removeFromBlackList, payload.data);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      // yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      // yield put(actions.getInitDataFailure(error));
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getInitData),
    fork(lockAccount),
    fork(unlockAccount),
    fork(addToBlackList),
    fork(removeFromBlackList),
  ]);
}
