import apisList from './apisList';
import createApi from './createApi';
import editApi from './editApi';
import showApi from './showApi';

export default {
  list: apisList,
  create: createApi,
  edit: editApi,
  show: showApi,
};
