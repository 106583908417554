import React from 'react';
import {
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectFaculty({ source }: any) {
  const { faculties } = useSelector((state: any) => state.students);
  return (
    <AutocompleteInput
      source={source}
      choices={faculties ?? []}
      variant="outlined"
      resource="user/students"
    />
  );
}

function SelectCourse({ source }: any) {
  const { courses } = useSelector((state: any) => state.students);
  return (
    <SelectInput
      source={source}
      choices={courses ?? []}
      variant="outlined"
      resource="user/students"
    />
  );
}

function SelectActivityClass({ source }: any) {
  const { activityClasses } = useSelector((state: any) => state.students);
  return (
    <AutocompleteInput
      source={source}
      choices={activityClasses ?? []}
      variant="outlined"
      resource="user/students"
    />
  );
}

function SelectStudentTitle({ source }: any) {
  const { studentTitles } = useSelector((state: any) => state.students);
  return (
    <SelectInput
      source={source}
      choices={studentTitles ?? []}
      variant="outlined"
      resource="user/students"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectFaculty
    source="faculty"
  />,
  <SelectCourse
    source="course"
  />,
  <SelectActivityClass
    source="activityClass"
  />,
  <SelectStudentTitle
    source="studentTitle"
  />,
];
