import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import ActivityClassesArrayField from '../../../components/master/courses/activityClassesArrayField';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_COURSE);
  const canSearch = canAccess(permissions, FUNC.SEARCH_COURSES);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/courses.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.master/courses.fields.description')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="description"
            />
          </Grid>
          <Grid container xs={12} md={12} mt={4} mx={1}>
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              {translate('resources.master/courses.fields.activityClasses')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            md={10}
            marginTop={4}
            sx={{ maxHeight: '500px', overflowY: 'auto', paddingTop: '0px!important' }}
          >
            <ActivityClassesArrayField />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
