const messages = {
  resources: {
    'user/students': {
      name: 'Students |||| Students',
      fields: {
        studentTitle: 'Student Title',
        status: 'Status',
        startPendingAt: 'Start Pending At',
        endPendingAt: 'End Pending At',
        import: 'Import Students',
        roleIds: 'Roles',
        roleName: 'Roles',
        studentInformationLabel: 'Student Information',
        userInformationLabel: 'User Infomation',
        yusInformationLabel: 'Yus Information',
        cpvInformationLabel: 'Cpv Information',
        positionInClass: 'Position in class',
        currentPosition: 'Position in class',
        positionInClassName: 'Position in class',
        language: 'Language',
        currentAddressLabel: 'Current Address',
        birthPlaceLabel: 'Birthplace Address',
        permanentAddressLabel: 'Permanent Address',
        nativeLandLabel: 'Native Land',
        language1: 'Language 1',
        language2: 'Language 2',
        information: {
          firstName: 'First name',
          lastName: 'Last name',
          otherName: 'Other name',
          email: 'Personal email',
          phone: 'Phone number 2',
          identityCardType: 'Identity card type',
          identityCardIssuerId: 'Identity card issuer',
          identityCardIssuerName: 'Identity card issuer',
          identityCardNumber: 'Identity card number',
          identityCardIssueDate: 'Identity card issue date',
          identityCardFront: 'Identity card front',
          theBackOfIdentityCard: 'The back of identity card',
          gender: 'Gender',
          birthday: 'Birthday',
          nationalityId: 'Nationality',
          ethnicityId: 'Ethnicity',
          religion: 'Religion',
          height: 'Height',
          weight: 'Weight',
          candidate: 'Candidate',
          bloodGroup: 'BloodGroup',
          job: 'Job',
          educationalLevel: 'Educational level',
          englishLevel: 'English level',
          computerLevel: 'Computer level',
          bestCertificate: 'Best certificate',
          politicalTheory: 'Political theory',
          qlnn: 'QLNN',
          isWorkFarFromHome: 'Is Work Far From Home',
          healthStatus: 'Health status',
          familyFrom: 'Family From',
          note: 'Note',
          birthPlaceCountryId: 'Birth place country',
          birthPlaceProvinceId: 'Birth place province',
          birthPlaceProvinceName: 'Birth place province',
          currentAddress: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
          nativeLand: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
          permanentAddress: {
            countryId: 'Country',
            detailAddress: 'Detail address',
            communeId: 'Commune',
            districtId: 'District',
            provinceId: 'Province',
          },
          isYusMember: 'Is Yus member',
          isCpvMember: 'Is Cpv',
          cpvDateJoining: 'Cpv date joining',
          cpvPlaceJoining: 'Cpv place joining',
        },
        id: 'id',
        userId: 'User Id',
        username: 'User name',
        email: 'Student Email',
        phone: 'Phone Number 1',
        email2: 'Personal Email',
        phone2: 'Phone Number 2',
        male: 'Male',
        female: 'Female',
        firstName: 'First Name',
        lastName: 'Last name',
        fullName: 'Full name',
        otherName: 'Other name',
        healthStatus: 'Health status',
        height: 'Height',
        weight: 'Weight',
        candidate: 'Candidate',
        bloodGroup: 'BloodGroup',
        job: 'Job',
        educationalLevel: 'Educational level',
        englishLevel: 'English level',
        computerLevel: 'Computer level',
        bestCertificate: 'Best certificate',
        politicalTheory: 'Political theory',
        qlnn: 'Qlnn',
        isWorkFarFromHome: 'Work far from home',
        gender: 'Gender',
        genderName: 'Gender',
        birthday: 'Birthday',
        avatar: 'Avatar',
        cover: 'Cover',
        identityCardNumber: 'Identity card number',
        identityCardIssuer: 'Identity card issuer',
        identityCardIssueDate: 'Identity card issueDate',
        identityCardFront: 'Identity card front',
        theBackOfIdentityCard: 'The back of identity card',
        portraitPhoto: 'Portrait photo',
        nationality: 'Nationality',
        ethicity: 'Ethicity',
        religion: 'Religion',
        isYusMember: 'Yus member',
        taxCode: 'Tax code',
        note: 'Note',
        type: 'Type',
        typeName: 'Type name',
        currentAddress: {
          detailAddress: 'Detail address',
          communeId: 'Commune',
          districtId: 'District',
          provinceId: 'Province',
          communeName: 'Commune',
          districtName: 'District',
          provinceName: 'Province',
        },
        birthPlace: {
          detailAddress: 'Detail address',
          communeId: 'Commune',
          districtId: 'District',
          provinceId: 'Province',
          communeName: 'Commune',
          districtName: 'District',
          provinceName: 'Province',
        },
        permanentAddress: {
          detailAddress: 'Detail address',
          communeId: 'Commune',
          districtId: 'District',
          provinceId: 'Province',
          communeName: 'Commune',
          districtName: 'District',
          provinceName: 'Province',
        },
        activityClassId: 'Activity class',
        activityClassName: 'Activity class',
        classPositions:
          {
            id: 'Id',
            name: 'Name',
            isActive: 'Active',
            from: '',
            to: '',
          },
        facultyId: 'Faculty',
        facultyName: 'Faculty',
        faculty: 'Faculty',
        courseId: 'Course',
        courseName: 'Course',
        course: 'Course',
        majorId: 'Major',
        majorName: 'Major',
        languages:
          {
            id: 'Id',
            name: 'Name',
            order: 'Order',
          },
        yusInformation: {
          status: 'Status',
          isMember: 'Is member',
          isNewMember: 'New member',
          isMovedMember: 'Moved member',
          isTempMember: 'Temp member',
          card: 'Card',
          prepareDate: 'Prepare date',
          officialDate: 'Official date',
          movedDate: 'Moved date',
          yusClassBefore: 'Yus class before',
          isRegisterForYusActivities: 'Register for yus activities',
          placeOfRegisterForYusActivities: 'Place of register for yus activities',
          regisStartDate: 'Registration start date',
          regisEndDate: 'Registration end date',
          dateJoining: 'Date joining',
          placeJoining: 'Place joining',
          positionInYus: 'Position in yus',
          currentPosition: 'Position in yus',
        },
        cpvInformation: {
          status: 'Status',
          isCpvMember: 'Is Cpv member',
          cpvDateJoining: 'Sub date joining',
          cpvRealDateJoining: 'Real date joining',
          cpvPlaceOfActivityId: 'Place of activity',
          cpvPlaceJoining: 'Place joining',
        },
      },
    },
  },
};

export default messages;
