const messages = {
  resources: {
    'master/activity-classes': {
      name: 'Activity Classes |||| Activity Class',
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        courseId: 'Course',
        courseName: 'Course',
        facultyId: 'Faculty',
        facultyName: 'Faculty',
        students: 'Students',
        studentQuantity: 'Students quantity',
        studentFields: {
          studentId: 'Student Code',
          fullName: 'Full Name',
          phone: 'Phone',
        },
      },
    },
  },
};

export default messages;
