import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
} from 'react-admin';
import filters from '../../../components/system/mailLogs/mailLogFilter';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import ResendInput from '../../../components/system/mailLogs/resendInput';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.LOG_EMAIL);
  const canResend = canAccess(permissions, FUNC.LOG_EMAIL);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={false} />}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={false} optimized>
        <DateField source="sendAt" showTime />
        <TextField source="subject" />
        <TextField source="template" />
        <TextField source="statusName" />
        <TextField source="priorityName" />
        <TextField source="fromEmail" />
        <TextField source="toEmail" />
        {canShow && <ShowButton label="" />}
        {canResend && <ResendInput />}
      </Datagrid>
    </List>
  );
});
