export default {
  base64Encode: (str: string | undefined | null) => {
    try {
      if (str === undefined || str === null || str === '') {
        return '';
      }
      let output = btoa(str);
      let endPos = 0;
      for (endPos = output.length; endPos > 0; endPos -= 1) {
        if (output[endPos - 1] !== '=') {
          break;
        }
      }
      const numberPaddingChars = output.length - endPos;
      output = output.replace(/\+/g, '-');
      output = output.replace(/\//g, '_');
      output = output.substring(0, endPos);
      output += numberPaddingChars;
      return output;
    } catch (e: any) {
      console.log(`base64EndCode: ${e.message}`);
    }
    return '';
  },
  base64Decode: (str: string | undefined | null) => {
    try {
      if (str === undefined || str === null || str === '') {
        return '';
      }
      let input = str;
      const numberPadding = parseInt(input.substring(input.length - 1), 10);
      input = input.substring(0, input.length - 1);
      input = input.replace(/-/g, '+');
      input = input.replace(/_/g, '/');
      for (let j = 0; j < numberPadding; j += 1) {
        input += '=';
      }
      input = input.replace(/[^A-Za-z0-9+/=]/g, '');
      const output = atob(input);
      return output;
    } catch (e: any) {
      console.log(`base64Decode: ${e.message}`);
    }
    return '';
  },
};
