import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  roles: [],
  genders: [],
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.roles = action.payload.roles;
      state.genders = action.payload.genders;
    },
    getInitDataFailure: (state) => {
      state.roles = [];
      state.genders = [];
    },
  },
});

const actionsTmp: any = { ...users.actions };
actionsTmp.getInitData = createAction('users/accounts/getInitData');
actionsTmp.lockAccount = createAction('users/accounts/lockAccount');
actionsTmp.unlockAccount = createAction('users/accounts/unlockAccount');
actionsTmp.addToBlackList = createAction('users/accounts/addToBlackList');
actionsTmp.removeFromBlackList = createAction('users/accounts/removeFromBlackList');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default users.reducer;
