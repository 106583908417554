import * as React from 'react';
import {
  SimpleForm, useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LanguageArrayInput from '../../../components/system/settings/languageArrayInput';
import { actions } from '../../../redux/system/settings';
import EditToolBar from '../../../components/commons/editToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SETTINGS);
  const canEdit = canAccess(permissions, FUNC.FOREIGN_LANGUAGE_SETTING);
  const [languages, setLanguages] = useState(null);
  const { data } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();
  useEffect(() => {
    setLanguages(data?.list?.find((item: any) => item.id === 'foreignLanguage')?.value);
  }, [data]);
  const hiddenKeys = ['order'];
  const notify = useNotify();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
  };
  const transform = (val: any) => {
    const list: any[] = [];
    Object.keys(val).forEach((key) => {
      const property = val[key];
      property.forEach((element: any, index: any) => {
        element.order = index + 1;
      });
      const position: { id: string, value: any } = { id: key, value: property };
      list.push(position);
    });
    dispatch(actions.putString({ list, onSuccess }));
  };
  if (!canShow) return null;
  return (
    <SimpleForm toolbar={<EditToolBar isSave={canEdit} />} onSubmit={transform}>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <LanguageArrayInput
              hiddenKeys={hiddenKeys}
              datas={languages}
              source="foreignLanguage"
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
});
