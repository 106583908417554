const messages = {
  resources: {
    'integration/screens': {
      name: 'Screens |||| Screens',
      fields: {
        id: 'Id',
        showId: 'Id',
        moduleId: 'Module',
        name: 'Name',
        isDefault: 'Default screen',
        sort: 'Sort',
        group: 'Group of screen',
        groupId: 'Group of screen',
        listFunctions: 'Functions',
        isActive: 'Active',
        childFunctions: 'Functions of sreen',
        functionCode: 'Code',
        functions: {
          isReturnView: 'Main view',
          id: 'Id',
          showId: 'Id',
        },
      },
    },
  },
};

export default messages;
