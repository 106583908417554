import React from 'react';
import {
  DateInput,
  SelectInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectStatus({ source }: any) {
  const { mailStatus } = useSelector((state: any) => state.logs);
  return (
    <SelectInput
      source={source}
      choices={mailStatus ?? []}
      variant="outlined"
    />
  );
}

function SelectPriority({ source }: any) {
  const { mailPriorities } = useSelector((state: any) => state.logs);
  return (
    <SelectInput
      source={source}
      choices={mailPriorities ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectStatus
    source="status"
  />,
  <SelectPriority
    source="priority"
  />,
  <DateInput
    source="from"
  />,
  <DateInput
    source="to"
  />,
];
