import * as React from 'react';
import {
  required,
  useTranslate,
  TextInput,
  email,
  useNotify,
  Form,
} from 'react-admin';
import {
  Avatar, Box, CardActions, CircularProgress, Button, Typography, Card,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/app';
import background from '../assets/imgs/common/loginBg.jpg';
import logo from '../assets/imgs/common/logo.png';

export default React.memo(() => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const redirectUrl = `${window.location.origin}/forgot-password/reset-password`;
  const [browser, setBrowser] = useState('');
  const [latOfMap, setLatOfMap] = useState('');
  const [longOfMap, setLongOfMap] = useState('');
  const [isSendByIam] = useState(true);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const backLoginPage = () => {
    window.location.href = '/login';
  };
  const getPosition = () => {
    fetch('https://ipinfo.io/json?token=fc7a1a15e4945b')
      .then((res: any) => res.json())
      .then((res: any) => {
        if (res.loc) {
          const loc = res.loc.split(',');
          if (loc.length > 1) {
            setLatOfMap(`${loc[0] ?? ''}`);
            setLongOfMap(`${loc[1] ?? ''}`);
          }
        }
      }).catch(console.log);
  };

  React.useEffect(() => {
    if (navigator) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          setLatOfMap(`${position.coords.latitude ?? ''}`);
          setLongOfMap(`${position.coords.longitude ?? ''}`);
        });
      } else {
        getPosition();
      }
      setBrowser(navigator.userAgent);
    }
  }, []);
  const notify = useNotify();
  const onSuccess = () => {
    setLoading(false);
    if (isSendEmail) {
      notify('dut.msg.send_email_for_reset_password_success', {
        type: 'info',
      });
    }
    setIsSendEmail(true);
  };

  const onError = () => {
    setLoading(false);
    notify('dut.msg.email_not_correct', {
      type: 'error',
    });
  };

  const handleSubmit = (value: any) => {
    setLoading(true);
    const data = {
      ...value,
      isSendByIam,
      browser,
      latOfMap,
      longOfMap,
      redirectUrl,
    };
    const payload = {
      data,
      onSuccess,
      onError,
    };
    dispatch(actions.forgotPassword(payload));
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card
          sx={{
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: '#000',
            opacity: 0.4,
            zIndex: 1,
          }}
        />
        <Card
          sx={{
            minWidth: 300,
            position: 'relative',
            zIndex: 2,
            marginTop: '7em',
            marginBottom: '10em',
          }}
        >
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }} alt="logo" src={logo} variant="square" />
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            DUT IAM
          </Box>
          {!isSendEmail && (
            <>
              <Box sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
              }}
              >
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{
                    margin: '1em',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    color: '#2D333A',
                  }}
                >
                  {translate('dut.reset_your_password')}
                </Typography>
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{ width: '100%' }}
                >
                  {translate('dut.type_email_for_reset_password')}
                </Typography>
                <Box sx={{ marginTop: '1em' }}>
                  <TextInput
                    source="email"
                    validate={[required(), email()]}
                    type="email"
                    fullWidth
                    variant="outlined"
                    helperText={false}
                    inputProps={{ maxLength: 255 }}
                    disabled={loading}
                  />
                </Box>
              </Box>
              <CardActions sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.continue')}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={backLoginPage}
                  color="primary"
                  disabled={loading}
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                  }}
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.back_login_page')}
                </Button>
              </CardActions>

            </>
          )}
          {isSendEmail && (
            <>
              <Box sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
              }}
              >
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{
                    margin: '1em',
                    fontSize: '1.5em',
                    color: '#2D333A',
                  }}
                >
                  {translate('dut.check_your_email')}
                </Typography>
                <Typography
                  variant="body2"
                  display="content"
                  textAlign="center"
                  sx={{ width: '100%' }}
                >
                  {translate('dut.msg.send_email_for_reset_password_success')}
                </Typography>
              </Box>
              <CardActions sx={{
                padding: '0 1em 1em 1em',
                width: '20em',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.resend_email')}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={backLoginPage}
                  color="primary"
                  disabled={loading}
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                  }}
                >
                  {loading && (
                    <CircularProgress size={25} thickness={2} />
                  )}
                  {translate('dut.back_login_page')}
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      </Box>
    </Form>
  );
});
