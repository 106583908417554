const messages = {
  resources: {
    'master/semesters': {
      name: 'Học kỳ |||| Học kỳ',
      fields: {
        id: 'Id',
        name: 'Tên học kỳ',
        description: 'Mô tả',
        semesters: 'Học kỳ',
        scholasticId: 'Năm học',
        scholasticName: 'Năm học',
        startAt: 'Thời gian bắt đầu',
        endAt: 'Thời gian kết thúc',
        isDefault: 'Học kỳ mặc định',
        isEnd: 'Đã kết thúc',
        startYear: 'Năm bắt đầu',
        endYear: 'Năm kết thúc',
      },
    },
  },
};

export default messages;
