import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  faculties: [],
  courses: [],
  activityClasses: [],
  majors: [],
  languages: [],
  studentTitles: [],
  positionsInClass: [],
  positionsInYus: [],
  genders: [],
  provinces: [],
  countries: [],
  ethnicities: [],
  religions: [],
  roles: [],
  studentStatus: [],
  identityTypes: [],
  studentEmailDomain: '',
  cCCDCardIssuer: '',
  cpvPlacesOfActivity: [],
  cpvMemberType: [],
};

export const students = createSlice({
  name: 'students',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.faculties = action.payload.faculties;
      state.courses = action.payload.courses;
      state.activityClasses = action.payload.activityClasses;
      state.majors = action.payload.majors;
      state.languages = action.payload.languages;
      state.studentTitles = action.payload.studentTitles;
      state.positionsInClass = action.payload.positionsInClass;
      state.positionsInYus = action.payload.positionsInYus;
      state.genders = action.payload.genders;
      state.provinces = action.payload.provinces;
      state.countries = action.payload.countries;
      state.ethnicities = action.payload.ethnicities;
      state.religions = action.payload.religions;
      state.roles = action.payload.roles;
      state.studentStatus = action.payload.studentStatus;
      state.identityTypes = action.payload.identityTypes;
      state.studentEmailDomain = action.payload.studentEmailDomain;
      state.cCCDCardIssuer = action.payload.cccdCardIssuer;
      state.cpvPlacesOfActivity = action.payload.cpvPlacesOfActivity;
      state.cpvMemberType = action.payload.cpvMemberType;
    },
    getInitDataFailure: (state) => {
      state.faculties = [];
      state.courses = [];
      state.activityClasses = [];
      state.majors = [];
      state.languages = [];
      state.studentTitles = [];
      state.positionsInClass = [];
      state.positionsInYus = [];
      state.genders = [];
      state.provinces = [];
      state.countries = [];
      state.ethnicities = [];
      state.religions = [];
      state.roles = [];
      state.studentStatus = [];
      state.identityTypes = [];
      state.studentEmailDomain = '';
      state.cCCDCardIssuer = '';
      state.cpvPlacesOfActivity = [];
      state.cpvMemberType = [];
    },
    addEthnicitySuccess: (state, action) => {
      state.ethnicities = action.payload.ethnicities;
    },
    addReligionSuccess: (state, action) => {
      state.religions = action.payload.religions;
    },
    addCpvPlaceOfActivitySuccess: (state, action) => {
      state.cpvPlacesOfActivity = action.payload.cpvPlacesOfActivity;
    },
    addEthnicityFailure: () => {
    },
  },
});

const actionsTmp: any = { ...students.actions };
actionsTmp.getInitData = createAction('students/students/getInitData');
actionsTmp.addEthnicity = createAction('students/students/addEthnicity');
actionsTmp.addReligion = createAction('students/students/addReligion');
actionsTmp.addCpvPlaceOfActivity = createAction('students/students/addCpvPlaceOfActivity');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default students.reducer;
