/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';

import {
  TextInput,
  useTranslate,
} from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

function CustomSearchInput({ source, placeholder }: any) {
  const translate = useTranslate();

  return (
    <TextInput
      label={translate(`dut.${placeholder}`)}
      source={source}
      variant="outlined"
      resettable
      inputProps={{ maxLength: 250 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default CustomSearchInput;
