import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import StudentListShow from '../../../components/subject/subjectClasses/studentListShow';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const translate = useTranslate();
  const canEdit = canAccess(permissions, FUNC.EDIT_SUBJECT_CLASS);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SUBJECT_CLASSES);
  return (
    <Show
      actions={<ShowActions isEdit={canEdit} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.name')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.subjectName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="subjectName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.subjectClassCode')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="subjectClassCode"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.typeName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="typeName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.teacherName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="teacherName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.scholasticName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="scholasticName"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label={translate('resources.subject/subject-classes.fields.semesterName')} />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="semesterName"
            />
          </Grid>
          <Grid container paddingX={12} spacing={2} rowSpacing={0.5}>
            <Grid item xs={12}>
              <StudentListShow />
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
