import { AuthProvider } from 'react-admin';
import store from './redux/store';
import { actions as appActions } from './redux/app';
import repositories from './repositories/login';
import profileRepositories from './repositories/profile';

const authProvider: AuthProvider = {
  login: (params) => repositories.login(params).then((res) => {
    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText);
    }
    const { data } = res;
    localStorage.removeItem('beforeUrl');
    localStorage.setItem('token', data.data.token);
    localStorage.setItem('refresh_token', data.data.refreshToken);
    localStorage.setItem(
      'timeout',
      `${new Date(
        new Date().getTime() + parseInt(data.data.expires, 10) * 60000,
      )}`,
    );
    if (data.data.tokenIAM) {
      localStorage.setItem('tokenIAM', data.data.tokenIAM);
    }
    if (data?.data?.redirectUrl) {
      window.location = data.data.redirectUrl;
      return Promise.reject();
    }
    store.dispatch(appActions.getAppState({}));
    let beforeUrl = localStorage.getItem('beforeUrl');
    if (beforeUrl === undefined || beforeUrl === null || beforeUrl === '' || beforeUrl === '/login') {
      beforeUrl = '/';
    }
    return Promise.resolve({ redirectTo: beforeUrl });
  }),
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenIAM');
    localStorage.removeItem('timeout');
    localStorage.removeItem('refresh_token');
    localStorage.setItem('getProfile', '0');
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem('token')) {
      const now = new Date();
      const timeout = new Date(`${localStorage.getItem('timeout')}`);
      if (timeout >= now) {
        return Promise.resolve();
      }
    }
    localStorage.setItem('beforeUrl', window.location.pathname);
    localStorage.removeItem('token');
    localStorage.removeItem('timeout');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('tokenIAM');
    return Promise.reject();
  },
  getIdentity: () => {
    const getProfile = localStorage.getItem('getProfile');
    if (getProfile === '1') {
      const profile = localStorage.getItem('profile');
      if (profile) {
        const state = JSON.parse(profile);
        return Promise.resolve({
          id: state?.id,
          fullName: state?.username,
          avatar: state?.avatar,
        });
      }
      return Promise.resolve({
        id: 0,
        fullName: '',
        avatar: '',
      });
    }
    store.dispatch(appActions.noLoading(true));
    localStorage.setItem('getProfile', '1');
    return profileRepositories.getProfileBase().then((res) => {
      store.dispatch(appActions.noLoading(false));
      const { data } = res;
      localStorage.setItem('profile', JSON.stringify(data));
      return Promise.resolve({
        id: data?.id,
        fullName: data?.username,
        avatar: data?.avatar,
      });
    });

    // return Promise.resolve({
    //   id: 0,
    //   fullName: '',
    //   avatar: '',
    // });
  },
  checkError: () => Promise.resolve(),
  getPermissions: () => {
    const appState = localStorage.getItem('appState');
    if (appState) {
      const state = JSON.parse(appState);
      return Promise.resolve(state?.permissions ?? []);
    }
    return Promise.resolve([]);
  },
};

export default authProvider;
