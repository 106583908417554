import repositories from '..';

const resource = '/user/accounts';

export default {
  getInitData: () => repositories.get(`${resource}/data/init`),
  lockAccount: (data: any) => repositories.put(`${resource}/${data.id}/lock`),
  unlockAccount: (data: any) => repositories.put(`${resource}/${data.id}/unlock`),
  addToBlackList: (data: any) => repositories.put(`${resource}/${data.id}/add-to-black-list`),
  removeFromBlackList: (data: any) => repositories.put(`${resource}/${data.id}/remove-from-black-list`),
};
