const messages = {
  resources: {
    'system/settings': {
      name: 'Setting |||| Settings',
      position: 'Positions',
      mail: 'Mail system',
      language: 'Foreign languages',
      typeStudent: 'Type of students',
      typeCadres: 'Type of cadres',
      blobStorage: 'Blog Storage',
      other: 'Other',
      fields: {
        id: 'Id',
        name: 'Name',
        positionInClass: 'Position in activity class',
        positionInFaculty: 'Position in faculty',
        positionInMajor: 'Position in major',
        positionInYus: 'Position in young union',
        positionInCpv: 'Position in communist party',
        positionInDepartment: 'Position in department',
        foreignLanguage: 'Foreign languages',
        typeOfStudent: 'Type of students',
        typeOfEmployee: 'Type of employees',
        typeOfTeacher: 'Type of teachers',
        mailName: 'Name',
        mailer: 'Type',
        mailStmpAuth: 'Encription method',
        mailHost: 'Server',
        mailPort: 'Port',
        mailUser: 'User',
        mailPassword: 'Passwork',
        mailFrom: 'Mail from',
        mailReceived: 'Mail received',
        cccdIdentityCardIssuer: 'CCCD Identity Card Issuer',
        cnd: 'CDN',
        mediaUrl: 'Server file',
        religion: 'Religions',
        studentEmailDomain: 'Student Email Domain',
        bucketName: 'Bucket name',
        endpoint: 'Endpoint',
        accessKey: 'Access key',
        secretKey: 'Secret key',
      },
    },
  },
};

export default messages;
