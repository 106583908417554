/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_MENU_ITEM);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MENU_ITEMS);
  return (
    <Show
      actions={<ShowActions isEdit={canPut} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.showId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              source="showId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.moduleId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="moduleId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.name" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.showParentId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="showParentId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.showFunctionId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="showFunctionId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.icon" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="icon"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.router" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="router"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.level" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="level"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/menu/items.fields.sort" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="sort"
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
