import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/integration/screenGroups/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_COURSE);
  const canShow = canAccess(permissions, FUNC.SHOW_COURSE_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_COURSE);
  const canDelete = canAccess(permissions, FUNC.DELETE_COURSE);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canAdd} />}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="name" textAlign="center" />
        <TextField source="description" textAlign="center" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
