import React from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormGroup,
  FormControlLabel,
  TableRow,
  Paper,
  Grid,
  Checkbox,
} from '@mui/material';

export default React.memo(({ ...props }: any) => {
  const translate = useTranslate();
  const render = (data: any[]) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="table-permission">
            <TableBody>
              {(data ?? []).map((screen: any) => (
                <React.Fragment key={screen.id}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className="row-screen"
                  >
                    <TableCell component="th" scope="row">
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={screen.isEnabledAll}
                            />
                          )}
                          label={translate(screen.name)}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className="row-function"
                  >
                    <TableCell scope="row">
                      <Grid container spacing={2}>
                        {screen.functions.map((func: any) => (
                          <Grid item xs={3} key={func.id}>
                            <FormGroup>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={func.isEnabled}
                                  />
                                )}
                                label={translate(func.name)}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
  return (
    <FunctionField
      {...props}
      render={(record: any, source: string) => render(record[source])}
    />
  );
});
