import teacherList from './teacherList';
import createTeacher from './createTeacher';
import editTeacher from './editTeacher';

export default {
  list: teacherList,
  create: createTeacher,
  edit: editTeacher,
  show: editTeacher,
};
