import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField,
  DateField,
} from 'react-admin';
import filters from '../../../components/master/semesters/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);

  const canEdit = canAccess(permissions, FUNC.EDIT_SEMESTER);
  const canShow = canAccess(permissions, FUNC.SHOW_SEMESTER_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_SEMESTER);
  const canDelete = canAccess(permissions, FUNC.DELETE_SEMESTER);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canAdd} />}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="scholasticName" />
        <TextField source="name" />
        <DateField locales="en-GB" source="startAt" />
        <DateField locales="en-GB" source="endAt" />
        <BooleanField source="isDefault" />
        <BooleanField source="isEnd" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
