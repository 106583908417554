import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLogout } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { actions } from '../../redux/app';

export default function () {
  const dispatch = useDispatch();
  const logout = useLogout();
  const location = useLocation().search;
  const redirectUrl = new URLSearchParams(location).get('redirectUrl');
  useEffect(() => {
    dispatch(actions.logout({
      onSuccess: () => {
        logout();
        window.location.href = redirectUrl || '/login';
      },
    }));
  }, []);
  return (
    <div />
  );
}
