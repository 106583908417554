import {
  all, takeLatest, put, call, fork,
} from 'redux-saga/effects';
import { actions } from '.';
import repositories from '../../../repositories/user/students';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getInitData() {
  yield takeLatest(actions.getInitData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.getInitData, data.payload);
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(response.data);
      }
      yield put(actions.getInitDataSuccess(response.data));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(actions.getInitDataFailure(error));
    }
  });
}

export function* addEthnicity() {
  yield takeLatest(actions.addEthnicity, function* (data:any) {
    const { payload } = data;
    try {
      yield put(actions.addEthnicitySuccess(payload));
    } catch (error) {
      yield put(actions.addEthnicityFailure(error));
    }
  });
}

export function* addReligion() {
  yield takeLatest(actions.addReligion, function* (data:any) {
    const { payload } = data;
    try {
      yield put(actions.addReligionSuccess(payload));
    } catch (error) {
      yield put(actions.addEthnicityFailure(error));
    }
  });
}

export function* addCpvPlaceOfActivity() {
  yield takeLatest(actions.addCpvPlaceOfActivity, function* (data:any) {
    const { payload } = data;
    try {
      yield put(actions.addCpvPlaceOfActivitySuccess(payload));
    } catch (error) {
      yield put(actions.addEthnicityFailure(error));
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getInitData),
    fork(addEthnicity),
    fork(addReligion),
    fork(addCpvPlaceOfActivity),
  ]);
}
