import * as React from 'react';
import Button from '@mui/material/Button';
import BlockIcon from '@mui/icons-material/Block';
import HistoryIcon from '@mui/icons-material/History';
import { Title, useTranslate } from 'react-admin';

export default function (props: any) {
  const translate = useTranslate();
  const { title } = props;
  const goHome = () => {
    window.location.href = '/';
  };
  return (
    <div className="no-access">
      <Title defaultTitle={title} />
      <div className="no-access-message">
        <BlockIcon className="no-access-icon" />
        <h1>{translate('ra.page.no_access')}</h1>
        <div>
          {translate('ra.message.no_access')}
          .
        </div>
      </div>
      <div className="no-access-toolbar">
        <Button
          variant="contained"
          startIcon={<HistoryIcon />}
          onClick={goHome}
        >
          {translate('ra.action.home')}
        </Button>
      </div>
    </div>
  );
}
