import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  Title,
  Toolbar,
  SaveButton,
  required,
  useTranslate,
  regex,
  PasswordInput,
} from 'react-admin';
import { Grid } from '@mui/material';

export default React.memo((props: any) => {
  const { profile, genders } = useSelector((state: any) => state.profile);
  const translate = useTranslate();
  const { onSave } = props;
  const saveProfile = (data: any) => {
    if (typeof onSave === 'function') {
      onSave(data);
    }
  };
  return (
    <div className="dut-form">
      <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>} record={profile} onSubmit={saveProfile}>
        <Title title={translate('dut.profile')} />
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="username"
              label={translate('dut.username')}
              fullWidth
              variant="outlined"
              helperText={false}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <PasswordInput
              source="wifiPassword"
              label={translate('dut.wifiPassword')}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="firstName"
              label={translate('dut.firstName')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="lastName"
              label={translate('dut.lastName')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="email"
              label={translate('dut.email')}
              type="email"
              fullWidth
              variant="outlined"
              helperText={false}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="phone"
              label={translate('dut.phone')}
              fullWidth
              variant="outlined"
              helperText={false}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="email2"
              label={translate('dut.email2')}
              type="email"
              fullWidth
              inputProps={{ maxLength: 255 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="phone2"
              label={translate('dut.phone2')}
              fullWidth
              inputProps={{ maxLength: 15 }}
              validate={[
                regex(
                  /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                  'dut.msg.phone_number_format_invalidate',
                ),
              ]}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              source="gender"
              label={translate('dut.gender')}
              choices={genders ?? []}
              emptyValue="0"
              fullWidth
              optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <DateInput
              source="birthday"
              label={translate('dut.birthday')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="nationality"
              label={translate('dut.nationality')}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              source="ethicity"
              label={translate('dut.ethicity')}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={12} md={5}>
            <TextInput
              source="religion"
              label={translate('dut.religion')}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </div>
  );
});
