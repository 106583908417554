import * as React from 'react';
import {
  Button,
  Confirm,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/subject/subjectClasses';

interface IDeleteStudentButtonProps {
  getStudents: (() => void),
}
export default React.memo((props: IDeleteStudentButtonProps) => {
  const { getStudents } = props;
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();
  const { id } = useParams();
  const record = useRecordContext();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenConfirmButton = () => setOpenDialog(true);
  const handleCloseConfirmButton = () => setOpenDialog(false);
  const onClickRemoveStudents = () => {
    dispatch(
      actions.removeStudentsFromSubjectClass(
        {
          studentIds: [record?.id],
          id,
          onSuccess: (response: any) => {
            const { data: { removeCount } } = response;
            notify(
              translate('dut.removeStudentSuccess'),
              {
                type: 'success',
                autoHideDuration: 1000,
                messageArgs: {
                  smart_count: removeCount,
                },
              },
            );
            getStudents();
            setOpenDialog(false);
          },
          onFailed: () => {
            setOpenDialog(false);
          },
          callback: () => {
            setOpenDialog(false);
          },
        },
      ),
    );
  };

  if (!record) {
    return null;
  }

  return (
    <>
      <Button
        className="parentPaddingNone"
        label=""
        onClick={handleOpenConfirmButton}
        sx={{ color: 'red' }}
      >
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={openDialog}
        title={translate('dut.removeStudentTitle')}
        content={translate('dut.removeStudentContentDialog', { count: 1 })}
        onConfirm={onClickRemoveStudents}
        onClose={handleCloseConfirmButton}
      />
    </>
  );
});
