import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Typography } from '@mui/material';

export default React.memo((props:any) => {
  const translate = useTranslate();
  const { className, label } = props;
  return (
    <Typography
      className={className}
      variant="body2"
      display="content"
      textAlign="left"
      sx={{ color: 'gray' }}
    >
      {translate(label)}
      :
    </Typography>
  );
});
