import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default React.memo((
  props: LinearProgressProps & { value: number },
) => {
  const { value } = props;
  return (
    <div className="dut-loading">
      <div className="dut-loading-bg" />
      <Box className="dut-linear-progress-bar">
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(
              value,
            )}%`}

          </Typography>
        </Box>
      </Box>
    </div>
  );
});
