import { Grid } from '@mui/material';
import * as React from 'react';
import { SelectInput, TextInput } from 'react-admin';
import { IDENTITY_TYPE } from '../../../utils/constant';

export default React.memo((props: any) => {
  const { identityCardType, provinces, cCCDCardIssuer } = props;
  if (!identityCardType) {
    return <Grid item xs={4} md={4} />;
  }
  if (identityCardType === IDENTITY_TYPE.CMND) {
    return (
      <Grid item xs={4} md={4}>
        <SelectInput
          source="information.identityCardIssuerId"
          choices={provinces ?? []}
          variant="outlined"
          helperText={false}
          fullWidth
        />
      </Grid>
    );
  }
  if (identityCardType === IDENTITY_TYPE.CCCD) {
    return (
      <Grid item xs={4} md={4}>
        <TextInput
          source="information.identityCardIssuerName"
          variant="outlined"
          helperText={false}
          fullWidth
          disabled
          format={() => cCCDCardIssuer}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={4} md={4}>
      <TextInput
        source="information.identityCardIssuerName"
        variant="outlined"
        helperText={false}
        fullWidth
        inputProps={{ maxLength: 100 }}
      />
    </Grid>
  );
});
