import moduleList from './moduleList';
import createModule from './createModule';
import editModule from './editModule';
import showModule from './showModule';

export default {
  list: moduleList,
  create: createModule,
  edit: editModule,
  show: showModule,
};
