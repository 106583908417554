/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  TextInput,
  required,
  maxLength,
  SelectInput,
  FormTab,
  TabbedForm,
  useRecordContext,
  Edit,
  regex,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import FUNC from '../../../utils/functions';
import canAccess from '../../../utils/canAccess';
import ApiKeySelectInput from '../../../components/integration/webhooks/apiKeySelectInput';
import WebhookHistoryList from '../../../components/integration/webhooks/webhookHistoryList';

function WebhookForm(props: any) {
  const record = useRecordContext(props);
  console.log('xxx', record);
  const {
    modules, triggers, status,
  } = useSelector((state: any) => state.webhooks);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_WEBHOOK);
  const canDelete = canAccess(permissions, FUNC.DELETE_WEBHOOK);
  return (
    <TabbedForm toolbar={<EditToolBar isSave={canEdit} isDelete={canDelete} />}>
      <FormTab label="resources.integration/webhooks.name">
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              disabled
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <ApiKeySelectInput
              source="apiKeyId"
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <SelectInput
              fullWidth
              variant="outlined"
              choices={triggers ?? []}
              emptyValue=""
              optionText={(choice) => choice.name}
              source="triggerName"
              validate={[
                required(),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              fullWidth
              variant="outlined"
              choices={status ?? []}
              emptyValue=""
              optionText={(choice) => choice.name}
              source="status"
              validate={[
                required(),
              ]}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="url"
              validate={[
                maxLength(200),
                regex(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, 'dut.msg.url_format_invalidate'),
              ]}
            />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </FormTab>
      <FormTab label="resources.integration/webhooks.fields.histories.name">
        <WebhookHistoryList />
      </FormTab>
    </TabbedForm>
  );
}

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canSearch = canAccess(permissions, FUNC.SEARCH_WEBHOOK);
  const canShow = canAccess(permissions, FUNC.SHOW_WEBHOOK_DETAIL);
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <WebhookForm
        actions={<EditActions isShow={canShow} isList={canSearch} />}
        mutationMode="pessimistic"
      />
    </Edit>
  );
});
