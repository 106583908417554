const messages = {
  resources: {
    'user/accounts': {
      name: 'User |||| Users',
      fields: {
        fullName: 'Full name',
        firstName: 'First name',
        lastName: 'Last name',
        username: 'Username',
        email: 'Email',
        phone: 'Phone',
        Phone: 'Phone',
        lastLogin: 'Last login',
        lastLogout: 'Last logout',
        isActive: 'Active',
        isVerifyEmail: 'Verify email',
        status: 'Status',
        Group: 'Group',
        Query: 'Search',
        groups: 'Groups',
        gender: 'Gender',
        genderName: 'Gender',
        birthday: 'Date of birth',
        address: 'Address',
        note: 'Note',
        password: 'Password',
        confirmPassword: 'Confirm password',
        Role: 'Role',
        roleIds: 'Roles',
        statusName: 'Status',
        taxCode: 'Tax code',
        nationality: 'Nationality',
        ethicity: 'Ethnicity',
        religion: 'Religion',
        wifiPassword: 'Wifi Password',
      },
    },
  },
  dut: {
    lockAccount: 'Lock',
    unlockAccount: 'UnLock',
    addToBlackList: 'Add to blacklist',
    removeFromBlackList: 'Remove from blacklist',
  },
};

export default messages;
