import React from 'react';
import { SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';

function SelectModule({ source }: any) {
  const { modules } = useSelector((state: any) => state.screenGroups);
  return (
    <SelectInput
      source={source}
      choices={modules ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectModule
    source="moduleId"
  />,
];
