import courseList from './courseList';
import createCourse from './createCourse';
import editCourse from './editCourse';
import showCourse from './showCourse';

export default {
  list: courseList,
  create: createCourse,
  edit: editCourse,
  show: showCourse,
};
