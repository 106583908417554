import * as React from 'react';
import {
  TextInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  SelectInput,
  required,
  AutocompleteInput,
  minValue,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/user/students';
import { CPV_MEMBER_TYPE } from '../../utils/constant';
import sharedFunction from '../../utils/sharedFunction';

export default React.memo((props: any) => {
  const dispatch = useDispatch();
  const {
    isTeachers,
  } = props;
  const selectedState = isTeachers ? (state: any) => state.teachers : (state: any) => state.students;
  const {
    cpvPlacesOfActivity,
    cpvMemberType,
  } = useSelector(selectedState);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={4}>
            <BooleanInput
              source="cpvInformation.isCpvMember"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
          {formData.cpvInformation?.isCpvMember
            && (
              <>
                <Grid item xs={4} md={4}>
                  <DateInput
                    source="cpvInformation.cpvDateJoining"
                    variant="outlined"
                    helperText={false}
                    validate={required()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <AutocompleteInput
                    source="cpvInformation.cpvPlaceOfActivityId"
                    choices={cpvPlacesOfActivity ?? []}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                    onCreate={(filter) => {
                      const newPlaceOfActivity = {
                        id: filter ?? '',
                        name: filter ?? '',
                      };
                      dispatch(actions
                        .addCpvPlaceOfActivity({ cpvPlacesOfActivity: [...cpvPlacesOfActivity, newPlaceOfActivity] }));
                      return newPlaceOfActivity;
                    }}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <SelectInput
                    source="cpvInformation.status"
                    choices={cpvMemberType ?? []}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                    validate={required()}
                  />
                </Grid>
                {formData.cpvInformation?.status === CPV_MEMBER_TYPE.OFFICIAL
                  && (
                    <Grid item xs={4} md={4}>
                      <DateInput
                        source="cpvInformation.cpvRealDateJoining"
                        parse={sharedFunction.dateParse}
                        variant="outlined"
                        helperText={false}
                        fullWidth
                        validate={minValue(formData.cpvInformation?.cpvDateJoining ?? null)}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="cpvInformation.cpvPlaceJoining"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
              </>
            )}
        </Grid>
      )}
    </FormDataConsumer>
  );
});
