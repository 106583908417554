import * as React from 'react';
import { FunctionField } from 'react-admin';

export default React.memo((props: any) => {
  const { groups } = props;
  return (
    <FunctionField
      render={(record:any) => {
        const filter = groups?.filter((group:any) => group.id === record.groupId);
        if (filter[0]) {
          return filter[0].name;
        }
        return record.groupId;
      }}
    />
  );
});
