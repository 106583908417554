const messages = {
  resources: {
    'system/logs/mail': {
      name: 'Mail Log |||| Mail Logs',
      fields: {
        id: 'Id',
        sendAt: 'Gửi lúc',
        subject: 'Tiêu đề',
        template: 'Bản mẫu',
        fromEmail: 'Gửi từ',
        toEmail: 'Gửi đến',
        mailError: 'Tên lỗi',
        numberOfError: 'Số lần lỗi',
        statusName: 'Trạng thái',
        priorityName: 'Độ ưu tiên',
        model: 'Dữ liệu',
        body: 'Nội dung',
      },
    },
  },
};

export default messages;
