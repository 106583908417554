/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  BooleanField,
  Show,
  SimpleShowLayout,
  TextField,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowLabel from '../../../components/commons/showLabel';
import ShowActions from '../../../components/commons/showActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import CustomedArrayField from '../../../components/integration/screens/customedArrayField';
import repositories from '../../../repositories/integration/screens';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_SCREEN);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCREENS);
  const canShowFunction = canAccess(permissions, FUNC.SHOW_FUNCTION_DETAIL);
  const canPutFunction = canAccess(permissions, FUNC.EDIT_FUNCTION);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const deleteFunctionInScreen = (id:string, functionId:string) => {
    repositories.deleteAfunctionInscreen({ id, functionId }).then(() => {
      notify(translate('dut.deleted'), { type: 'success' });
      refresh();
    });
  };
  return (
    <Show
      actions={<ShowActions isEdit={canPut} isList={canSearch} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.id" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              source="showId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.moduleId" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="moduleId"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.name" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.isDefault" />
          </Grid>
          <Grid item xs={8} md={10}>
            <BooleanField
              fullWidth
              source="isDefault"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.group" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="group.name"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.sort" />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              source="sort"
            />
          </Grid>
          <Grid item xs={1} md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.integration/screens.fields.childFunctions" />
          </Grid>
          <CustomedArrayField
            deleteHandle={deleteFunctionInScreen}
            sourceScreenId="id"
            canShowFunction={canShowFunction}
            canPutFunction={canPutFunction}
            canPut={canPut}
          />
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
});
