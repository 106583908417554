import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import { useSelector } from 'react-redux';
import filters from '../../../components/integration/apis/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_API);
  const canShow = canAccess(permissions, FUNC.SHOW_API_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_API);
  const canDelete = canAccess(permissions, FUNC.DELETE_API);

  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canAdd} />}
      pagination={<PostPagination rowsPerPageOptions={[10, 20, 50, 100]} />}
      className="apiTable"
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="showId" />
        <TextField source="moduleId" />
        <TextField source="groupName" />
        <TextField source="apiCode" />
        <TextField source="name" />
        <TextField source="area" />
        <TextField source="controller" />
        <TextField source="action" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
