const messages = {
  resources: {
    'user/teachers': {
      name: 'Giảng viên |||| Giảng viên',
      fields: {
        import: 'Nhập giảng viên',
        teacherInformationLabel: 'Thông tin giảng viên',
        userInformationLabel: 'Thông tin cá nhân',
        yusInformationLabel: 'Thông tin đoàn viên',
        cpvInformationLabel: 'Thông tin đảng viên',
        language1: 'Ngoại ngữ 1',
        language2: 'Ngoại ngữ 2',
        id: 'MSSV',
        teacherCode: 'Mã',
        userId: 'Mã người dùng',
        username: 'MSGV',
        teacherNote: 'Ghi chú giảng viên',
        password: 'Mật khẩu',
        confirmPassword: 'Xác nhận mật khẩu',
        email: 'Email giảng viên',
        phone: 'SĐT chính',
        email2: 'Email cá nhân',
        phone2: 'SĐT khác',
        male: 'Nam',
        female: 'Nữ',
        firstName: 'Tên',
        lastName: 'Họ và tên đệm',
        fullName: 'Họ tên',
        otherName: 'Biệt danh',
        healthStatus: 'Tình trạng sức khỏe',
        height: 'Chiều cao',
        weight: 'Cân nặng',
        candidate: 'Ứng viên',
        bloodGroup: 'Nhóm máu',
        job: 'Công việc',
        educationalLevel: 'Trình độ học vấn',
        englishLevel: 'Trình độ tiếng anh',
        computerLevel: 'Trình độ tin học',
        bestCertificate: 'Chứng chỉ cao nhất',
        politicalTheory: 'Lý luận chính trị',
        qlnn: 'QlNN',
        isWorkFarFromHome: 'Đi làm xa',
        gender: 'Giới tính',
        genderName: 'Giới tính',
        birthday: 'Ngày sinh',
        avatar: 'Ảnh thẻ',
        cover: 'Cover',
        portraitPhoto: 'Ảnh chân dung',
        nationality: 'Quốc gia',
        ethicity: 'Dân tộc',
        religion: 'Tôn giáo',
        cpvDateJoining: 'Ngày tham gia Đảng',
        cpvPlaceJoining: 'Nơi tham gia Đảng',
        taxCode: 'Mã số thuế',
        note: 'Ghi chú',
        type: 'Loại giảng viên',
        typeName: 'Loại giảng viên',
        userInfoNote: 'Ghi chú thông tin',
        activityClass: 'Chi đoàn lớp',
        activityClassId: 'Chi đoàn lớp',
        activityClassName: 'Chi đoàn lớp',
        currentAddressLabel: 'Nơi ở hiện nay',
        nativeLandLabel: 'Quê quán',
        birthPlaceLabel: 'Nơi sinh',
        permanentAddressLabel: 'Địa chỉ thường trú',
        facultyId: 'Khoa',
        facultyName: 'Khoa',
        faculty: 'Khoa',
        teachingFaculties: 'Khoa giảng dạy',
        listDepartments: 'Phòng ban công tác',
        roleIds: 'Nhóm quyền',
        faculties: {
          id: 'Khoa',
          name: 'Khoa',
          faculty: 'Khoa',
          positions: {
            id: 'Mã',
            label: 'Chức vụ trong khoa',
            name: 'Chức vụ',
            isActive: 'Hoạt động',
            from: 'Ngày bắt đầu',
            to: 'Ngày kết thúc',
          },
        },
        majors: {
          id: 'Chuyên ngành',
          major: 'Chuyên ngành',
          name: 'Chuyên ngành',
          positions:
              {
                id: 'Mã',
                label: 'Chức vụ trong chuyên ngành',
                name: 'Chức vụ',
                isActive: 'Hoạt động',
                from: 'Ngày bắt đầu',
                to: 'Ngày kết thúc',
              },
        },
        languages:
          {
            id: 'Mã',
            name: 'Tên',
            order: 'Thứ tự',
          },
        information: {
          firstName: 'Tên',
          lastName: 'Họ và tên đệm',
          otherName: 'Biệt danh',
          email: 'Email cá nhân',
          phone: 'SĐT khác',
          identityCardType: 'Loại giấy tờ',
          identityCardNumber: 'Số',
          identityCardIssuerId: 'Cơ quan cấp',
          identityCardIssuerName: 'Cơ quan cấp',
          identityCardIssueDate: 'Ngày cấp',
          identityCardFront: 'Mặt trước CMND/CCCD',
          theBackOfIdentityCard: 'Mặt sau CMND/CCCD',
          gender: 'Giới tính',
          birthday: 'Ngày sinh',
          nationalityId: 'Quốc tịch',
          ethnicityId: 'Dân tộc',
          religion: 'Tôn giáo',
          height: 'Chiều cao',
          weight: 'Cân nặng',
          bloodGroup: 'Nhóm máu',
          job: 'Công việc',
          educationalLevel: 'Trình độ học vấn',
          englishLevel: 'Trình độ tiếng anh',
          computerLevel: 'Trình độ tin học',
          bestCertificate: 'Chứng chỉ cao nhất',
          politicalTheory: 'Lý luận chính trị',
          qlnn: 'QlNN',
          isWorkFarFromHome: 'Đi làm xa',
          healthStatus: 'Tình trạng sức khoẻ',
          familyFrom: 'Thành phần xuất thân',
          note: 'Ghi chú',
          isYusMember: 'Là Đoàn viên',
          language1: 'Ngoại ngữ 1',
          language2: 'Ngoại ngữ 2',
          birthPlaceCountryId: 'Quốc gia sinh',
          birthPlaceProvinceId: 'Tỉnh/Thành phố',
          birthPlaceProvinceName: 'Tỉnh/Thành phố',
          currentAddress: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
          nativeLand: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
          permanentAddress: {
            countryId: 'Quốc gia',
            detailAddress: 'Địa chỉ cụ thể',
            communeId: 'Phường/xã',
            districtId: 'Quận/huyện',
            provinceId: 'Tỉnh/Thành phố',
          },
        },
        departments: {
          id: 'Phòng ban',
          name: 'Phòng ban',
          department: 'Phòng ban',
          positions: {
            id: 'Mã',
            label: 'Chức vụ trong phòng ban',
            name: 'Chức vụ',
            isActive: 'Hoạt động',
            from: 'Ngày bắt đầu',
            to: 'Ngày kết thúc',
          },
        },
        yusInformation: {
          status: 'Trạng thái Đoàn viên',
          isMember: 'Là đoàn viên',
          isNewMember: 'Đoàn viên mới',
          isMovedMember: 'Đoàn viên chuyển đến',
          isTempMember: 'Đoàn viên hoạt động tạm thời',
          card: 'Thẻ Đoàn viên',
          prepareDate: 'Ngày vào Đoàn dự bị',
          officialDate: 'Ngày vào đoàn chính thức',
          movedDate: 'Ngày chuyển đến',
          yusClassBefore: 'Chi đoàn trước',
          isRegisterForYusActivities: 'Đăng ký chuyển hoạt động Đoàn',
          placeOfRegisterForYusActivities: 'Nơi đăng ký hoạt động Đoàn',
          regisStartDate: 'Ngày bắt đầu đăng ký',
          regisEndDate: 'Ngày kết thúc đăng ký',
          dateJoining: 'Ngày tham gia Đoàn',
          placeJoining: 'Nơi tham gia Đoàn',
          currentPosition: 'Chức vụ trong đoàn',
          positionInYusId: 'Chức vụ trong đoàn',
        },
        cpvInformation: {
          status: 'Trạng thái Đảng viên',
          isCpvMember: 'Là Đảng viên',
          cpvDateJoining: 'Ngày vào Đảng dự bị',
          cpvRealDateJoining: 'Ngày vào Đảng chính thức',
          cpvPlaceOfActivityId: 'Chi bộ Đảng sinh hoạt',
          cpvPlaceJoining: 'Nơi vào Đảng',
        },
      },
    },
  },
  dut: {
    removeFaculty: 'Xoá khoa',
    addFaculty: 'Thêm khoa',
    addPosition: 'Thêm chức vụ',
    removePositionInFaculty: 'Xoá chức vụ trong khoa',
    removePositionMajor: 'Xoá chức vụ trong chuyên ngành',
    removeDepartment: 'Xoá phòng ban',
    addDepartment: 'Thêm phòng ban',
    removePositionDepartment: 'Xoá chức vụ trong phòng ban',
  },
};

export default messages;
