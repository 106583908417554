import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import {
  TextInput,
  SelectInput,
  required,
  regex,
  AutocompleteInput,
  FormDataConsumer,
  DateInput,
  email,
  useTranslate,
  BooleanInput,
  useRecordContext,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { districtsActions } from '../../redux/master/districts';
import { communesActions } from '../../redux/master/communes';
import { actions } from '../../redux/user/students';
import { ADDRESS_TYPE, IDENTITY_TYPE, VIETNAM_COUNTRY_ID } from '../../utils/constant';
import IdentityCardIssuer from '../../components/user/students/identityCardIssuer';
import NumberFormatInput from '../../components/commons/numberFormatInput';
import sharedFunction from '../../utils/sharedFunction';

export default React.memo((props: any) => {
  const { roleUser, isEdit } = props;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const methods = useFormContext();
  const {
    genders,
    provinces,
    languages,
    identityTypes,
    cCCDCardIssuer,
    countries,
    ethnicities,
    religions,
  } = useSelector((state: any) => state[roleUser]);

  let identityTypeChoices = identityTypes;

  const {
    permanentAddressDistricts,
    currentAddressDistricts,
    nativeLandDistricts,
  } = useSelector((state: any) => state.districts);

  const {
    permanentAddressCommunes,
    currentAddressCommunes,
    nativeLandCommunes,
  } = useSelector((state: any) => state.communes);

  const GetDistricts = (event: any, type: number) => {
    dispatch(districtsActions.getData({ provinceId: event, type }));
  };

  const GetCommunes = (event: any, type: number) => {
    dispatch(communesActions.getData({ districtId: event, type }));
  };

  const GetSecondLanguage = (language1: any) => {
    if (!languages) return [];
    if (!language1) return [];

    return languages.filter((x:any) => x.id !== language1);
  };

  const NationalityChange = (event: any, formData: any) => {
    if (formData.information) {
      if (event.target.value !== VIETNAM_COUNTRY_ID) {
        methods.setValue('information.identityCardType', IDENTITY_TYPE.PASSPORT);
        identityTypeChoices = identityTypes.filter((x:any) => x.id === IDENTITY_TYPE.PASSPORT);
      } else {
        identityTypeChoices = identityTypes;
      }
      methods.setValue('information.birthPlaceCountryId', event.target.value);
      methods.setValue('information.nativeLand.countryId', event.target.value);
      methods.setValue('information.permanentAddress.countryId', event.target.value);
      methods.setValue('information.currentAddress.countryId', event.target.value);
    }
  };

  const FetchData = () => {
    const record = useRecordContext();

    useEffect(() => {
      if (record.information?.religion) {
        if (!religions.includes(record.information.religion)) {
          dispatch(actions.addReligion({ religions: [...religions, record.information.religion] }));
        }
      }
    }, [religions]);

    useEffect(() => {
      if (record.information?.nativeLand?.provinceId) {
        GetDistricts(record.information.nativeLand.provinceId, ADDRESS_TYPE.NATIVE_LAND);
        if (record.information.nativeLand?.districtId) {
          GetCommunes(record.information.nativeLand.districtId, ADDRESS_TYPE.NATIVE_LAND);
        }
      }

      if (record.information?.permanentAddress?.provinceId) {
        GetDistricts(record.information.permanentAddress.provinceId, ADDRESS_TYPE.PERMANENT_ADDRESS);
        if (record.information.permanentAddress?.districtId) {
          GetCommunes(record.information.permanentAddress.districtId, ADDRESS_TYPE.PERMANENT_ADDRESS);
        }
      }

      if (record.information?.currentAddress?.provinceId) {
        GetDistricts(record.information.currentAddress.provinceId, ADDRESS_TYPE.CURRENT_ADDRESS);
        if (record.information.currentAddress?.districtId) {
          GetCommunes(record.information.currentAddress.districtId, ADDRESS_TYPE.CURRENT_ADDRESS);
        }
      }
    }, record.languages);
  };

  if (isEdit) {
    FetchData();
  }

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={4}>
              <TextInput
                id="readonlyFirtName"
                source="information.firstName"
                disabled
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                id="readonlyLastName"
                source="information.lastName"
                disabled
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                id="readonlyOtherName"
                source="information.otherName"
                disabled
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.email"
                validate={[email(), required()]}
                type="email"
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.phone"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  regex(
                    /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                    'dut.msg.phone_number_format_invalidate',
                  ),
                ]}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <SelectInput
                source="information.identityCardType"
                choices={identityTypeChoices ?? []}
                fullWidth
                defaultValue={IDENTITY_TYPE.CMND}
                variant="outlined"
                helperText={false}
                validate={required()}
              />
            </Grid>
            <IdentityCardIssuer
              identityCardType={formData.information?.identityCardType}
              provinces={provinces}
              cCCDCardIssuer={cCCDCardIssuer}
            />
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.identityCardNumber"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <DateInput
                source="information.identityCardIssueDate"
                parse={sharedFunction.dateParse}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <SelectInput
                source="information.gender"
                choices={genders ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
                optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <DateInput
                source="information.birthday"
                parse={sharedFunction.dateParse}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <SelectInput
                source="information.nationalityId"
                choices={countries ?? []}
                fullWidth
                validate={required()}
                variant="outlined"
                helperText={false}
                onChange={(event:any) => NationalityChange(event, formData)}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="information.ethnicityId"
                choices={ethnicities
                  ? ethnicities.filter((v:any) => v.countryId === formData.information?.nationalityId) : []}
                fullWidth
                variant="outlined"
                helperText={false}
                onCreate={(filter) => {
                  const newEthnicity = {
                    id: filter ?? '',
                    name: filter ?? '',
                    countryId: formData.information.nationalityId,
                  };
                  dispatch(actions.addEthnicity({ ethnicities: [...ethnicities, newEthnicity] }));
                  return newEthnicity;
                }}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="information.religion"
                choices={religions ? religions.map((value:any) => ({ id: value, name: value })) : []}
                fullWidth
                variant="outlined"
                helperText={false}
                onCreate={(filter) => {
                  dispatch(actions.addReligion({ religions: [...religions, filter] }));
                  return { id: filter, name: filter };
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={12} md={12} mt={3} mx={1}>
              <Typography variant="h6">
                {translate('resources.user/students.fields.birthPlaceLabel')}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <SelectInput
                source="information.birthPlaceCountryId"
                choices={countries ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
                validate={required()}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              {formData.information?.birthPlaceCountryId === VIETNAM_COUNTRY_ID
                ? (
                  <AutocompleteInput
                    source="information.birthPlaceProvinceId"
                    choices={provinces ?? []}
                    variant="outlined"
                    helperText={false}
                    fullWidth
                    onChange={(e) => GetDistricts(e, ADDRESS_TYPE.BIRTH_PLACE)}
                  />
                )
                : (
                  <TextInput
                    source="information.birthPlaceProvinceName"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                  />
                )}
            </Grid>
          </Grid>

          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={12} md={12} mt={3} mx={1}>
              <Typography variant="h6">
                {translate('resources.user/students.fields.nativeLandLabel')}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <SelectInput
                source="information.nativeLand.countryId"
                choices={countries ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
                validate={required()}
              />
            </Grid>
            {formData.information?.nativeLand?.countryId === VIETNAM_COUNTRY_ID
              ? (
                <>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.nativeLand.provinceId"
                      choices={provinces ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetDistricts(e, ADDRESS_TYPE.NATIVE_LAND)}
                      validate={required()}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.nativeLand.districtId"
                      choices={nativeLandDistricts ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetCommunes(e, ADDRESS_TYPE.NATIVE_LAND)}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.nativeLand.communeId"
                      choices={nativeLandCommunes ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                    />
                  </Grid>
                </>
              )
              : (
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="information.nativeLand.detailAddress"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              )}
          </Grid>

          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={12} md={12} mt={3} mx={1}>
              <Typography variant="h6">
                {translate('resources.user/students.fields.permanentAddressLabel')}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <SelectInput
                source="information.permanentAddress.countryId"
                choices={countries ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
                emptyValue={null}
                validate={required()}
              />
            </Grid>
            {formData.information?.permanentAddress?.countryId === VIETNAM_COUNTRY_ID
              ? (
                <>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.permanentAddress.provinceId"
                      choices={provinces ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetDistricts(e, ADDRESS_TYPE.PERMANENT_ADDRESS)}
                      emptyValue={null}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.permanentAddress.districtId"
                      choices={permanentAddressDistricts ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetCommunes(e, ADDRESS_TYPE.PERMANENT_ADDRESS)}
                      emptyValue={null}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.permanentAddress.communeId"
                      choices={permanentAddressCommunes ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      emptyValue={null}
                    />
                  </Grid>
                </>
              )
              : (
                <Grid item xs={9} md={9} />
              )}
            <Grid item xs={12} md={12}>
              <TextInput
                source="information.permanentAddress.detailAddress"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={12} md={12} mt={3} mx={1}>
              <Typography variant="h6">
                {translate('resources.user/students.fields.currentAddressLabel')}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <SelectInput
                source="information.currentAddress.countryId"
                choices={countries ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
                emptyValue={null}
                validate={required()}
              />
            </Grid>
            {formData.information?.currentAddress?.countryId === VIETNAM_COUNTRY_ID
              ? (
                <>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.currentAddress.provinceId"
                      choices={provinces ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetDistricts(e, ADDRESS_TYPE.CURRENT_ADDRESS)}
                      emptyValue={null}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.currentAddress.districtId"
                      choices={currentAddressDistricts ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      onChange={(e) => GetCommunes(e, ADDRESS_TYPE.CURRENT_ADDRESS)}
                      emptyValue={null}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <AutocompleteInput
                      source="information.currentAddress.communeId"
                      choices={currentAddressCommunes ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      emptyValue={null}
                    />
                  </Grid>
                </>
              )
              : (
                <Grid item xs={9} md={9} />
              )}
            <Grid item xs={12} md={12}>
              <TextInput
                source="information.currentAddress.detailAddress"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={4}>
              <NumberFormatInput
                source="information.height"
                symbol="cm"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <NumberFormatInput
                source="information.weight"
                symbol="kg"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.bloodGroup"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextInput
                source="information.healthStatus"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.familyFrom"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <BooleanInput
                source="information.isWorkFarFromHome"
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4} />
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.educationalLevel"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.computerLevel"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.englishLevel"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextInput
                source="information.bestCertificate"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextInput
                source="information.politicalTheory"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={12} md={12} mt={3} mx={1}>
              <Typography variant="h6">
                {translate('resources.user/students.fields.language')}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <SelectInput
                source="language1"
                choices={languages ?? []}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8} />
            <Grid item xs={4} md={4}>
              <SelectInput
                source="language2"
                choices={GetSecondLanguage(formData?.language1)}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={8} />
            <Grid item xs={12} md={12}>
              <TextInput
                multiline
                source="information.note"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 200 }}
                rows={3}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormDataConsumer>
  );
});
