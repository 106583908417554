const messages = {
  resources: {
    'integration/screens': {
      name: 'Màn hình |||| Màn hình',
      fields: {
        id: 'Mã',
        showId: 'Mã',
        moduleId: 'Mô đun',
        name: 'Tên',
        isDefault: 'Màn hình mặc định',
        sort: 'Thứ tự',
        group: 'Nhóm màn hình',
        groupId: 'Nhóm màn hình',
        listFunctions: 'Danh sách chức năng',
        isActive: 'Hoạt động',
        childFunctions: 'Chức năng trong màn hình',
        functionCode: 'Code',
        functions: {
          isReturnView: 'Giao diện chính',
          id: 'Mã',
          showId: 'Mã',
        },
      },
    },
  },
};

export default messages;
