import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Show,
  useRecordContext,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ShowActions from '../../../components/commons/showActions';
import ShowLabel from '../../../components/commons/showLabel';
import ShowField from '../../../components/commons/showField';
import ShowDate from '../../../components/commons/showDate';
import ShowBooleanField from '../../../components/commons/showBooleanField';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

function FullNameFieldCustom(props:any) {
  const { className } = props;
  const record = useRecordContext();
  if (record) {
    return (
      <Typography
        className={className}
        variant="body2"
        display="content"
        textAlign="left"
      >
        {record.firstName}
        {' '}
        {record.lastName}
      </Typography>
    );
  }
  return null;
}
export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_USER);
  const canSearch = canAccess(permissions, FUNC.SEARCH_USERS);
  return (
    <Show actions={<ShowActions isEdit={canEdit} isList={canSearch} />}>
      <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
        <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
          <ShowLabel label="resources.user/accounts.fields.fullName" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <FullNameFieldCustom className="highLightField" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.username" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="username" className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.email" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="email" className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.phone" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="phone" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.birthday" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate source="birthday" showTime={false} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.gender" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="genderName" isTranslate />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.taxCode" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="taxCode" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.nationality" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="nationality" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.ethicity" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="ethicity" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.religion" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="religion" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.address" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="address" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.lastLogin" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate source="lastLogin" showTime />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.lastLogout" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate source="lastLogout" showTime />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.isActive" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowBooleanField source="isActive" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.isVerifyEmail" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowBooleanField source="isVerifyEmail" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.Role" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="roleName" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.status" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="statusName" isTranslate />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.user/accounts.fields.note" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="note" />
        </Grid>
        <Grid item xs={2} md={1.5} />
      </Grid>
    </Show>
  );
});
