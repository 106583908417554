import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { actions } from '../../redux/oauth';
import helpers from '../../utils/helpers';
import loadingIcon from '../../assets/imgs/common/loading.gif';

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const data: any = {};
    searchParams.forEach((value, key) => {
      data[key] = value;
    });
    if (localStorage.getItem('tokenIAM')) {
      data.token = localStorage.getItem('tokenIAM');
    }
    dispatch(actions.checkClient({
      data,
      onSuccess: (res: any) => {
        if (res.data.tokenIAM) {
          localStorage.setItem('tokenIAM', res.data.tokenIAM);
        }
        if (res.data.redirectUrl) {
          window.location = res.data.redirectUrl;
        } else if (res.code === 200) {
          navigate(`/login?p=${helpers.base64Encode(JSON.stringify(data))}`);
        } else {
          navigate('/no-access');
        }
      },
      onError: () => {
        navigate('/no-access');
      },
    }));
  }, []);
  return (
    <div className="dut-loading">
      <div className="dut-loading-bg white" />
      <img className="dut-loading-icon" src={loadingIcon} alt="Loading" />
    </div>
  );
}
