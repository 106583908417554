import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Edit,
  useTranslate,
  TabbedForm,
  FormTab,
  useRedirect,
} from 'react-admin';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import StudentInfoContainer from './studentInfoContainer';
import UserInfoContainer from '../userInfoContainer';
import UserYusInfoContainer from '../userYusInfoContainer';
import UserCpvInfoContainer from '../userCpvInfoContainer';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/commons/editActions';
import repositories from '../../../repositories/user/students';
import { VIETNAM_COUNTRY_ID } from '../../../utils/constant';

export default React.memo(() => {
  const redirect = useRedirect();
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_STUDENT);
  const canSearch = canAccess(permissions, FUNC.SEARCH_STUDENTS);
  const canShow = canAccess(permissions, FUNC.SHOW_STUDENT_DETAIL);
  const canUpdateStudentRole = canAccess(permissions, FUNC.EDIT_ROLE_STUDENT);
  const translate = useTranslate();
  const transform = (data:any) => {
    if (!data.information.isYusMember) {
      data.yusInformation = null;
    }
    if (data.information?.birthPlaceCountryId) {
      if (data.information?.birthPlaceCountryId === VIETNAM_COUNTRY_ID) {
        data.information.birthPlaceProvinceName = '';
      } else {
        data.information.birthPlaceProvinceId = '';
      }
    }
    if (data.information?.permanentAddress?.countryId) {
      if (data.information?.permanentAddress?.countryId === VIETNAM_COUNTRY_ID) {
        data.information.permanentAddress.detailAddress = '';
      } else {
        data.information.permanentAddress.provinceId = '';
        data.information.permanentAddress.districtId = '';
        data.information.permanentAddress.communeId = '';
      }
    }
    if (data.information?.nativeLand?.countryId) {
      if (data.information?.nativeLand?.countryId === VIETNAM_COUNTRY_ID) {
        data.information.nativeLand.detailAddress = '';
      } else {
        data.information.nativeLand.provinceId = '';
        data.information.nativeLand.districtId = '';
        data.information.nativeLand.communeId = '';
      }
    }
    if (data.information?.currentAddress?.countryId) {
      if (data.information?.currentAddress?.countryId === VIETNAM_COUNTRY_ID) {
        data.information.currentAddress.detailAddress = '';
      } else {
        data.information.currentAddress.provinceId = '';
        data.information.currentAddress.districtId = '';
        data.information.currentAddress.communeId = '';
      }
    }
    data.roleIds = data.roleIds.filter((roleId:any) => roleId !== 'STD');
    return data;
  };
  const onSuccess = (data:any) => {
    if (data?.information?.avatar?.rawFile) {
      const studentId = data.id;
      const formData = new FormData();
      formData.append('file', data?.information?.avatar?.rawFile);
      repositories.postAvatarImage(studentId, formData);
    }
    redirect('/user/students');
  };
  return (
    <Edit
      actions={<EditActions resource="system/settings" isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
      className="mai-form"
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <TabbedForm toolbar={<EditToolBar isSave={canPut} />}>
        <FormTab
          sx={{ flexDirection: 'row' }}
          label={translate('resources.user/students.fields.studentInformationLabel')}
        >
          <StudentInfoContainer isEdit canUpdateStudentRole={canUpdateStudentRole} />
        </FormTab>
        <FormTab
          label={translate('resources.user/students.fields.userInformationLabel')}
        >
          <UserInfoContainer roleUser="students" isEdit />
        </FormTab>
        <FormTab label={translate('resources.user/students.fields.yusInformationLabel')}>
          <UserYusInfoContainer isTeachers={false} />
        </FormTab>
        <FormTab label={translate('resources.user/students.fields.cpvInformationLabel')}>
          <UserCpvInfoContainer isTeachers={false} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
});
