const messages = {
  resources: {
    'integration/menu/items': {
      name: 'Menu items |||| Menu item',
      fields: {
        id: 'Id',
        showId: 'Id',
        moduleId: 'Module',
        name: 'Name',
        showParentId: 'Parent Menu',
        showFunctionId: 'Default Function',
        icon: 'Icon',
        router: 'Router',
        sort: 'Sort',
        level: 'Level',
        isActive: 'Active',
      },
    },
  },
};

export default messages;
