import * as React from 'react';
import Button from '@mui/material/Button';
import {
  TopToolbar,
  ListButton,
  ShowButton,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import { ACCOUNT_STATUS } from '../../../utils/constant';

export default React.memo((props: any) => {
  const {
    onClickLockAccount,
    onClickUnLockAccount,
    onClickAddToBlackList,
    onClickRemoveFromBlackList,
    isShow,
    isList,
    isLockAccount,
    isUnlockAccount,
    isAddToBlackList,
    isRemoveFromBlackList,
  } = props;
  const record = useRecordContext();
  const translate = useTranslate();
  const lockAccount = () => {
    if (typeof onClickLockAccount === 'function') {
      onClickLockAccount(record);
    }
  };
  const unlockAccount = () => {
    if (typeof onClickUnLockAccount === 'function') {
      onClickUnLockAccount(record);
    }
  };
  const addToBlackList = () => {
    if (typeof onClickAddToBlackList === 'function') {
      onClickUnLockAccount(record);
    }
  };
  const removeFromBlackList = () => {
    if (typeof onClickRemoveFromBlackList === 'function') {
      onClickUnLockAccount(record);
    }
  };
  return (
    <TopToolbar>
      {isShow !== false && <ShowButton />}
      {isList !== false && <ListButton />}
      {record && record.status === ACCOUNT_STATUS.NORMAL && (isLockAccount !== false) && (
        <Button color="warning" onClick={lockAccount} size="small">
          <LockIcon fontSize="small" sx={{ mr: 1 }} />
          {translate('dut.lockAccount')}
        </Button>
      ) }
      {record && record.status === ACCOUNT_STATUS.LOCK && (isUnlockAccount !== false) && (
        <Button color="primary" onClick={addToBlackList} size="small">
          <LockOpenIcon fontSize="small" sx={{ mr: 1 }} />
          {translate('dut.unlockAccount')}
        </Button>
      )}
      {record && record.status === ACCOUNT_STATUS.NORMAL && (isAddToBlackList !== false) && (
        <Button color="error" onClick={unlockAccount} size="small">
          <DoNotDisturbAltIcon fontSize="small" sx={{ mr: 1 }} />
          {translate('dut.addToBlackList')}
        </Button>
      )}
      {record && record.status === ACCOUNT_STATUS.BLACKLIST && (isRemoveFromBlackList !== false) && (
        <Button color="primary" onClick={removeFromBlackList} size="small">
          <DoNotDisturbOffIcon fontSize="small" sx={{ mr: 1 }} />
          {translate('dut.removeFromBlackList')}
        </Button>
      )}
    </TopToolbar>
  );
});
