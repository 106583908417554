/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import CustomSearchInput from '../../commons/customSearchInput';
import SemesterSelectInput from './SemesterSelectInput';
import { actions } from '../../../redux/subject/subjectClasses';

let facultyId = 0;
let scholasticId = 0;

function SelectType({ source }: any) {
  const { types } = useSelector((state: any) => state.subjectClasses);
  return (
    <SelectInput
      source={source}
      choices={types ?? []}
      variant="outlined"
    />
  );
}

function SelectScholastic({ source }: any) {
  const { scholastics } = useSelector((state: any) => state.subjectClasses);
  const dispatch = useDispatch();
  const getData = (input: any) => {
    scholasticId = input.target.value;
    dispatch(actions.getInitData({ facultyId, scholasticId }));
  };
  return (
    <SelectInput
      source={source}
      choices={scholastics ?? []}
      onChange={getData}
      variant="outlined"
    />
  );
}

function SelectTeacher({ source }: any) {
  const { teachers } = useSelector((state: any) => state.subjectClasses);
  return (
    <AutocompleteInput
      source={source}
      choices={teachers ?? []}
      variant="outlined"
      fullWidth
    />
  );
}

function SelectSubject({ source }: any) {
  const { subjects } = useSelector((state: any) => state.subjectClasses);
  return (
    <AutocompleteInput
      source={source}
      choices={subjects ?? []}
      variant="outlined"
      fullWidth
    />
  );
}

function SelectFaculty({ source }: any) {
  const { faculties } = useSelector((state: any) => state.subjectClasses);
  const dispatch = useDispatch();
  const getData = (input: any) => {
    facultyId = input;
    dispatch(actions.getInitData({ facultyId, scholasticId }));
  };
  return (
    <AutocompleteInput
      source={source}
      choices={faculties ?? []}
      variant="outlined"
      onChange={getData}
      fullWidth
    />
  );
}

export default [
  <CustomSearchInput source="Query" alwaysOn placeholder="keyword" />,
  <SelectType
    source="type"
  />,
  <SelectScholastic
    source="scholasticId"
  />,
  <SemesterSelectInput
    source="semesterId"
    variant="outlined"
  />,
  <SelectFaculty
    source="facultyId"
    variant="outlined"
  />,
  <SelectTeacher
    source="teacherId"
  />,
  <SelectSubject
    source="subjectId"
  />,
];
