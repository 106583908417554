import _ from 'lodash';

export default (permissions: any, functionId: string) => {
  try {
    const permission = _.find(permissions, (item: any) => item.id === functionId);
    return !!permission?.isEnabled;
  } catch (e) {
    console.log(e);
  }
  return false;
};
