import * as React from 'react';
import {
  SimpleForm, TextInput, maxLength, required, useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { actions } from '../../../redux/system/settings';
import EditToolBar from '../../../components/commons/editToolBar';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canShow = canAccess(permissions, FUNC.SETTINGS);
  const canEdit = canAccess(permissions, FUNC.EMAIL_SETTING);
  const [blobStorage, setBlobStorage] = useState(null);
  const { data } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();
  useEffect(() => {
    setBlobStorage(data);
  }, [data]);
  const notify = useNotify();
  const onSuccess = () => {
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
  };
  const transform = (value: any) => {
    dispatch(actions.putBlobStorage({ value, onSuccess }));
  };
  return (
    <SimpleForm record={blobStorage ?? []} toolbar={<EditToolBar isSave={canEdit} />} onSubmit={transform}>
      {blobStorage && canShow && (
        <>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={1} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="bucketName"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(255),
                ]}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="endpoint"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(255),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={1} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="accessKey"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(255),
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={1} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="secretKey"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  required(),
                  maxLength(255),
                ]}
              />
            </Grid>
          </Grid>
        </>
      )}
    </SimpleForm>
  );
});
