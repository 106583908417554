import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ShowButton,
  EditButton,
  EmailField,
  DeleteWithConfirmButton,
} from 'react-admin';
import filters from '../../../components/user/accounts/filters';
import ListActions from '../../../components/commons/listActions';
import PostPagination from '../../../components/commons/postPagination';
import PostBulkActionButtons from '../../../components/commons/postBulkActionButtons';
import TranslateField from '../../../components/commons/translateField';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canEdit = canAccess(permissions, FUNC.EDIT_USER);
  const canShow = canAccess(permissions, FUNC.SHOW_USER_DETAIL);
  const canAdd = canAccess(permissions, FUNC.ADD_USER);
  const canDelete = canAccess(permissions, FUNC.DELETE_USER);
  return (
    <List
      filters={filters}
      actions={<ListActions isExport isCreate={canAdd} />}
      perPage={100}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={<PostBulkActionButtons />} optimized>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="username" />
        <EmailField source="email" />
        <TextField source="phone" />
        <DateField source="lastLogin" showTime />
        <DateField source="lastLogout" showTime />
        <BooleanField source="isActive" />
        <BooleanField source="isVerifyEmail" />
        <TranslateField source="statusName" />
        {canShow && <ShowButton label="" />}
        {canEdit && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
