import screenList from './screenList';
import createScreen from './createScreen';
import editScreen from './editScreen';
import showScreen from './showScreen';

export default {
  list: screenList,
  create: createScreen,
  edit: editScreen,
  show: showScreen,
};
