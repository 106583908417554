import {
  all, takeEvery, put, call, fork,
} from 'redux-saga/effects';
import { communesActions } from '.';
import repositories from '../../../repositories/master/communes';
import ResponseInterface from '../../../interfaces/responseInterface';

export function* getData() {
  yield takeEvery(communesActions.getData, function* (data: any) {
    const { payload } = data;
    try {
      const response: ResponseInterface = yield call(repositories.get, data.payload.districtId);
      const communes = { type: payload.type, communes: response.data };
      if (typeof payload.onSuccess === 'function') {
        payload.onSuccess(communes);
      }
      yield put(communesActions.getDataSuccess(communes));
    } catch (error) {
      if (typeof payload.onError === 'function') {
        payload.onError(error);
      }
      yield put(communesActions.getDataFailure(error));
    }
  });
}

export default function* appSagas() {
  yield all([
    fork(getData),
  ]);
}
