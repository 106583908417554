import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../../containers/integration/screens/createScreenContainer';
import { actions } from '../../../redux/integration/screens';

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getInitData('\'\''));
  }, []);
  return (
    <Container />
  );
}
