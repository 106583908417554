import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  Edit,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import { actions } from '../../../redux/integration/menuItems';
import EditActions from '../../../components/commons/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const { modules, menus, functions } = useSelector((state: any) => state.menuItems);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_MENU_ITEM);
  const canSearch = canAccess(permissions, FUNC.SEARCH_MENU_ITEMS);
  const canShow = canAccess(permissions, FUNC.SHOW_MENU_ITEM_DETAIL);
  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data === '') {
      dispatch(actions.getInitData('\'\''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              disabled
              variant="outlined"
              source="showId"
              validate={[
                required(),
                maxLength(10),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="moduleId"
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              onChange={(choice) => getData(choice.target.value)}
              validate={
                required()
              }
              disabled
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="parentId"
              choices={menus ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="functionId"
              choices={functions ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="icon"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="router"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="sort"
              validate={[
                required(),
                maxLength(100)]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
