import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  TextField,
} from 'react-admin';

export default React.memo(() => (
  <ArrayField source="semesters">
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <DateField locales="en-GB" source="startAt" />
      <DateField locales="en-GB" source="endAt" />
    </Datagrid>
  </ArrayField>
));
