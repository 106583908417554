/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
  maxLength,
  required,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

export default React.memo((props: any) => {
  const {
    hiddenKeys,
    datas,
    source,
  } = props;

  const methods = useFormContext();
  const [isInit, setIsInit] = React.useState(true);
  const translate = useTranslate();

  React.useEffect(() => {
    if (datas && datas?.length > 0 && isInit) {
      const newDatas = datas.map((item: any) => ({
        ...item,
        isOldValue: true,
      }));
      methods.setValue(source, newDatas);
      setIsInit(false);
    }
  }, [datas]);
  return (
    <ArrayInput
      hiddenLabel
      source={source}
      sx={{
        marginTop: '0',
      }}
    >
      <SimpleFormIterator className="rowfunction">
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => (
            <>
              <TextInput
                focused={false}
                source={getSource?.('id') || ''}
                label={translate('resources.system/settings.fields.id')}
                validate={[
                  required(),
                  maxLength(10)]}
                sx={{ width: '100%', marginRight: '5%' }}
                variant="outlined"
                helperText={false}
                disabled={scopedFormData.isOldValue}
              />
              <TextInput
                focused={false}
                source={getSource?.('name') || ''}
                label={translate('resources.system/settings.fields.name')}
                validate={[
                  required(),
                  maxLength(255)]}
                sx={{ width: '100%' }}
                variant="outlined"
                helperText={false}
              />
              {hiddenKeys?.map((key: string) => (
                <TextInput
                  source={getSource?.(key) || ''}
                  style={{ display: 'none' }}
                />
              ))}

            </>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
});
