import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  profile: {},
  baseProfile: false,
  version: 0,
  password: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  versionPass: 0,
  genders: [],
};

export const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileSuccess: (state, action) => {
      state.profile = action.payload;
    },
    changeVersionProfile: (state) => {
      state.version += 1;
    },
    getProfileBaseSuccess: (state, action) => {
      localStorage.setItem('profile', JSON.stringify(action.payload));
    },
    getProfileBaseFailure: () => {
      localStorage.setItem('profile', JSON.stringify({}));
    },
    changePasswordSuccess: (state) => {
      state.password = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      };
      state.versionPass += 1;
    },
    getInitDataSuccess: (state, action) => {
      state.genders = action.payload.genders;
    },
    getInitDataFailure: (state) => {
      state.genders = [];
    },
  },
});

const actionsTmp: any = { ...profile.actions };
actionsTmp.getProfile = createAction('profile/getProfile');
actionsTmp.getProfileBase = createAction('profile/getProfileBase');
actionsTmp.saveProfile = createAction('profile/saveProfile');
actionsTmp.changePassword = createAction('profile/changePassword');
actionsTmp.getInitData = createAction('profile/getInitData');

export const actions = { ...actionsTmp };

export const profileSelector = (state: any) => state;

export default profile.reducer;
