import md5 from 'md5';
import repositories from '.';

const resource = '/auth';

export default {
  login: (body: any) => {
    body.password = md5(body.password);
    return repositories.post(`${resource}/login`, body);
  },
  logout: () => repositories.get(`${resource}/logout`),
  forgotPassword: (body: any) => repositories.post(`${resource}/forgot-password`, body),
  resetPassword: (body: any) => {
    body.password = md5(body.password);
    body.confirmPassword = md5(body.confirmPassword);
    return repositories.post(`${resource}/forgot-password/change-password`, body);
  },
};
