import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  faculties: [],
};

export const subjects = createSlice({
  name: 'subjects',
  initialState,
  reducers: {
    getInitDataSuccess: (state, action) => {
      state.faculties = action.payload.faculties;
    },
    getInitDataFailure: (state) => {
      state.faculties = [];
    },
  },
});

const actionsTmp: any = { ...subjects.actions };
actionsTmp.getInitData = createAction('subjects/getInitData');

export const actions = { ...actionsTmp };

export const userSelector = (state: any) => state;

export default subjects.reducer;
