import md5 from 'md5';
import repositories from '.';

const resource = '/auth/profile';

export default {
  getProfile: () => repositories.get(`${resource}`),
  getProfileBase: () => repositories.get(`${resource}/base-info`),
  saveProfile: (data: any) => repositories.post(`${resource}/update`, data),
  changePassword: (data: any) => repositories.post(`${resource}/change-password`, {
    oldPassword: md5(data.oldPassword),
    password: md5(data.newPassword),
    confirmPassword: md5(data.confirmPassword),
  }),
  getInitData: () => repositories.get(`${resource}/data/init`),
};
