const messages = {
  resources: {
    'integration/screen-groups': {
      name: 'ScreenGroups |||| ScreenGroups',
      fields: {
        id: 'Id',
        moduleId: 'Module',
        moduleName: 'Module Name',
        name: 'Name',
        description: 'Description',
        showId: 'Id',
      },
    },
  },
};

export default messages;
