import englishMessages from './defaults/ra-language-english';
import commons from './commons/en';
import msg from './systemMessages/en';
import pageUsers from './pages/user/accounts/en';
import pageStudents from './pages/user/students/en';
import pageModules from './pages/integration/modules/en';
import pageApiKeys from './pages/integration/apiKeys/en';
import pageScreens from './pages/integration/screens/en';
import pageFunctions from './pages/integration/functions/en';
import pageMenus from './pages/integration/menuItems/en';
import pageApiGroups from './pages/integration/apiGroups/en';
import pageApis from './pages/integration/apis/en';
import pageRoles from './pages/system/roles/en';
import pageScreenGroups from './pages/integration/screenGroups/en';
import teacherGroups from './pages/user/teachers/en';
import system from './pages/system/system/en';
import pageWebhooks from './pages/integration/webhooks/en';
import pageCourses from './pages/master/courses/en';
import pageScholastics from './pages/master/scholastics/en';
import pageSemesters from './pages/master/semesters/en';
import pageActivityClasses from './pages/master/activityClasses/en';
import pageFaculties from './pages/master/faculties/en';
import pageSubjects from './pages/subject/subjects/en';
import pageSubjectClasses from './pages/subject/subjectClasses/en';
import pageSettings from './pages/system/settings/en';
import pageLogs from './pages/system/logs/en';
import pageMailLogs from './pages/system/mailLogs/en';
import pageMajors from './pages/master/majors/en';

const msgTitle: any = {};
const messages = {
  ...englishMessages,
  resources: {
    ...pageUsers.resources,
    ...pageModules.resources,
    ...pageApiKeys.resources,
    ...pageScreens.resources,
    ...pageFunctions.resources,
    ...pageMenus.resources,
    ...pageApiGroups.resources,
    ...pageApis.resources,
    ...pageScreenGroups.resources,
    ...pageRoles.resources,
    ...pageStudents.resources,
    ...teacherGroups.resources,
    ...system.resources,
    ...pageWebhooks.resources,
    ...pageCourses.resources,
    ...pageScholastics.resources,
    ...pageSemesters.resources,
    ...pageActivityClasses.resources,
    ...pageFaculties.resources,
    ...pageSubjects.resources,
    ...pageSubjectClasses.resources,
    ...pageSettings.resources,
    ...pageLogs.resources,
    ...pageMailLogs.resources,
    ...pageMajors.resources,
  },
  dut: {
    ...commons,
    ...pageSubjectClasses.dut,
    ...pageUsers.dut,
    ...pageRoles.dut,
    ...teacherGroups.dut,
    msg: {
      ...msg,
    },
    msgTitle: {
      ...msgTitle,
    },
    ...pageApiKeys.dut,
  },
  ...commons.menu,
  ...commons.api,
  ...commons.functions,
  ...commons.screens,
};

export default messages;
