/* eslint-disable max-len */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
  Edit,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from '../../../components/commons/editToolBar';
import { actions } from '../../../redux/integration/functions';
import EditActions from '../../../components/commons/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';
import SelectInputWithInitingRelatedFunction from '../../../components/integration/functions/selectInputWithInitingRelatedFunction';

export default React.memo(() => {
  const { modules, functions } = useSelector((state: any) => state.functions);
  const { permissions } = useSelector((state: any) => state.app.appState);
  const canPut = canAccess(permissions, FUNC.EDIT_FUNCTION);
  const canSearch = canAccess(permissions, FUNC.SEARCH_SCREENS);
  const canShow = canAccess(permissions, FUNC.SHOW_SCREEN_DETAIL);
  const dispatch = useDispatch();
  const getData = (data: string) => {
    if (data === '') {
      dispatch(actions.getInitData(''));
    } else {
      dispatch(actions.getInitData(data));
    }
  };
  return (
    <Edit
      actions={<EditActions isShow={canShow} isList={canSearch} />}
      mutationMode="pessimistic"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} />}>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sx={{ display: { xs: 'none' } }} md={1} />
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              disabled
              variant="outlined"
              source="showId"
              validate={[
                required(),
                maxLength(10),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="functionCode"
              validate={[
                required(),
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="name"
              validate={[
                required(),
                maxLength(100),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInputWithInitingRelatedFunction
              initFunction={getData}
              source="moduleId"
              disabled
              choices={modules ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => choice.name}
              variant="outlined"
              helperText={false}
              onChange={(choice) => getData(choice.target.value)}
              validate={
                required()
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectInput
              source="showRelatedFunctionId"
              choices={functions ?? []}
              emptyValue=""
              fullWidth
              optionText={(choice) => (`${choice.showId}: ${choice.name}`)}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="url"
              validate={[
                required(),
                maxLength(255),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="area"
              validate={[
                required(),
                maxLength(255),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="controller"
              validate={[
                required(),
                maxLength(255),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="action"
              validate={[
                required(),
                maxLength(255),
              ]}
            />
          </Grid>
          <Grid item xs={8} md={10}>
            <TextInput
              fullWidth
              variant="outlined"
              source="sort"
              validate={[
                required(),
                maxLength(255),
              ]}
            />
          </Grid>
          <Grid item xs={3} md={4}>
            <BooleanInput
              source="isReturnView"
            />
          </Grid>
          <Grid item xs={3} md={4}>
            <BooleanInput
              source="isRequired"
            />
          </Grid>
          <Grid item xs={3} md={4}>
            <BooleanInput
              source="isHidden"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
