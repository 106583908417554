import roleList from './roleList';
import editRole from './editRole';
import createRole from './createRole';
import showRole from './showRole';

export default {
  list: roleList,
  edit: editRole,
  create: createRole,
  show: showRole,
};
