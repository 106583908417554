import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  TextInput,
  SelectInput,
  DateInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import { Grid } from '@mui/material';
import sharedFunction from '../../utils/sharedFunction';

export default React.memo((props: any) => {
  const {
    isTeachers,
  } = props;
  const selectedState = isTeachers ? (state: any) => state.teachers : (state: any) => state.students;
  const {
    activityClasses,
    faculties,
    positionsInYus,
  } = useSelector(selectedState);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={4}>
            <BooleanInput
              source="information.isYusMember"
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </Grid>
          {formData.information?.isYusMember
          && (
            <>
              <Grid item xs={4} md={4}>
                <BooleanInput
                  source="yusInformation.isNewMember"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <BooleanInput
                  source="yusInformation.isTempMember"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <DateInput
                  source="yusInformation.prepareDate"
                  parse={sharedFunction.dateParse}
                  fullWidth
                  variant="outlined"
                  helperText={false}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <DateInput
                  source="yusInformation.officialDate"
                  parse={sharedFunction.dateParse}
                  fullWidth
                  variant="outlined"
                  helperText={false}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <TextInput
                  source="yusInformation.card"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="yusInformation.placeJoining"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <TextInput
                  source="yusInformation.status"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <SelectInput
                  source="yusInformation.currentPosition"
                  choices={positionsInYus ?? []}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4} />
              <Grid item xs={4} md={4}>
                <SelectInput
                  disabled
                  source="activityClass"
                  choices={activityClasses ?? []}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <SelectInput
                  disabled
                  source="faculty"
                  choices={faculties ?? []}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4} />
              <Grid item xs={4} md={4}>
                <BooleanInput
                  source="yusInformation.isMovedMember"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <DateInput
                  source="yusInformation.movedDate"
                  fullWidth
                  parse={sharedFunction.dateParse}
                  variant="outlined"
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="yusInformation.yusClassBefore"
                  variant="outlined"
                  parse={sharedFunction.dateParse}
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <BooleanInput
                  source="yusInformation.isRegisterForYusActivities"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <DateInput
                  source="yusInformation.regisStartDate"
                  fullWidth
                  parse={sharedFunction.dateParse}
                  variant="outlined"
                  helperText={false}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <DateInput
                  source="yusInformation.regisEndDate"
                  fullWidth
                  parse={sharedFunction.dateParse}
                  variant="outlined"
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="yusInformation.placeOfRegisterForYusActivities"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </FormDataConsumer>
  );
});
